import { CheckCircleFilled, LoadingOutlined } from '@ant-design/icons';
import { Button, Collapse, Divider, Drawer, Empty, Form, Input, Modal, Radio, Select, Space, Spin, notification } from 'antd';
import Moment from 'moment';
import NaijaStates from 'naija-state-local-government';

import { KlumpCheckout } from 'klump-react';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import ReactMoment from 'react-moment';
import NumberFormat from 'react-number-format';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import '../../assets/css/shop.css';
import Jwt_decrypt from '../../common/Jwt_decrypt';
import { _add_delivery_address, _checkout_summary, _fetch_user_wallet_data, _general_settings_by_id, _get_all_cities, _get_all_dates, _get_all_pickup_locations, _get_all_state, _get_profile, _get_saved_address, _get_selected_delivery_location, _get_selected_pickup_location, _shop_discount, _single_product, _verify_pin, pay_checkout } from '../../common/axios_services';
import Footer from '../../components/Footer';
import HowItWorks from '../../components/HowItWorks';
import Navbar from '../../components/Navbar';
import { addToCart } from '../../slices/cartSlice';
import { encrypted_token, fetchData, profileData } from '../../slices/profileSlice';

const Checkout = () => {
    // Import necessary dependencies
    const navigate = useNavigate();
    const currentDate = moment();
    const fetchDataStatus = useSelector(state => state.userProfile.fetch);
    const userData = useSelector(state => state.userProfile.dataFetched);
    const { Panel } = Collapse;
    const [form] = Form.useForm();
    const antIcon = <LoadingOutlined style={{ fontSize: 24, color: '#154C9A' }} spin />;
    const spinningIcon = <LoadingOutlined style={{ fontSize: 24, color: '#fff' }} spin />;
    const openNotificationWithIcon = (type, title, message) => {
        notification[type]({
            placement: 'bottomRight',
            message: title ? title : '',
            description: message ? message : "",
        });
    };

    // Other state variables
    const { handleSubmit: handleAddressFormSubmit, control: controlAddressForm, formState: { errors: errorsAddressForm } } = useForm({});
    let jwt_token = localStorage.getItem('konnect_token');
    const [howItWorksModal, setHowItWorksModal] = useState(false);
    const [showAddress, setShowAddress] = useState(false);
    const [spinning, setSpinning] = useState(false);
    const [loadingPickUpLocations, setLoadingPickUpLocations] = useState(false);
    const [deliveryType, setDeliveryType] = useState('delivery');
    const [deliveryMethod, setDeliveryMethod] = useState('home');
    const [deliveryDate, setDeliveryDate] = useState();
    const [paymentOption, setPaymentOption] = useState('pay_now');
    const [paymentOpt, setPaymentOpt] = useState('instant_payment');
    const [locationId, setLocationId] = useState("");
    const [selectHome, setSelectHome] = useState(false);
    const [loadingDate, setLoadingDate] = useState(true);
    const [allStates, setAllStates] = useState([]);
    const [allCity, setAllCities] = useState(false);
    const [allLocations, setAllLocations] = useState(false);
    const [updatedAddress, setUpdatedAddress] = useState(false);
    const [formattedDate, setFormattedDate] = useState(null);
    const [allDeliveryLocations, setAllDeliveryLocations] = useState([]);
    const [updateCount, setUpdateCount] = useState(0);
    const [locationAdded, setLocationAdded] = useState(true);
    const [deliveryDateUpdated, setDeliveryDateUpdated] = useState(false);
    const [paying, setPaying] = useState(false);
    const [deliveryData, setDeliveryData] = useState(null);
    const [allDate, setAllDate] = useState([]);
    const [allLgas, setAllLgas] = useState([]);
    const [orderTotals, setOrderTotals] = useState(false);
    const [paymentModal, setPaymentModal] = useState(false);
    const [userState, setUserState] = useState('');
    const [loadingButton, setLoadingButton] = useState(false);
    const [pay_on_delivery, setPay_on_delivery] = useState(false);
    const [verifyModal, setVerifyModal] = useState(false);
    const [revealPinMessage, setRevealPinMessage] = useState(false);
    const [verifingPin, setVerifingPin] = useState(false);
    const [summaryLoading, setSummaryLoading] = useState(false);
    const [meansOfTransfer, setMeansOfTransfer] = useState(userData?.kyc_status !== "verified" ? "gateway" : "wallet");
    const [dateRestrictions, setDateRestrictions] = useState(false);
    const [accountBalance, setAccountBalance] = useState({});
    const [minKlumps, setMinKlumps] = useState(false);
    const [minCheckoutData, setMinCheckoutData] = useState(false);
    const dispatch = useDispatch();

    const cart_info = JSON.parse(localStorage.getItem('konnect_cart'));

    // Helper functions
    const reduQuantity = (total, num) => {
        return total + Number(num.quantity);
    }
    const reduWeight = (total, num) => {
        return total + num.total_weight;
    }
    const reduPrice = (total, num) => {
        return total + num.total_price;
    }
    const reduSupplierPrice = (total, num) => {
        return total + num.total_supplier_price;
    }
    const checkOrderMinAmount = async ({ data }) => {
        try {
            const checkout_summary = await _checkout_summary(data);
            if (paymentOption === "bnpl") {
                const checkout_data = await _general_settings_by_id({ id: "15" });
                if ((+checkout_summary?.data?.data?.total_amount) < (userData.is_sa ? +checkout_data?.data?.data?.app_value : +checkout_data?.data?.data?.value)) {
                    openNotificationWithIcon("error", "Something went wrong", `A minimum order of NGN ${(+checkout_data?.data?.data?.value).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')} is required before proceeding to checkout for pay monthly. Please update your cart accordingly.`);
                    setMinKlumps(false);
                    setMinCheckoutData(checkout_data?.data?.data);
                } else {
                    setMinCheckoutData(false);
                    setMinKlumps(true);
                }
            } else {
                const checkout_data = await _general_settings_by_id({ id: "1" });
                if ((+checkout_summary?.data?.data?.total_amount) < (userData.is_sa ? +checkout_data?.data?.data?.app_value : +checkout_data?.data?.data?.value)) {
                    openNotificationWithIcon("error", "Something went wrong", `A minimum order of NGN ${(+checkout_data?.data?.data?.value).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')} is required before proceeding to checkout. Please update your cart accordingly.`);
                    navigate('/cart');
                }
            }
            setOrderTotals(checkout_summary.data.data);
            setSummaryLoading(false);
        } catch (err) {
            setSummaryLoading(false);
            if (err.response) {
                if (err.response === "Unauthorized") {
                    navigate('/');
                    openNotificationWithIcon(err?.response?.data?.status, err?.response?.data?.title, err?.response?.data?.message);
                } else {
                    openNotificationWithIcon(err?.response?.data?.status, err?.response?.data?.title, err?.response?.data?.message);
                }
            } else {
                openNotificationWithIcon('error', "Something went wrong", err.message);
            }
        }
    }
    const DeliveryFees = async ({ id }) => {
        setOrderTotals(false);
        const local_cart = JSON.parse(localStorage.getItem('konnect_cart'));
        if (local_cart) {
            const data = {
                payment_opt: paymentOption === "pay_now" ? "instant_payment" : paymentOption === "bnpl" ? "buy_now_pay_later" : paymentOption === "pay_on_delivery" ? "pay_on_delivery" : "instant_payment",
                delivery_date_id: id,
                sub_amount: `${local_cart.data.reduce(reduPrice, 0)}`,
                is_pod: pay_on_delivery,
                coupon: "0",
                delivery_opt: deliveryMethod,
            }
            setSummaryLoading(true);
            checkOrderMinAmount({ data });
        }
    }
    const fetchUserAddresses = async () => {
        try {
            const allAddresses = await _get_saved_address();
            setAllDeliveryLocations(allAddresses.data.data);
        } catch (err) {
            if (err.response) {
                openNotificationWithIcon(err?.response?.data?.status, err?.response?.data?.title, err?.response?.data?.message);
            } else {
                openNotificationWithIcon('error', "Something went wrong", err.message);
            }
        }
    }
    const fetchAddedStates = async () => {
        try {
            const uploadedStates = await _get_all_state();
            setAllStates(uploadedStates.data.data);
        } catch (err) {
            if (err.response) {
                openNotificationWithIcon(err?.response?.data?.status, err?.response?.data?.title, err?.response?.data?.message);
            } else {
                openNotificationWithIcon('error', "Something went wrong", err.message);
            }
        }
    }
    const fetchAddedCities = async () => {
        try {
            const uploadedCities = await _get_all_cities();
            setAllCities(uploadedCities.data.data);
        } catch (err) {
            if (err.response) {
                openNotificationWithIcon(err?.response?.data?.status, err?.response?.data?.title, err?.response?.data?.message);
            } else {
                openNotificationWithIcon('error', "Something went wrong", err.message);
            }
        }
    }
    const fetchPickupLocations = async () => {
        try {
            const uploadedLocations = await _get_all_pickup_locations();
            setAllLocations(uploadedLocations.data.data);
        } catch (err) {
            if (err.response) {
                openNotificationWithIcon(err?.response?.data?.status, err?.response?.data?.title, err?.response?.data?.message);
            } else {
                openNotificationWithIcon('error', "Something went wrong", err.message);
            }
        }
    }
    const getBalance = async () => {
        try {
            const get_balance = await _fetch_user_wallet_data();
            setAccountBalance(get_balance?.data?.data);
        } catch (err) {
            setAccountBalance({});
            if (err.response) {
                if (err.response.data.message === "Unauthorized") {
                    navigate("/signin?redir=review");
                    localStorage.removeItem('konnect_token');
                    openNotificationWithIcon(err?.response?.data?.status, err?.response?.data?.title, err?.response?.data?.message);
                } else {
                    openNotificationWithIcon(err?.response?.data?.status, err?.response?.data?.title, err?.response?.data?.message);
                }
            } else {
                openNotificationWithIcon('error', "Something went wrong", err.message);
            }
        }
    }
    const getProfile = async () => {
        try {
            const user_profile = await _get_profile();
            dispatch(profileData(user_profile.data.data));
            dispatch(fetchData(true));
        } catch (err) {
            if (err.response) {
                if (err.response.data.message === "Unauthorized") {
                    dispatch(profileData(false));
                    dispatch(fetchData(false));
                    navigate("/signin?redir=review");
                    localStorage.removeItem('konnect_token');
                    openNotificationWithIcon(err?.response?.data?.status, err?.response?.data?.title, err?.response?.data?.message);
                } else {
                    openNotificationWithIcon(err?.response?.data?.status, err?.response?.data?.title, err?.response?.data?.message);
                }
            } else {
                openNotificationWithIcon('error', "Something went wrong", err.message);
            }
        }
    }
    const getAllDates = async () => {
        setLoadingDate(true);
        try {
            const all_dates = await _get_all_dates({ payment_opt: paymentOption === "pay_now" ? "instant_payment" : paymentOption === "pay_on_delivery" ? "pay_on_delivery" : paymentOption === "bnpl" ? "buy_now_pay_later" : "instant_payment" });
            let date_data = all_dates.data.data.sort(function (a, b) {
                return new Date(a.date) - new Date(b.date);
            });
            setAllDate(date_data);
            setLoadingDate(false);
        } catch (err) {
            setLoadingDate(false);
            if (err.response) {
                if (err.response.data.message === "Unauthorized") {
                    dispatch(profileData(false));
                    dispatch(fetchData(false));
                    navigate("/signin?redir=review");
                    localStorage.removeItem('konnect_token');
                    openNotificationWithIcon(err?.response?.data?.status, err?.response?.data?.title, err?.response?.data?.message);
                } else {
                    openNotificationWithIcon(err?.response?.data?.status, err?.response?.data?.title, err?.response?.data?.message);
                }
            } else {
                openNotificationWithIcon('error', "Something went wrong", err.message);
            }
        }
    }
    const getDateRestrictions = async () => {
        setDateRestrictions(false);
        try {
            const date_restrict = await _general_settings_by_id({ id: "2" });
            setDateRestrictions(date_restrict?.data?.data?.value);
        } catch (err) {
            setDateRestrictions(false);
            if (err.response) {
                if (err.response.data.message === "Unauthorized") {
                    dispatch(profileData(false));
                    dispatch(fetchData(false));
                    navigate("/signin?redir=review");
                    localStorage.removeItem('konnect_token');
                    openNotificationWithIcon(err?.response?.data?.status, err?.response?.data?.title, err?.response?.data?.message);
                } else {
                    openNotificationWithIcon(err?.response?.data?.status, err?.response?.data?.title, err?.response?.data?.message);
                }
            } else {
                openNotificationWithIcon('error', "Something went wrong", err.message);
            }
        }
    }
    const checkProductPrice = async () => {
        const storedCart = JSON.parse(localStorage.getItem('konnect_cart'));
        if (storedCart && storedCart.data && storedCart.data.length > 0) {
            try {
                const updatedCart = await Promise.all(
                    storedCart.data.map(async product => {
                        try {
                            const checkPrice = await _single_product({ id: product?.product_id });
                            let data = {
                                id: product?.id,
                                product_id: product?.product_id,
                                quantity: product?.quantity ? product?.quantity : 1,
                                timestamp: product?.timestamp,
                                product_name: product?.product_name,
                                product_image: product?.product_image,
                                description: checkPrice?.data?.data?.description,
                                product_price: checkPrice?.data?.data?.konnect_price,
                                supplier_price: checkPrice?.data?.data?.sellers_price,
                                weight: checkPrice?.data?.data?.weight,
                                discount_rate: checkPrice?.data?.data?.discount_rate,
                                discount: checkPrice?.data?.data?.konnect_price * checkPrice?.data?.data?.discount_rate * 0.01 * product?.quantity,
                                total_weight: product?.quantity * checkPrice?.data?.data?.weight,
                                total_price: product?.quantity * checkPrice?.data?.data?.konnect_price,
                                total_supplier_price: product?.quantity * checkPrice?.data?.data?.sellers_price,
                                cashback: 0,
                            }
                            return data;
                        } catch (err) {
                            return null;
                        }
                    })
                )
                storedCart.data = updatedCart.filter(product => product !== null);
                localStorage.setItem('konnect_cart', JSON.stringify(storedCart));
            } catch (err) {
                // console.log('Error updating product prices:', err.message);
            }
        }
    }

    // Fetch necessary data on component mount
    useEffect(() => {
        if (jwt_token) {
            const decrypted = Jwt_decrypt({ token: jwt_token });
            dispatch(encrypted_token(decrypted));
        }
        getProfile();
        fetchUserAddresses();
        fetchAddedStates();
        fetchAddedCities();
        fetchPickupLocations();
        checkProductPrice();
        getBalance();
    }, [dispatch, jwt_token, updateCount, deliveryType, deliveryMethod]);
    useEffect(() => {
        getAllDates();
        getDateRestrictions();
    }, [paymentOption, dispatch, jwt_token, updateCount, deliveryType, deliveryMethod]);

    // Other functions
    const genExtra = () => {
        if (deliveryDate) {
            return <CheckCircleFilled style={{ color: "green" }} />;
        } else {
            return <CheckCircleFilled />;
        }
    }
    const genPayment = () => {
        if (paymentOption) {
            return <CheckCircleFilled style={{ color: "green" }} />;
        } else {
            return <CheckCircleFilled />;
        }
    }

    // Function to handle adding a home delivery address
    const home_delivery_address = async (values) => {
        setLoadingButton(true);
        try {
            const data = {
                user_id: userData?.id ? userData?.id : userData?.user_id,
                location_no: "0",
                Address: values.home_address,
                floor_no: values.floor_no,
                phone_contact: values.phone_no,
                email: userData?.email,
                state: userState,
                city: "city ",
                lga: values.lga,
                longitude: "1",
                latitude: "1",
            };
            setUpdateCount(updateCount + 1);
            const addedDeliveryAddress = await _add_delivery_address(data);
            try {
                const fetchLocations = await fetchUserAddresses();
                openNotificationWithIcon('success', addedDeliveryAddress?.data?.title, addedDeliveryAddress?.data?.message);
                setSelectHome(false);
                setShowAddress(false);
                setLoadingButton(false);
            } catch (err) {
                if (err.response) {
                    openNotificationWithIcon(err?.response?.data?.status, err?.response?.data?.title, err?.response?.data?.message);
                    setLoadingButton(false);
                } else {
                    window.location.reload();
                    openNotificationWithIcon('error', "Something went wrong", err.message);
                    setLoadingButton(false);
                }
            }
        } catch (err) {
            if (err.response) {
                openNotificationWithIcon(err?.response?.data?.status, err?.response?.data?.title, err?.response?.data?.message);
                setLoadingButton(false);
            } else {
                window.location.reload();
                openNotificationWithIcon('error', "Something went wrong", err.message);
                setLoadingButton(false);
            }
        }
    }

    // Function to handle selecting a delivery location
    const handleDelivery = async (id) => {
        try {
            const addressData = await _get_selected_delivery_location({ id: id })
            setLocationId(id)
            setLocationAdded(true)
            const get_home_address = JSON.parse(localStorage.getItem('konnect_cart'))
            get_home_address.delivery_data = addressData.data.data
            setDeliveryData(addressData.data.data)
            get_home_address.location_id = id
            localStorage.setItem('konnect_cart', JSON.stringify(get_home_address))
            dispatch(addToCart(get_home_address))
            setUpdatedAddress(true)
            setLocationAdded(false);
            setShowAddress(false)
        } catch (err) {
            if (err.response) {
                openNotificationWithIcon(err?.response?.data?.status, err?.response?.data?.title, err?.response?.data?.message)
            } else {
                openNotificationWithIcon('error', "Something went wrong", err.message)
            }
        }
    }

    // Function to handle selecting a pickup location
    const handlePickup = async (id) => {
        try {
            const addressData = await _get_selected_pickup_location({ id: id })
            setLocationId(id)
            setLocationAdded(true)
            const data = {
                id,
                user_id: userData.user_id,
                email: userData.email ? userData.email : userData?.user?.email,
                lga: addressData.data.data.lga,
                location_no: addressData.data.data.location_no,
                location_name: addressData.data.data.location_name,
                Address: addressData.data.data.Address,
                city: addressData.data.data.city.city_name,
                floor_no: "01",
                latitude: addressData.data.data.latitude,
                longitude: addressData.data.data.longitude,
                state: addressData.data.data.city.state.name,
            }
            setDeliveryData(data)
            const get_pickup_address = JSON.parse(localStorage.getItem('konnect_cart'))
            get_pickup_address.delivery_data = data;
            get_pickup_address.location_id = id;
            localStorage.setItem('konnect_cart', JSON.stringify(get_pickup_address));
            dispatch(addToCart(get_pickup_address));
            setUpdatedAddress(true);
            setLocationAdded(false);
            setShowAddress(false);
        } catch (err) {
            // console.log(err)
            if (err.response) {
                openNotificationWithIcon(err?.response?.data?.status, err?.response?.data?.title, err?.response?.data?.message)
            } else {
                openNotificationWithIcon('error', "Something went wrong", err.message)
            }
        }
    }

    // Function to handle changing the payment option
    const ChangePaymentOpt = (e) => {
        setPaymentOption(e.target.value);
        setOrderTotals(false)
        setDeliveryDateUpdated(false)
        setDeliveryDate(null)
        if (e.target.value === "pay_now") {
            setPaymentOpt('instant_payment')
            setPay_on_delivery(false)
        } else if (e.target.value === "pay_on_delivery") {
            setPay_on_delivery(true)
            setPaymentOpt('pay_on_delivery')
        } else if (e.target.value === "bnpl") {
            setPay_on_delivery(false)
            setPaymentOpt('buy_now_pay_later')
        }
    }

    // Function to handle the checkout process
    const checkout = async ({ mode, method }) => {
        setPaying(true)
        const get_info = JSON.parse(localStorage.getItem('konnect_cart'))
        try {
            const data = {
                product_id: get_info.product_id,
                product_meta: {
                    location_id: get_info.location_id,
                    order_no: `${get_info.order_id}`,
                    order_size: `${get_info.data.reduce(reduWeight, 0)}`,
                    total_qty: `${get_info.data.reduce(reduQuantity, 0)}`,
                    sub_amount: `${get_info.data.reduce(reduPrice, 0)}`,
                    supplier_price: `${get_info.data.reduce(reduSupplierPrice, 0)}`,
                    tax: "",
                    charges: `${orderTotals?.charges}`,
                    discount: orderTotals.discounts,
                    coupon_amount: orderTotals.coupon,
                    delivery_fee: orderTotals.delivery_fee,
                    cashback: orderTotals.cashback,
                    total_amount: orderTotals.total_amount,
                    items_data: get_info.data,
                    delivery_opt: deliveryMethod,
                    delivery_address: get_info.delivery_data,
                    delivery_date: `${get_info.delivery_date}`,
                    delivery_date_id: `${deliveryDate.id}`,
                    delivery_address_id: `${get_info?.delivery_data?.id}`,
                    narrations: "Product Checkout",
                    payment_opt: `${paymentOpt === "pay_now" ? "pay_after_delivery" : paymentOpt === "pay_on_delivery" ? "pay_on_delivery" : paymentOpt === "bnpl" ? "buy_now_pay_later" : "pay_after_delivery"}`,
                },
                service: "shop",
                payment_mode: `${mode}`,
                payment_method: `${method}`,
                payment_opt: `${paymentOpt === "pay_now" ? "pay_after_delivery" : paymentOpt === "pay_on_delivery" ? "pay_on_delivery" : paymentOpt === "bnpl" ? "buy_now_pay_later" : "pay_after_delivery"}`,
                amount: `${pay_on_delivery ? orderTotals.upfront_payment : orderTotals.total_amount}`,
                save_cade: true
            }
            const make_payment = await pay_checkout({ data })
            localStorage.removeItem('konnect_cart')
            const transactionRef = make_payment?.data?.data?.transaction_ref
            const title = make_payment?.data?.title
            const msg = make_payment?.data?.message
            // openNotificationWithIcon('success', title, msg)
            if (mode === "gateway") {
                window.open(make_payment.data.data.response_data.link, "_self")
            } else {
                navigate(`/confirm-checkout?msg=${encodeURIComponent(msg)}&reference=${encodeURIComponent(transactionRef)}&title=${encodeURIComponent(title)}`);
            }
        } catch (err) {
            setPaying(false)
            if (err.response) {
                openNotificationWithIcon(err?.response?.data?.status, err?.response?.data?.title, err?.response?.data?.message)
            } else {
                openNotificationWithIcon('error', "Something went wrong", err.message)
            }
        }
    }

    // Function to handle payment with Klump
    const payWithKlump = () => {
        let customerFirstName = userData.first_name ? userData.first_name : userData.user.first_name;
        let customerLastName = userData.last_name ? userData.last_name : userData.user.last_name;
        let customerEmail = userData.email ? userData.email : userData.user.email;
        const get_info = JSON.parse(localStorage.getItem('konnect_cart'))
        const editedItems = get_info?.data.map((item) => ({
            image_url: item?.product_image,
            item_url: process.env.REACT_APP_NODE_ENV === "prod" ? `${process.env.REACT_APP_FRONTEND_BASE_URL}/product/${item?.product_name.replace(/ /g, '%20')}/${item?.product_id}` : `${process.env.REACT_APP_FRONTEND_BASE_URL_TEST}/product/${item?.product_name.replace(/ /g, '%20')}/${item?.product_id}`,
            name: item?.product_name,
            unit_price: item?.product_price,
            quantity: item?.quantity,
        }));
        const payload = {
            publicKey: `${process.env.REACT_APP_KLUMPS_TEST_PUBLIC_KEY2}`,
            data: {
                amount: +orderTotals?.total_amount + +orderTotals?.discounts,
                shipping_fee: +orderTotals?.delivery_fee + +orderTotals?.charges,
                currency: 'NGN',
                merchant_reference: `${get_info?.order_id}`,
                meta_data: {
                    user_id: userData?.id ? userData?.id : userData?.user_id,
                    product_id: get_info.product_id,
                    product_meta: {
                        location_id: get_info.location_id,
                        order_no: `${get_info.order_id}`,
                        order_size: `${get_info.data.reduce(reduWeight, 0)}`,
                        total_qty: `${get_info.data.reduce(reduQuantity, 0)}`,
                        sub_amount: `${get_info.data.reduce(reduPrice, 0)}`,
                        supplier_price: `${get_info.data.reduce(reduSupplierPrice, 0)}`,
                        tax: "",
                        charges: `${orderTotals?.charges}`,
                        discount: orderTotals.discounts,
                        coupon_amount: orderTotals.coupon,
                        delivery_fee: orderTotals.delivery_fee,
                        cashback: orderTotals.cashback,
                        total_amount: orderTotals.total_amount,
                        items_data: get_info.data,
                        delivery_opt: deliveryMethod,
                        delivery_address: get_info.delivery_data,
                        delivery_date: `${get_info.delivery_date}`,
                        delivery_date_id: `${deliveryDate.id}`,
                        delivery_address_id: `${get_info?.delivery_data?.id}`,
                        narrations: "Product Checkout",
                        payment_opt: "buy_now_pay_later",
                    },
                    service: "shop",
                    payment_mode: "gateway",
                    payment_method: "klumps",
                    payment_opt: "buy_now_pay_later",
                    amount: `${orderTotals.total_amount}`,
                    save_cade: true,
                    customer: `${customerFirstName} ${customerLastName}`,
                    email: customerEmail
                },
                items: editedItems
            },
            onSuccess: (data) => {
                openNotificationWithIcon('success', 'Order Successful', "")
                localStorage.removeItem('konnect_cart')
                const transactionRef = data?.data?.data?.data?.reference
                const title = 'Order Completed Successfully'
                const msg = 'Click on the order to monitor the progress of your purchase.'
                navigate(`/confirm-checkout?msg=${encodeURIComponent(msg)}&reference=${encodeURIComponent(transactionRef)}&title=${encodeURIComponent(title)}`);
                return data;
            },
            onLoad: (data) => {
                console.log('html onLoad will be handled by the merchant');
            },
            onOpen: (data) => {
                console.log('html OnOpen will be handled by the merchant');
            },
            onError: (data) => {
            },
            onClose: (data) => {
                // openNotificationWithIcon('error', 'Something went wrong', "Payment cancelled, try making the order again.")
            }
        }
        // eslint-disable-next-line no-undef
        new Klump(payload)
    }

    useEffect(() => {
        if (updatedAddress && paymentOption && deliveryDate) {
            DeliveryFees({ id: deliveryDate?.id })
        }
    }, [updatedAddress, paymentOption, deliveryDate])


    // Function to select delivery date
    const selectDeliveryDate = (e) => {
        setDeliveryDate(e?.target?.value);
        setFormattedDate(Moment(e?.target?.value?.date.split('T')[0]).format('dddd, MMMM Do YYYY'));
        const get_delivery_date = JSON.parse(localStorage.getItem('konnect_cart'));
        get_delivery_date.delivery_date = Moment(e.target.value.date).format('dddd, MMMM Do YYYY');
        localStorage.setItem('konnect_cart', JSON.stringify(get_delivery_date));
    }

    // Function to change delivery type
    const changeDeliveryType = (value) => {
        setDeliveryType(value);
        if (value === "delivery") {
            setDeliveryMethod("home");
        } else {
            setDeliveryMethod(value);
        }
    }

    // Function to handle state change
    const onChangeState = (value) => {
        if (value) {
            const allLGAsinAState = NaijaStates.lgas(`${value}`);
            setAllLgas(allLGAsinAState.lgas);
            setUserState(value);
        }
    }

    // Function to verify pin
    const VerifyPin = async (values) => {
        setVerifingPin(true);
        try {
            const verify_pin = await _verify_pin({ pin: values.pin });
            setVerifingPin(false);
            openNotificationWithIcon('success', verify_pin?.data?.title, verify_pin?.data?.message);
            setRevealPinMessage(false);
            setVerifyModal(false);
            await checkout({ mode: "wallet", method: 'wallet' });
        } catch (err) {
            setVerifingPin(false);
            if (err.response) {
                if (err.response.data.message === "Unauthorized") {
                    openNotificationWithIcon(err?.response?.data?.status, err?.response?.data?.title, err?.response?.data?.message);
                    navigate('/signin');
                    setRevealPinMessage(false);
                } else {
                    setRevealPinMessage(err.response.data.message);
                }
            } else {
                setRevealPinMessage(false);
                openNotificationWithIcon('error', "Something went wrong", err.message);
            }
        }
    }

    // Function to select payment option
    const SelectPaymentOpt = async (values) => {
        setPaymentModal(false);
        if (values.payment_mode === "wallet" || meansOfTransfer === "wallet") {
            setVerifyModal(true);
        } else if (values.payment_mode === "gateway" || meansOfTransfer === "gateway") {
            checkout({ mode: "gateway", method: 'card' });
        }
    }

    // Object for buyer's preferred payment option
    const buy_now_data = {
        title: "Buyer's preferred payment option.",
        lists: [
            { content: 'Order is processed if your wallet balance is more than the order amount.' },
            { content: 'Order amount is moved to your Lien account & debited after delivery' },
            { content: 'You can cancel the order at no charges.' },
        ]
    }

    // Object for pay later option
    const buy_later = {
        title: "Pay monthly how it works",
        lists: [
            { content: '<a href="Useklump.com" target="_blank">Useklump.com</a> allows you to buy our food stuffs and pay only 25%.' },
            { content: ' Your bank (First bank etc) will facilitate the balance while you pay back monthly.' },
            { content: 'We deliver on the date you selected.' },
        ]
    }

    // Define the buy_now_later object with pay on delivery option
    const buy_now_later = {
        title: "Pay on delivery option.",
        lists: [
            { content: 'We debit a deposit amount from the buyer\'s account to confirm the order.' },
            { content: 'We debit the balance on delivery before handing over to the buyer.' },
            { content: 'Buyer can cancel at a fee.' },
        ]
    }

    return (
        <div>
            <Spin spinning={spinning} indicator={antIcon}>
                <Navbar />
                {cart_info ?
                    <div className="cart">
                        {loadingPickUpLocations ?
                            <div className="spiningSpace">
                                <Spin indicator={antIcon} />
                            </div>
                            : <div>
                                <div className="new_checkout_data contain">
                                    <div className="mt-5 cart-design-body">
                                        <div>
                                            <Collapse defaultActiveKey={['1', '2', '3', '4']} expandIconPosition="start">
                                                <Panel header="ORDER DETAILS" key="1" extra={
                                                    <div style={{ display: "flex", gap: "10px", alignItems: "center" }} >
                                                        <CheckCircleFilled style={{ color: "green" }} />
                                                        <button className="bg_green no_border" onClick={() => navigate('/cart')}>Update</button>
                                                    </div>
                                                }>
                                                    <div>
                                                        <div>
                                                            {cart_info.data.length ?
                                                                <div className="">
                                                                    <div className="cart-design-tab new_cart">
                                                                        {cart_info.data.map((product, index) => (
                                                                            <div key={index}>
                                                                                <div className={`${cart_info.data.length - index === 1 ? 'no-border' : ''} cart - body - detail`}>
                                                                                    <div className="inner-flex new_cart">
                                                                                        <div className="cart_product_image">
                                                                                            <img style={{ height: "65px" }} src={product.product_image} alt="product" />
                                                                                        </div>
                                                                                        <div className="cart_product_text">
                                                                                            <div className="inner-flex new_cart">
                                                                                                <div>
                                                                                                    <h4>{product.product_name}</h4>
                                                                                                    <p className="cart_product_description">
                                                                                                        <span className="small_currency">NGN</span>
                                                                                                        <NumberFormat value={product.product_price} className="product-price" displayType={'text'} prefix={""} thousandSeparator={true} /> each
                                                                                                    </p>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="mobile">
                                                                                                <div className="mobile-flex-cover">
                                                                                                    <div className="mobile-flex">
                                                                                                        <Select defaultValue={product.quantity}
                                                                                                            disabled
                                                                                                            className="select-quantity" style={{ width: 80 }}>
                                                                                                            <Select.Option value={product.quantity}>{product.quantity}</Select.Option>
                                                                                                        </Select>
                                                                                                        <p>
                                                                                                            <span className="small_currency">NGN</span>
                                                                                                            <NumberFormat value={product.total_price} className="product-price" displayType={'text'} prefix={""} thousandSeparator={true} />
                                                                                                        </p>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>

                                                                                    <div className="small-flex">
                                                                                        <Select defaultValue={product.quantity}
                                                                                            disabled
                                                                                            className="select-quantity" style={{ width: 80, margin: '0% auto' }}>
                                                                                            <Select.Option value={product.quantity}>{product.quantity}</Select.Option>
                                                                                        </Select>
                                                                                    </div>
                                                                                    <div className="small-flex">
                                                                                        <p>
                                                                                            <span className="small_currency">NGN</span>
                                                                                            <NumberFormat value={product.total_price} className="product-price" displayType={'text'} prefix={""} thousandSeparator={true} />
                                                                                        </p>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        ))}
                                                                    </div>
                                                                </div>
                                                                :
                                                                <div className="empty-cart">
                                                                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}
                                                                        description={
                                                                            <span>
                                                                                You have no products in cart yet? <br />
                                                                                <Link to="/shop">Go to shop</Link>
                                                                            </span>
                                                                        }
                                                                    />
                                                                </div>
                                                            }
                                                        </div>
                                                    </div>
                                                    {/* </div> */}
                                                </Panel>
                                                <Panel header="CHOOSE DELIVERY OPTION" key="2" extra={
                                                    <div style={{ display: "flex", gap: "10px", alignItems: "center" }} >
                                                        {updatedAddress ?
                                                            <CheckCircleFilled style={{ color: "green" }} /> :
                                                            <CheckCircleFilled />
                                                        }
                                                    </div>
                                                }>
                                                    <>
                                                        {locationAdded ?
                                                            <div className='d-flex flex-column'>
                                                                <span style={{ marginLeft: "auto", marginBottom: "10px" }} className="col-12">
                                                                    <label>Select Delivery Type</label>
                                                                    <Select placeholder="Select Delivery Type"
                                                                        style={{ height: '5rem' }}
                                                                        value={deliveryType} onChange={(value) => changeDeliveryType(value)}>
                                                                        <Select.Option value="delivery">Delivery Address</Select.Option>
                                                                        <Select.Option value="pickup">Pickup Delivery</Select.Option>
                                                                    </Select>
                                                                </span>
                                                                {deliveryType === "delivery" ?
                                                                    <>
                                                                        {allDeliveryLocations.length ?
                                                                            <div className="col-12">
                                                                                <label>Select Delivery Address</label>
                                                                                <Select onChange={handleDelivery} defaultValue={locationId}
                                                                                    style={{ height: '5rem' }}
                                                                                    laceholder="Choose Delivery Option" className='d-flex flex-column'>
                                                                                    <Select.Option value="">Select a delivery address</Select.Option>
                                                                                    {allDeliveryLocations.map((deliveryLocation, index) => (
                                                                                        <Select.Option key={index} value={deliveryLocation.id}>
                                                                                            <span>{deliveryLocation.Address}, {deliveryLocation.city}, {deliveryLocation.state}</span>
                                                                                        </Select.Option>
                                                                                    ))}
                                                                                </Select>
                                                                                <span style={{ fontSize: '1.2rem', fontStyle: 'italic' }}>check drop down to pick new address</span>
                                                                            </div>
                                                                            :
                                                                            <div className="col-12">
                                                                                <label>Select Delivery Address</label>
                                                                                <Select
                                                                                    style={{ height: '5rem' }}
                                                                                    placeholder="Choose Delivery Option" className='d-flex flex-column'></Select>
                                                                                <span style={{ fontSize: '1.2rem', fontStyle: 'italic' }}>check drop down to pick new address</span>
                                                                            </div>
                                                                        }
                                                                    </>
                                                                    :
                                                                    <>
                                                                        {allLocations?.length ?
                                                                            (
                                                                                <div className="col-12">
                                                                                    <label>Select Pickup Location</label>
                                                                                    <Select onChange={handlePickup}
                                                                                        style={{ height: '5rem' }}
                                                                                        defaultValue={locationId} placeholder="Choose Pickup Option" className='d-flex flex-column'>
                                                                                        <Select.Option value="">Select a delivery address</Select.Option>
                                                                                        {allLocations.map((location, index) => (
                                                                                            <Select.Option key={index} value={location.id}>
                                                                                                <span>{location.Address},  {location.city.city_name}, {location.city.state.name}</span>
                                                                                            </Select.Option>
                                                                                        ))}
                                                                                    </Select>
                                                                                </div>
                                                                            ) :
                                                                            <div className="col-12">
                                                                                <label>Select Pickup Address</label>
                                                                                <Select laceholder="Choose Pickup Address"
                                                                                    style={{ height: '5rem' }}
                                                                                    className='d-flex flex-column'></Select>
                                                                            </div>
                                                                        }
                                                                    </>
                                                                }
                                                            </div>
                                                            :
                                                            <div className='d-flex flex-column'>
                                                                {deliveryData &&
                                                                    <span style={{ marginLeft: "auto", marginBottom: "10px" }} className="col-12">
                                                                        <span style={{ textTransform: "capitalize" }}>{deliveryType}</span><br />
                                                                        <span style={{ textTransform: "capitalize" }}>{deliveryData.Address}, {deliveryData.city}, {deliveryData.state} state</span>
                                                                    </span>
                                                                }
                                                            </div>
                                                        }
                                                    </>
                                                    <div className="justify-content-between col-12 mt-3">
                                                        {!locationAdded &&
                                                            <button className="bg_green no_border" onClick={() => { setLocationAdded(true); setShowAddress(false); setUpdatedAddress(false); setDeliveryData(false); setLocationId("") }}>Change Address</button>
                                                        }
                                                        {deliveryType === "delivery" && locationAdded &&
                                                            <React.Fragment>
                                                                <button className="bg_green no_border" onClick={() => { setShowAddress(true); setSelectHome(true) }}>Add Delivery Address</button>
                                                            </React.Fragment>
                                                        }
                                                    </div>
                                                </Panel>
                                                <Panel header="PAYMENT OPTIONS" key="3" extra={genPayment()}>
                                                    <Radio.Group onChange={(e) => { ChangePaymentOpt(e) }} value={paymentOption}>
                                                        <Space direction='vertical'>
                                                            {userData?.kyc_status !== "verified" ?
                                                                <>
                                                                    <Radio value="pay_now"><b style={{ paddingRight: "5px" }}>Buy now & pay in full </b></Radio>
                                                                    {/* <Radio value="pay_on_delivery"><b style={{ paddingRight: "5px" }}>Buy now & pay on delivery</b></Radio> */}
                                                                    <Radio value="bnpl"><b style={{ paddingRight: "5px" }}>Buy now & pay monthly </b><span><HowItWorks data={buy_later} /></span></Radio>
                                                                </> :
                                                                <>
                                                                    <Radio value="pay_now"><b style={{ paddingRight: "5px" }}>Buy now & pay after delivery </b><span><HowItWorks data={buy_now_data} /></span> </Radio>
                                                                    {/* <Radio value="pay_on_delivery"><b style={{ paddingRight: "5px" }}>Buy now & pay on delivery</b><span><HowItWorks data={buy_now_later} /></span></Radio> */}
                                                                    <Radio value="bnpl"><b style={{ paddingRight: "5px" }}>Buy now & pay monthly </b><span><HowItWorks data={buy_later} /></span></Radio>
                                                                </>
                                                            }

                                                        </Space>
                                                    </Radio.Group>
                                                </Panel>
                                                <Panel header="DELIVERY DATE OPTIONS:" key="4" extra={genExtra()}>
                                                    {deliveryDateUpdated ?
                                                        <span>
                                                            {formattedDate &&
                                                                <>
                                                                    <p style={{ display: 'flex', alignItems: 'center' }} >Selected Delivery Date : <b>{formattedDate}</b></p>
                                                                </>
                                                            }
                                                        </span>
                                                        :
                                                        <div>
                                                            {allDate.length === 0 && paymentOption ?
                                                                <b>We're sorry, no delivery date due to the fluctuation of price. Kindly check back tomorrow or try another payment option.</b>
                                                                :
                                                                <Radio.Group onChange={(e) => { selectDeliveryDate(e); setDeliveryDateUpdated(true) }} value={formattedDate}>
                                                                    <Space direction='vertical'>
                                                                        {loadingDate && !dateRestrictions ?
                                                                            <Spin />
                                                                            :
                                                                            <>
                                                                                {
                                                                                    allDate.map((date) => {
                                                                                        const shouldRender =
                                                                                            !(currentDate.isSameOrAfter(moment(date?.date.split('T')[0], 'YYYY-MM-DD').clone().subtract(+dateRestrictions, 'day'), 'day')) &&
                                                                                            !(currentDate.isSame(moment(date?.date.split('T')[0], 'YYYY-MM-DD'), 'day')) &&
                                                                                            !!(currentDate.isSameOrBefore(moment(date?.date.split('T')[0], 'YYYY-MM-DD').clone().add(+dateRestrictions, 'day'), 'day'));

                                                                                        const shouldDisplay =
                                                                                            (paymentOption === 'pay_now' && !(date.total_pay_now >= date.pay_now_limit)) ||
                                                                                            (paymentOption === 'bnpl' && !(date.total_bnpl >= date.bnpl_limit)) ||
                                                                                            (paymentOption === 'pay_on_delivery' && !(date.total_pay_on_delivery >= date.pay_on_delivery_limit));

                                                                                        if (shouldRender && shouldDisplay) {
                                                                                            return (
                                                                                                <Radio key={date?.id} value={date}>
                                                                                                    <div style={{ display: "flex", gap: "10px", fontStyle: "bold" }}>
                                                                                                        <ReactMoment format="dddd, MMMM Do YYYY">{date?.date.split('T')[0]}</ReactMoment>
                                                                                                    </div>
                                                                                                </Radio>
                                                                                            );
                                                                                        }
                                                                                        return null;
                                                                                    })
                                                                                }
                                                                            </>
                                                                        }
                                                                    </Space>
                                                                </Radio.Group>
                                                            }
                                                        </div>
                                                    }
                                                    {deliveryDateUpdated &&
                                                        <button style={{ marginTop: "10px" }} className="bg_green no_border" onClick={() => { setDeliveryDateUpdated(false); setDeliveryDate(null) }}>Update Delivery Date</button>
                                                    }
                                                </Panel>
                                            </Collapse>
                                        </div>
                                        <div>
                                            <div className="cart-brief">
                                                <div className="cart-brief-data">
                                                    <div>
                                                        <ul className="first-list">
                                                            {orderTotals ?
                                                                <>
                                                                    <li>
                                                                        <span className="first">Order size </span>
                                                                        <span className="second">{cart_info.data.reduce(reduWeight, 0).toFixed(2)} kg</span>
                                                                    </li>
                                                                    <Divider style={{ clear: 'both', width: '100%', margin: '5px 0' }} />
                                                                    <li>
                                                                        <span className="first">Subtotal </span>
                                                                        <span className="second">
                                                                            <span className="small_currency"></span>
                                                                            <NumberFormat prefix="" value={cart_info.data.reduce(reduPrice, 0).toFixed(2)} className="foo" displayType={'text'} thousandSeparator={true} />
                                                                        </span>
                                                                    </li>
                                                                    <li>
                                                                        <span className="first">Delivery Fee</span>
                                                                        <span className="second"><NumberFormat prefix="" value={Number(orderTotals.delivery_fee).toFixed(2)} className="foo" displayType={'text'} thousandSeparator={true} /></span>
                                                                    </li>
                                                                    <li>
                                                                        <span className="first">Discounts</span>
                                                                        <span className="second">
                                                                            {paymentOption === "bnpl" ?
                                                                                <NumberFormat prefix="" value={Number(0).toFixed(2)} className="foo" displayType={'text'} thousandSeparator={true} /> :
                                                                                <NumberFormat prefix="" value={Number(orderTotals.discounts).toFixed(2)} className="foo" displayType={'text'} thousandSeparator={true} />
                                                                            }
                                                                        </span>
                                                                    </li>
                                                                    <li>
                                                                        <span className="first">Charges</span>
                                                                        <span className="second"><NumberFormat prefix="" value={Number(orderTotals?.charges).toFixed(2)} className="foo" displayType={'text'} thousandSeparator={true} /></span>
                                                                    </li>
                                                                    <li>
                                                                        <span className="first">Coupon</span>
                                                                        <span className="second"><NumberFormat prefix="" value={Number(orderTotals.coupon).toFixed(2)} className="foo" displayType={'text'} thousandSeparator={true} /></span>
                                                                    </li>
                                                                    <Divider style={{ clear: 'both', width: '100%', margin: '5px 0' }} />
                                                                    <li>
                                                                        <span className="first">Total </span>
                                                                        <span className="second">
                                                                            <span className="small_currency">NGN </span>
                                                                            {paymentOption === "bnpl" ?
                                                                                <NumberFormat prefix="" value={Number(+orderTotals.total_amount + +orderTotals.discounts).toFixed(2)} className="foo" displayType={'text'} thousandSeparator={true} /> :
                                                                                <NumberFormat prefix="" value={Number(orderTotals.total_amount).toFixed(2)} className="foo" displayType={'text'} thousandSeparator={true} />
                                                                            }
                                                                        </span>
                                                                    </li>
                                                                    {pay_on_delivery && <>
                                                                        <Divider style={{ clear: 'both', width: '100%', margin: '5px 0' }} />
                                                                        <li>
                                                                            <span className="first">Upfront Payment</span>
                                                                            <span className="second">
                                                                                <span className="small_currency">NGN </span>
                                                                                <NumberFormat prefix="" value={Number(orderTotals.upfront_payment).toFixed(2)} className="foo" displayType={'text'} thousandSeparator={true} />
                                                                            </span>
                                                                        </li>
                                                                        <li>
                                                                            <span className="first">Balance</span>
                                                                            <span className="second">
                                                                                <span className="small_currency">NGN </span>
                                                                                <NumberFormat prefix="" value={Number(orderTotals.balance).toFixed(2)} className="foo" displayType={'text'} thousandSeparator={true} />
                                                                            </span>
                                                                        </li>
                                                                    </>
                                                                    }
                                                                </> :
                                                                !orderTotals && summaryLoading ?
                                                                    <>
                                                                        <li>
                                                                            <span className="first">Order size </span>
                                                                            <span className="second"><Spin /></span>
                                                                        </li>
                                                                        <Divider style={{ clear: 'both', width: '100%', margin: '5px 0' }} />
                                                                        <li>
                                                                            <span className="first">Subtotal </span>
                                                                            <span className="second">
                                                                                <span className="small_currency"></span>
                                                                                <Spin />
                                                                            </span>
                                                                        </li>
                                                                        <li>
                                                                            <span className="first">Delivery Fee</span>
                                                                            <span className="second"><Spin /></span>
                                                                        </li>
                                                                        <li>
                                                                            <span className="first">Discounts</span>
                                                                            <span className="second"><Spin /></span>
                                                                        </li>
                                                                        <li>
                                                                            <span className="first">Charges</span>
                                                                            <span className="second"><Spin /></span>
                                                                        </li>
                                                                        <li>
                                                                            <span className="first">Coupon</span>
                                                                            <span className="second"><Spin /></span>
                                                                        </li>
                                                                        <Divider style={{ clear: 'both', width: '100%', margin: '5px 0' }} />
                                                                        <li>
                                                                            <span className="first">Total </span>
                                                                            <span className="second"><Spin /></span>
                                                                        </li>
                                                                    </> :
                                                                    <>
                                                                        <li>
                                                                            <span className="first">Order size </span>
                                                                            <span className="second">
                                                                                <span className="small_currency">NGN </span>
                                                                                <NumberFormat prefix="" value={Number(0).toFixed(2)} className="foo" displayType={'text'} thousandSeparator={true} /></span>
                                                                        </li>
                                                                        <Divider style={{ clear: 'both', width: '100%', margin: '5px 0' }} />
                                                                        <li>
                                                                            <span className="first">Subtotal </span>
                                                                            <span className="second">
                                                                                <span className="small_currency">NGN </span>
                                                                                <NumberFormat prefix="" value={Number(0).toFixed(2)} className="foo" displayType={'text'} thousandSeparator={true} />
                                                                            </span>
                                                                        </li>
                                                                        <li>
                                                                            <span className="first">Delivery Fee</span>
                                                                            <span className="second"><span className="small_currency">NGN </span>
                                                                                <NumberFormat prefix="" value={Number(0).toFixed(2)} className="foo" displayType={'text'} thousandSeparator={true} /></span>
                                                                        </li>
                                                                        <li>
                                                                            <span className="first">Discounts</span>
                                                                            <span className="second"><span className="small_currency">NGN </span>
                                                                                <NumberFormat prefix="" value={Number(0).toFixed(2)} className="foo" displayType={'text'} thousandSeparator={true} /></span>
                                                                        </li>
                                                                        <li>
                                                                            <span className="first">Charges</span>
                                                                            <span className="second"><span className="small_currency">NGN </span>
                                                                                <NumberFormat prefix="" value={Number(0).toFixed(2)} className="foo" displayType={'text'} thousandSeparator={true} /></span>
                                                                        </li>
                                                                        <li>
                                                                            <span className="first">Coupon</span>
                                                                            <span className="second"><span className="small_currency">NGN </span>
                                                                                <NumberFormat prefix="" value={Number(0).toFixed(2)} className="foo" displayType={'text'} thousandSeparator={true} /></span>
                                                                        </li>
                                                                        <Divider style={{ clear: 'both', width: '100%', margin: '5px 0' }} />
                                                                        <li>
                                                                            <span className="first">Total </span>
                                                                            <span className="second">
                                                                                <span className="small_currency">NGN </span>
                                                                                <NumberFormat prefix="" value={Number(0).toFixed(2)} className="foo" displayType={'text'} thousandSeparator={true} />
                                                                            </span>
                                                                        </li>
                                                                    </>
                                                            }
                                                        </ul>
                                                        {(!minKlumps && minCheckoutData) && <p style={{ color: 'red', fontSize: '1.3rem' }}>A minimum order of NGN {(+minCheckoutData?.value).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')} is required before proceeding to checkout for pay monthly. Please update your cart accordingly.</p>}
                                                        {paymentOption === "pay_now" &&
                                                            <>
                                                                {updatedAddress && paymentOption && deliveryDate && orderTotals ?
                                                                    <>
                                                                        {paying ? (
                                                                            <button disabled style={{ width: '100%', marginTop: '5%', opacity: "0.7" }} className="pay_bg_green"><Spin indicator={spinningIcon} /></button>
                                                                        ) : (
                                                                            <>
                                                                                {userData?.kyc_status !== "verified" ?
                                                                                    <button onClick={() => SelectPaymentOpt({})} style={{ width: '100%', marginTop: '5%' }} className="pay_bg_green">Pay now</button> :
                                                                                    <button onClick={() => setPaymentModal(true)} style={{ width: '100%', marginTop: '5%' }} className="pay_bg_green">Confirm this order</button>
                                                                                }
                                                                                {/* <button onClick={() => setPaymentModal(true)} style={{ width: '100%', marginTop: '5%' }} className="pay_bg_green"><small>Pay </small> NGN <NumberFormat prefix="" value={Number(orderTotals?.total_amount).toFixed(2)} className="foo" displayType={'text'} thousandSeparator={true} /> <small> Now</small></button> */}
                                                                            </>
                                                                        )}
                                                                    </> :

                                                                    <>
                                                                        <button disabled style={{ width: '100%', marginTop: '5%', opacity: "0.7" }} className="pay_bg_green">Pay Now</button>
                                                                    </>
                                                                }
                                                            </>
                                                        }
                                                        {paymentOption === "bnpl" &&
                                                            <>
                                                                {updatedAddress && paymentOption && deliveryDate && orderTotals && minKlumps && orderTotals ?
                                                                    <>
                                                                        {paying ?
                                                                            <button disabled style={{ width: '100%', marginTop: '5%', opacity: "0.7" }} className="pay_bg_green"><Spin indicator={spinningIcon} /></button>
                                                                            :
                                                                            <>
                                                                                <div hidden>
                                                                                    <KlumpCheckout onClick={payWithKlump} />
                                                                                </div>
                                                                                <button style={{ width: '100%', marginTop: '5%' }} onClick={payWithKlump} className="pay_bg_blue">Pay in 4 installment</button>
                                                                            </>
                                                                        }
                                                                    </> :
                                                                    <>
                                                                        <button disabled style={{ width: '100%', marginTop: '5%', opacity: "0.7" }} className="pay_bg_blue">Pay with Klump</button>
                                                                    </>
                                                                }
                                                            </>
                                                        }
                                                        {paymentOption === "pay_on_delivery" &&
                                                            <>
                                                                {updatedAddress && paymentOption && deliveryDate && orderTotals?.upfront_payment && orderTotals ?
                                                                    <>
                                                                        {paying ? (
                                                                            <button disabled style={{ width: '100%', marginTop: '5%', opacity: "0.7" }} className="pay_bg_green"><Spin indicator={spinningIcon} /></button>
                                                                        ) : (
                                                                            <>
                                                                                {userData?.kyc_status !== "verified" ?
                                                                                    <button onClick={() => SelectPaymentOpt({})} style={{ width: '100%', marginTop: '5%' }} className="pay_bg_green"><small>Pay </small> NGN <NumberFormat prefix="" value={Number(orderTotals?.upfront_payment).toFixed(2)} className="foo" displayType={'text'} thousandSeparator={true} /> <small> Now</small></button> :
                                                                                    <button onClick={() => setPaymentModal(true)} style={{ width: '100%', marginTop: '5%' }} className="pay_bg_green"><small>Pay </small> NGN <NumberFormat prefix="" value={Number(orderTotals?.upfront_payment).toFixed(2)} className="foo" displayType={'text'} thousandSeparator={true} /> <small> Now</small></button>
                                                                                }
                                                                            </>
                                                                        )}
                                                                    </> :
                                                                    <>
                                                                        <button disabled style={{ width: '100%', marginTop: '5%', opacity: "0.7" }} className="pay_bg_green">Pay Now</button>
                                                                    </>
                                                                }
                                                            </>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                    </div > :
                    <Button onClick={navigate('/cart')}></Button>
                }
                <div style={{ marginTop: 60 }}></div>
                <Footer />
            </Spin >
            <Drawer title="Address Form" placement="right" size="default" onClose={() => setSelectHome(false)} open={selectHome} extra={<Space><Button onClick={() => setSelectHome(false)}>Cancel</Button></Space>} >
                <div style={{ marginTop: "10px" }} />
                <div style={{ padding: "10px" }} className='checkout_center'>
                    <form onSubmit={handleAddressFormSubmit(home_delivery_address)} autoComplete="off">
                        <div className="form-flex-2" style={{ width: '100%' }}>
                            <div className="form-group">
                                <label htmlFor="">Email Address</label>
                                <Controller name="email" control={controlAddressForm}
                                    render={({ field }) => (
                                        <Input style={{ width: '100%', height: '5rem' }} disabled {...field}
                                            defaultValue={`${fetchDataStatus && userData.user ? userData.user.email : userData.email}`} />
                                    )} />
                            </div>
                            <div className="form-group">
                                <label htmlFor="">Phone Number</label>
                                <Controller name="phone_no" control={controlAddressForm}
                                    render={({ field }) => (
                                        <Input style={{ width: '100%', height: '5rem' }} {...field}
                                            defaultValue="" />
                                    )} />
                            </div>
                        </div>
                        <div style={{ width: '100%' }}>
                            <div className="form-group">
                                <label htmlFor="">Address</label>
                                <Controller name="home_address" control={controlAddressForm}
                                    render={({ field }) => (
                                        <Input style={{ width: '100%', height: '5rem' }} {...field}
                                            defaultValue="" />
                                    )} />
                            </div>
                            <div className="form-group">
                                <label htmlFor="">Floor Number</label>
                                <Controller name="floor_no" control={controlAddressForm}
                                    render={({ field }) => (
                                        <Input style={{ width: '100%', height: '5rem' }} {...field}
                                            defaultValue="" />
                                    )} />
                            </div>
                        </div>
                        <div className="form-flex-2" style={{ width: '100%' }}>
                            <div className="form-group">
                                <label htmlFor="">State</label>
                                <Select placeholder="Select State"
                                    onChange={onChangeState}
                                    control={controlAddressForm} name="state"
                                    style={{ width: '100%', height: '5rem' }}>
                                    {allStates.map((state) => (
                                        <Select.Option key={state.id} value={state.name}>{state.name}</Select.Option>
                                    ))}
                                </Select>
                            </div>
                            <div className="form-group">
                                <label htmlFor="lga">LGA</label>
                                {allStates ?
                                    <Controller name="lga" control={controlAddressForm}
                                        render={({ field }) => (
                                            <Select placeholder="Select LGA" {...field}
                                                name="lga"
                                                style={{ width: '100%', height: '5rem' }}>
                                                {allLgas.map((lga, index) => (
                                                    <Select.Option key={index} value={lga}>{lga}</Select.Option>
                                                ))}
                                            </Select>
                                        )} /> :
                                    <Select name="lga" placeholder="Select LGA"
                                        style={{ width: '100%', height: '5rem' }}></Select>
                                }
                            </div>
                        </div>
                        <div style={{ marginTop: 40 }}></div>
                        <div className="grid_flex checkout_center">
                            <div></div>
                            {
                                !loadingButton ?
                                    <button
                                        className="bg_green no_border">Save Address</button> :
                                    <button
                                        className="bg_green no_border"><Spin indicator={spinningIcon} /></button>
                            }
                        </div>
                    </form>
                </div>
            </Drawer>
            <Modal open={howItWorksModal} onCancel={() => setHowItWorksModal(false)} footer={null} >
                <div>
                    <div className="referral topup howbnplworks">
                        <div>
                            <h3>How It Works</h3>
                            <ul>
                                <li><ion-icon name="checkmark-circle-outline"></ion-icon> A Wise9ja user is activated when they top-up their Wise9ja account</li>
                                <li><ion-icon name="checkmark-circle-outline"></ion-icon> Top-up amount attracts instant purchase voucher & monthly cash while
                                    we pay back the top-up amount at month 6.</li>
                                <li><ion-icon name="checkmark-circle-outline"></ion-icon> BNPL allows Wise9ja user to buy any item equivalent to
                                    their top-up balance amount and pay nothing at checkout.</li>
                                <li><ion-icon name="checkmark-circle-outline"></ion-icon> They pay back the checkout amount in 4 months at 0% interest.</li>
                                <li><ion-icon name="checkmark-circle-outline"></ion-icon> Simply grow your cash as a Wise9ja user, shop and pay fully in 4 months.</li>
                                <li><ion-icon name="checkmark-circle-outline"></ion-icon> BNPL is the preferred option as you enjoy instant purchase voucher
                                    that reduces your checkout amount, and your top-up amount also grows monthly</li>
                            </ul>
                            <button onClick={() => setHowItWorksModal(false)}>Close</button>
                        </div>
                    </div>
                </div>
            </Modal>
            <Modal centered open={verifyModal} onCancel={() => setVerifyModal(false)} footer={null} >
                <div>
                    <h4 className="modal-title">Transaction Pin</h4>
                    {userData?.pin === "0000" ?
                        <p>Activate 2fa and create transaction pin</p> :
                        <p>Enter your transaction pin.</p>
                    }
                    <div style={{ marginTop: '5%' }}></div>
                    {revealPinMessage ? <p className="error-message">{revealPinMessage}</p> : ''}
                    <Form layout="vertical" style={{ maxWidth: 600 }} onFinish={VerifyPin} autoComplete="off">
                        <Form.Item label="Pin" name="pin">
                            {userData?.pin === "0000" ?
                                <Input.Password placeholder="Enter Pin" disabled />
                                :
                                <Input.Password placeholder="Enter Pin" autoComplete="off" />
                            }
                        </Form.Item>
                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }} >
                            {userData?.pin === "0000" ?
                                <>
                                    <Button type='primary' onClick={() => { navigate(`/profile/overview?pin=${true}`) }}>Create Transaction Pin</Button>
                                </>
                                :
                                <>
                                    {verifingPin ?
                                        <Button type="primary" loading disabled>Verifing Pin</Button> :
                                        <Button type="primary" htmlType="submit">Verify Pin</Button>
                                    }
                                </>
                            }
                            <Button onClick={() => setVerifyModal(false)} >Cancel</Button>
                        </div>
                    </Form>
                </div>
            </Modal>

            {/* Payment Modal */}
            <Modal centered open={paymentModal} onCancel={() => setPaymentModal(false)} footer={null} >
                <div>
                    {paymentOption !== "pay_now" &&
                        <h4 className="modal-title">Payment Info</h4>
                    }
                    <div style={{ marginTop: '5%' }}></div>
                    <Form layout="vertical" style={{ maxWidth: 600 }} initialValues={{ payment_mode: meansOfTransfer }} onFinish={SelectPaymentOpt} autoComplete="off">
                        {/* {paymentOption !== "pay_now" &&
                            <Form.Item label="Payment Option" name="payment_mode">
                                <Select onChange={({ value }) => setMeansOfTransfer(value)} defaultValue={meansOfTransfer} placeholder="Select Payment Mode">
                                    <Select.Option value="wallet">Wallet</Select.Option>
                                    {paymentOption !== "pay_now" &&
                                        <Select.Option value="gateway">Standard Payment</Select.Option>
                                    }
                                </Select>
                            </Form.Item>
                        } */}
                        {paymentOption === "pay_now" &&
                            <>
                                {meansOfTransfer === "wallet" &&
                                    <p style={{ marginBottom: "5px", textAlign: "center" }}>You have a wallet balance of <b><NumberFormat thousandSeparator={true} value={accountBalance?.konnect_bal || 0} prefix={'NGN '} displayType="text" className="numeric" /></b>.{(+accountBalance?.konnect_bal < +orderTotals?.total_amount) ? <span><br />To confirm this order, you have to fund your wallet wallet with a minimum <b><NumberFormat thousandSeparator={true} value={+orderTotals?.total_amount - +accountBalance?.konnect_bal} prefix={'NGN '} displayType="text" className="numeric" /></b>.</span> : <span><br />We will move <b><NumberFormat thousandSeparator={true} value={+orderTotals?.total_amount} prefix={'NGN '} displayType="text" className="numeric" /></b> to your Lien account and debit it after we have delivered.</span>}</p>
                                }
                            </>
                        }
                        {paymentOption !== "pay_now" &&
                            <>
                                {meansOfTransfer === "wallet" &&
                                    <p style={{ marginBottom: "5px", textAlign: "center" }}>You have a wallet balance of <b><NumberFormat thousandSeparator={true} value={accountBalance?.wallet_bal || 0} prefix={'NGN '} displayType="text" className="numeric" /></b>. {(+accountBalance?.wallet_bal < +orderTotals?.upfront_payment) ? <span><br />To confirm this order, you have to fund your Konnect wallet with a minimum <b><NumberFormat thousandSeparator={true} value={+orderTotals?.upfront_payment - +accountBalance?.wallet_bal} prefix={'NGN '} displayType="text" className="numeric" /></b>.</span> : <span><br />We will debit <b><NumberFormat thousandSeparator={true} value={+orderTotals?.upfront_payment} prefix={'NGN '} displayType="text" className="numeric" /></b> today.</span>}</p>
                                }
                            </>
                        }
                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }} >
                            <Button onClick={() => setPaymentModal(false)} >Cancel</Button>
                            {paymentOption === "pay_now" &&
                                <>
                                    {(meansOfTransfer === "wallet" && (+accountBalance?.konnect_bal < +orderTotals?.total_amount)) ?
                                        <Button type="primary" onClick={() => navigate(`/profile/loyalty?fundKonnect=true&minAmount=${+orderTotals?.total_amount - +accountBalance?.konnect_bal} `)}>Fund Wallet</Button>
                                        :
                                        <Button type="primary" htmlType="submit">Complete Order</Button>
                                    }
                                </>
                            }
                            {paymentOption !== "pay_now" &&
                                <>
                                    {(meansOfTransfer === "wallet" && (+accountBalance?.wallet_bal < +orderTotals?.upfront_payment)) ?
                                        <Button type="primary" onClick={() => navigate(`/profile/loyalty?fundWallet=true&minAmount=${+orderTotals?.upfront_payment - +accountBalance?.wallet_bal} `)}>Fund Wallet</Button>
                                        :
                                        <Button type="primary" htmlType="submit">Pay Now</Button>
                                    }
                                </>
                            }
                        </div>
                    </Form>
                </div>
            </Modal >
        </div >
    )}

export default Checkout
