import React, { useEffect, useState } from 'react';
import {Drawer} from "antd";
import { ReactComponent as Cancel } from '../assets/images/icons/x.svg';




const RightDrawerTemplate = ({closeDrawer, openDrawer, width, title, children, sm =false }) => {

    const [isSmallScreen, setIsSmallScreen] = useState(false);

    useEffect(() => {
        if (sm) {
            const handleResize = () => {
                setIsSmallScreen(window.innerWidth <= 768); // Adjust breakpoint as needed
            };

            handleResize(); // Check screen size on mount

            window.addEventListener("resize", handleResize);
            return () => window.removeEventListener("resize", handleResize);
        }
    }, [sm]);
    return (
        <Drawer
            placement={
                sm && isSmallScreen ? "bottom" : "right" // Use bottom for small screens if dynamic
            }
            onClose={closeDrawer}
            open={openDrawer}
            width={ width }
            className="checkoutDrawer_wrapper"
            height="80%"
        >
            <div className="w-full px-8 py-4">
                <div className="drawer_header py-3 flex justify-between">
                    <h2 className="text-4xl"> {title} </h2>
                    <Cancel onClick={closeDrawer} className="cancel cursor-pointer"/>
                </div>
                <hr />
                {children}
            </div>
        </Drawer>
    );
};

export default RightDrawerTemplate;