import React, {useEffect, useState} from 'react'
import {Collapse, Divider, Modal, notification, Pagination, Spin, Table, Tabs, Tag} from 'antd'
import {LoadingOutlined} from '@ant-design/icons'

//	styles
import '../../assets/css/shop.css'
import '../../assets/css/restaurnt.css'

// compoenents
import Navbar from '../../components/Navbar'
import RestaurantSideNav from '../../components/dashboard/restaurant_side_nav'
import InfoSlider from '../../components/Restaurant/Dashboard/InfoSlider'
import NumberFormat from 'react-number-format'
import {DateTime} from 'luxon'
import {_get_vendor_restaurant} from '../../common/axios_services'
import {useNavigate} from 'react-router-dom'
import MobileDashTabs from '../../components/dashboard/MobileDashTabs'
import {_fetch_user_wallet_data, _get_profile} from '../../common/axios_services';
import BecomeRestaurant from '../../components/modals/BecomeRestaurant';
import {Switch} from 'antd';
import moment from 'moment';
import waiting_approval from '../../assets/images/restaurant/waiting_approval.png';
import failed_approval from '../../assets/images/restaurant/failed_approval.mp4'




const RestaurantDashboard = () => {
	const {TabPane} = Tabs;
	const newAntIcon = <LoadingOutlined style={{fontSize: 24, color: '#008060'}} spin />;
	const navigate = useNavigate();
	const TodayHistoryColumn = [
		{
			title: 'S/N',
			dataIndex: 'sn',
		},
		{
			title: 'Time',
			dataIndex: 'time',
		},
		{
			title: 'Customer',
			className: 'column-number',
			dataIndex: 'customer',
		},
		{
			title: 'Distance',
			className: 'column-number',
			dataIndex: 'distance',
		},
		{
			title: 'Item',
			className: 'column-number',
			dataIndex: 'item',
		},
		{
			title: 'Sales',
			className: 'column-number',
			dataIndex: 'sales',
		},
		{
			title: 'Status',
			className: 'column-number',
			dataIndex: 'status',
			align: 'center',
		},
		{
			title: 'Action',
			className: 'column-number',
			dataIndex: 'action',
			align: 'center',
		}
	]
	const today_history_data = []

	const openNotificationWithIcon = (type, title, message) => {
		notification[type]({
			message: title,
			description: message,
			placement: "bottom-right"
		});
	};

	// States
	const openTimeFormat = 'HH:mm'; // Use HH:mm format for hours and minutes
	const [spinnerLoading, setSpinnerLoading] = React.useState(false);
	const [today_page, setTodayPage] = React.useState(1);
	const [today_per_page, setTodayPerPage] = React.useState(10);
	const [today_total, setTodayTotal] = React.useState(10);
	const [today_history, setTodayHistory] = React.useState([]);
	const [vendor_restaurant, setVendorRestaurant] = React.useState();
	const [isModalVisible, setIsModalVisible] = useState(false);
	const [isVendor, setIsVendor] = useState(false);
	const [isRestaurant, setIsRestaurant] = useState(false);
	const [restaurantOpenState, setRestaurantOpenState] = useState(false);
	const [isApproved, setIsApproved] = useState("");
	const [openTime, setOpenTime] = React.useState(moment('09:00', openTimeFormat));
	const [closeTime, setCloseTime] = React.useState(moment('17:00', openTimeFormat));


	// Functions
	const change_today_pagination = (selected_page, pageSize) => {
		setTodayPage(selected_page);
		setTodayPerPage(pageSize);
	}

	const fetchRestaurant = async () => {
		try {
			setSpinnerLoading(true);
			let vendorRestaurant = await _get_vendor_restaurant();
			const dataResult = vendorRestaurant?.data?.data || {};
			setVendorRestaurant(dataResult);
			setIsApproved(dataResult.status);

			// if time > open time and < close time THEN setRestaurantOpenState is true 
			// If time is strictly >= close time AND time is < open_time setRestaurantOpenState is false
			setOpenTime(dataResult.open_time);
			setCloseTime(dataResult.close_time);
			const currentTime = moment();

			if (currentTime.isBetween(openTime, closeTime)) {
				setRestaurantOpenState(true)
			} else if (currentTime.isBefore(openTime) || currentTime.isAfter(closeTime)) {
				setRestaurantOpenState(false)
			}

		} catch (err) {
			if (err.response) {
				if (err.response.data.message === "Unauthorized") {
					localStorage.removeItem('konnect_token')
					navigate('/signin')
				}
				openNotificationWithIcon('error', err.response.data.message)
			} else {
				openNotificationWithIcon('error', err.message)
			}
		} finally {
			setSpinnerLoading(false);
		}
	}

	// const handleCancel = () => {
	//   setIsModalVisible(false);
	// };
	const getUserProfile = async () => {
		try {
			let result = await _get_profile();
			const vendorStatus = result?.data?.data.is_so;
			setIsVendor(vendorStatus); // Update state
			setIsRestaurant(result?.data?.data.is_restaurant);

			if (!vendorStatus) {
				navigate('/profile/loyalty');
			}  // hidden to non-vendors
		}
		catch {
		}
	};

	const handleSwitch = (checked) => {
		setRestaurantOpenState(checked);

	}



	useEffect(() => {
		getUserProfile();
		fetchRestaurant();
	}, [])


	return (
		<>
			<div className="userprofile">
				<Spin spinning={spinnerLoading} indicator={newAntIcon}>
					<div className="profile-content">
						<Navbar />
						<div className="gray_bg_profile">
							<div className="profile_to_left">
								<div className="">
									<div className="profile_nav">
										<RestaurantSideNav />
									</div>
								</div>
							</div>
							<div className="profile_to_right">
								<div className="contain">
									<div className="main_container">
										<MobileDashTabs />
										{
											// IF VENDOR BUT NOT RESTAURANT
											(isVendor && !isRestaurant) ? (
												<>
													<BecomeRestaurant />
												</>
											) :
												// IF RESTAURANT BUT NOT APPROVED
												(isApproved === "pending") ? (
													<>
														<div className="flex flex-col pt-4 justify-center items-center ">
															<img src={waiting_approval} className='md:w-1/3 md:h-1/3  w-full h-full   ' alt="" />
															<p>Your restaurant is waiting for approval...</p>
														</div>
													</>
												) :
													//  IF RESTAURANT APPROVAL FAILED
													(isApproved === "rejected") ? (
														<>
															<div className="flex flex-col justify-center items-center">
																<video
																	src={failed_approval}
																	autoPlay
																	loop
																	muted
																	playsInline
																	className="w-1/3 h-1/3 my-5 rounded-lg shadow-md"
																/>
																<p>Unfortunately your restaurant approval was declined</p>
															</div>
														</>
													) :
														(
															<>
																<div className="main_container_title_div">
																	<h5>Dashboard</h5>
																	<Divider style={{margin: '0px 0 5px'}} />
																</div>

																{/* Restaurant Dashboard Slider  */}
																<InfoSlider />

																{/* Restaurant Toggle Time */}
																<div className="flex align-center justify-end w-full space-x-5 ">
																	<p className={` ${!restaurantOpenState ? 'text-[red]' : 'text-black'}  text-[14px]    `}>
																		{restaurantOpenState ? 'Restaurant is Opened' : "Restaurant is Closed"}
																	</p>
																	<Switch className={` ${restaurantOpenState ? 'bg-[#258635]' : 'bg-[grey]'}`} onChange={handleSwitch} />
																</div>
																{/* Restaurant table */}
																<Tabs type='card'>
																	<TabPane tab="Today's history" key="1">
																		{!today_history ?
																			<div>
																				<Spin indicator={newAntIcon} />
																			</div>
																			:
																			<>
																				<div>
																					<div className="desktop show-all">
																						<Table scroll={{x: true}} pagination={false} bordered columns={TodayHistoryColumn} dataSource={today_history_data} />
																						<div style={{marginTop: "2%"}}></div>
																						<Pagination showSizeChanger current={today_page} total={today_total} showTotal={(total, range) => `${range[0]} - ${range[1]} of ${total} transaction`} defaultPageSize={today_per_page} onChange={change_today_pagination} />
																					</div>
																					<div className="mobile topup-data">
																						<Collapse defaultActiveKey={[0]}>
																							{today_history.map((trans, index) => (
																								<Collapse.Panel showArrow={false} key={index} header={
																									<p style={{display: 'inline'}}>
																										<span className="small_currency">NGN</span>
																										<NumberFormat thousandSeparator={true} prefix={''} className="numeric" displayType={'text'} value={(+trans.total_amount).toFixed(2)} />
																										{trans.payment_type} on {DateTime.fromISO(trans.timestamp.split('T')[0]).toLocaleString(DateTime.DATE_HUGE)}
																									</p>
																								}>
																									<div>
																										<ul className="mobile-list-flex">
																											<li><span>Transaction Ref:</span><span>{trans.referense_no}</span></li>
																											<li><span>Service:</span><span>{trans.service}</span></li>
																											<li><span>Payment Mode:</span><span>{trans.mode}</span></li>
																											<li><span>Payment Type:</span><span>{trans.payment_type === "credit" ? <Tag color="green">{trans.payment_type}</Tag> : <Tag color="red">{trans.payment_type}</Tag>}</span></li>
																											<li><span>Status:</span><span><div style={{textTransform: "capitalize"}}>{trans.status === "success" ? <Tag color="#87d068">{trans.status}</Tag> : trans.status === "cancel" ? <Tag color="#cf1322">{trans.status}</Tag> : <Tag color="#ffa500">{trans.status}</Tag>}</div></span></li>
																											{/* <li><span>Action:</span><span><Button onClick={() => {setActiveHistorySelection(trans); setActiveHistoryDisplayModal(true);}}>View</Button></span></li> */}
																										</ul>
																									</div>
																								</Collapse.Panel>
																							))}
																						</Collapse>
																						<div style={{marginTop: "1%"}}>
																							<Pagination showSizeChanger current={today_page} total={today_total} showTotal={(total, range) => `${range[0]} - ${range[1]} of ${total} transaction`} defaultPageSize={today_per_page} onChange={change_today_pagination} />
																						</div>
																					</div>
																				</div>
																			</>
																		}
																	</TabPane>
																	<TabPane tab="Previous Orders" key="2">
																		{!today_history ?
																			<div>
																				<Spin indicator={newAntIcon} />
																			</div>
																			:
																			<>
																				<div>
																					<div className="desktop show-all">
																						<Table scroll={{x: true}} pagination={false} bordered columns={TodayHistoryColumn} dataSource={today_history_data} />
																						<div style={{marginTop: "1%"}}></div>
																						<Pagination showSizeChanger current={today_page} total={today_total} showTotal={(total, range) => `${range[0]} - ${range[1]} of ${total} transaction`} defaultPageSize={today_per_page} onChange={change_today_pagination} />
																					</div>
																					<div className="mobile topup-data">
																						<Collapse defaultActiveKey={[0]}>
																							{today_history.map((trans, index) => (
																								<Collapse.Panel showArrow={false} key={index} header={
																									<p style={{display: 'inline'}}>
																										<span className="small_currency">NGN</span>
																										<NumberFormat thousandSeparator={true} prefix={''} className="numeric" displayType={'text'} value={(+trans.total_amount).toFixed(2)} />
																										{trans.payment_type} on {DateTime.fromISO(trans.timestamp.split('T')[0]).toLocaleString(DateTime.DATE_HUGE)}
																									</p>
																								}>
																									<div>
																										<ul className="mobile-list-flex">
																											<li><span>Transaction Ref:</span><span>{trans.referense_no}</span></li>
																											<li><span>Service:</span><span>{trans.service}</span></li>
																											<li><span>Payment Mode:</span><span>{trans.mode}</span></li>
																											<li><span>Payment Type:</span><span>{trans.payment_type === "credit" ? <Tag color="green">{trans.payment_type}</Tag> : <Tag color="red">{trans.payment_type}</Tag>}</span></li>
																											<li><span>Status:</span><span><div style={{textTransform: "capitalize"}}>{trans.status === "success" ? <Tag color="#87d068">{trans.status}</Tag> : trans.status === "cancel" ? <Tag color="#cf1322">{trans.status}</Tag> : <Tag color="#ffa500">{trans.status}</Tag>}</div></span></li>
																											{/* <li><span>Action:</span><span><Button onClick={() => {setActiveHistorySelection(trans); setActiveHistoryDisplayModal(true);}}>View</Button></span></li> */}
																										</ul>
																									</div>
																								</Collapse.Panel>
																							))}
																						</Collapse>
																						<div style={{marginTop: "1%"}}>
																							<Pagination showSizeChanger current={today_page} total={today_total} showTotal={(total, range) => `${range[0]} - ${range[1]} of ${total} transaction`} defaultPageSize={today_per_page} onChange={change_today_pagination} />
																						</div>
																					</div>
																				</div>
																			</>
																		}
																	</TabPane>
																</Tabs>

															</>


														)



										}
									</div>
								</div>
							</div>
						</div>
					</div>
				</Spin >
			</div>
		</>
	)
}

export default RestaurantDashboard
