import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import '../assets/css/profile.css';

import { CopyOutlined, LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Checkbox, Divider, Form, Input, Modal, Select, Spin, Tabs, Upload, message, notification } from "antd";
import TabPane from "antd/lib/tabs/TabPane";
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { _change_password, _generate_otp, _get_profile, _change_login_pin, _request_update_email, _reset_email_passcode, _update_2fa_verification, _update_profile, _update_trans_pin, _update_user_type, _verify_otp } from "../common/axios_services";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import ProfileNav from '../components/dashboard/side_nav';
import { fetchData, profileData } from "../slices/profileSlice";
import PhoneInput from "react-phone-input-2";
import CopyToClipboard from "react-copy-to-clipboard";



const Dashboard = () => {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const [loadPasswordError, setLoadPasswordError] = useState(false);
    const [updatingPass, setUpdatingPass] = useState(false);
    const [supportManagerModal, setSupportManagerModal] = useState(false);
    const [VerActivated, setVerActivated] = useState(false);
    const [updatingProfile, setUpdatingProfile] = useState(false);
    const [profileErr, setProfileErr] = useState(false);
    const [updatePinVisible, setUpdatePinVisible] = useState(false);
    const [updateLoginPinVisible, setUpdateLoginPinVisible] = useState(false);
    const [updateCount, setUpdateCount] = useState(0);
    const [loadingOTPData, setLoadingOTPData] = useState(false);
    const [otp, setOtp] = useState(false);
    const [verifiedOtp, setVerifiedOtp] = useState(false);
    const [updatingPin, setUpdatingPin] = useState(false);
    const [updatingLoginPin, setUpdatingLoginPin] = useState(false);
    const [transactionPinUpdateDisplay, setTransactionPinUpdateDisplay] = useState(0);
    const [loginPinUpdateDisplay, setLoginPinUpdateDisplay] = useState(0);
    const [loadingSpinner, setLoadingSpinner] = useState(false);
    const [imageUrl, setImageUrl] = useState(null);
    const [imageToSend, setImageToSend] = useState(null);
    const [loading, setLoading] = useState(false);
    const [updateRa, setUpdateRa] = useState(false);
    const [becomingRa, setBecomingRa] = useState(false);
    const [reGen, setReGen] = useState(false);
    const [userOTP, setUserOTP] = useState('');
    const [activeTabKey, setActiveTabKey] = useState('1');
    const [updatingEmail, setUpdatingEmail] = useState(false);
    const [updateEmailCodeModal, setUpdateEmailCodeModal] = useState(false);
    const [updateEmailInfo, setUpdateEmailInfo] = useState(false);
    const [revealPinMessage, setRevealPinMessage] = useState('');
    const { state } = location;


    const profileInfo = useSelector(state => state.userProfile.dataFetched);

    const [countryCode, setCountryCode] = useState(profileInfo?.phone_code ? profileInfo?.phone_code : profileInfo?.user?.phone_code);
    const [phoneNumber, setPhoneNumber] = useState(profileInfo?.phone_number ? profileInfo?.phone_number : profileInfo?.user?.phone_number);
    const fullPhoneNumber = `${countryCode}${phoneNumber}`;


    const { handleSubmit, control } = useForm({
        defaultValues: {
            emailAddress: profileInfo?.email ? profileInfo?.email : profileInfo?.user?.email,
            accountId: profileInfo?.account_id ? profileInfo?.account_id : profileInfo?.user?.account_id,
            dob: profileInfo?.dob ? profileInfo?.dob : profileInfo?.user?.dob,
            gender: profileInfo?.gender ? profileInfo?.gender : profileInfo?.user?.gender,
            userName: profileInfo?.userName ? profileInfo?.userName : profileInfo?.user?.username,
            password: '',
            firstName: profileInfo?.first_name ? profileInfo?.first_name : profileInfo?.user?.first_name,
            lastName: profileInfo?.last_name ? profileInfo?.last_name : profileInfo?.user?.last_name,
            phoneNumber: fullPhoneNumber,
            ageGroup: profileInfo?.age ? profileInfo?.age : profileInfo?.user?.age
        },
    });

    const openNotificationWithIcon = (type, title, message) => {
        notification[type]({
            placement: 'bottomRight',
            message: title ? title : '',
            description: message ? message : "",
        });
    };

    const antIcon = <LoadingOutlined style={{ fontSize: 24, color: '#fff' }} spin />;
    const newAntIcon = <LoadingOutlined style={{ fontSize: 40 }} spin />;
    const { handleSubmit: editPasswordHandleSubmit, control: editPasswordControl } = useForm();
    const { handleSubmit: editEmailHandleSubmit, control: editEmailControl } = useForm();


    const navigate = useNavigate()
    const dispatch = useDispatch()
    const fetchUser = async () => {
        try {
            const user_profile = await _get_profile();
            dispatch(fetchData(true))
            dispatch(profileData(user_profile?.data?.data))
            setVerActivated(user_profile?.data?.data?.login_2fa)
            setImageUrl(user_profile?.data?.data?.image)
            if (user_profile?.data?.data?.email_otp) {
                setOtp(user_profile?.data?.data?.email_otp)
            }
        } catch (err) {
            if (err.response) {
                if (err.response.data.message === "Unauthorized") {
                    localStorage.removeItem('konnect_token')
                    navigate(`/signin?redir=loyalty`)
                }
                dispatch(profileData(false))
                dispatch(fetchData(false))
            } else {
            }
        }
    }
    useEffect(() => {
        fetchUser();
        if (searchParams.get('pin')) {
            setActiveTabKey('4');
        }

        const keyPin = searchParams.get('keypin');
        if (keyPin) {
            setActiveTabKey(keyPin);
        }

    }, [updateCount])

    const updateEmail = async e => {
        setUpdatingEmail(true)
        setProfileErr(false)
        try {
            let { confirmNewEmail, newEmail, oldEmail } = e;
            const data = {
                old_email: oldEmail,
                new_email: confirmNewEmail,
                confirm_email: newEmail,
            }
            setUpdateEmailInfo(data)
            const updateEmail = await _request_update_email(data, profileInfo.id)
            openNotificationWithIcon('success', updateEmail?.data?.title, updateEmail?.data?.message)
            setUpdatingEmail(false)
            setUpdateEmailCodeModal(true)
        } catch (err) {
            if (err.response) {
                if (err.response.data.message === "Unauthorized") {
                    localStorage.removeItem("konnect_token")
                    navigate('/')
                    setProfileErr(false)
                } else {
                    setProfileErr(err.response.data.message)
                }
            } else {
                setProfileErr(err.message)
                openNotificationWithIcon('error', "Something went wrong", err.message)
            }
            setUpdatingEmail(false)

        }
    }
    const updateUserInfo = async e => {
        setUpdatingProfile(true)
        try {
            let { emailAddress, firstName, lastName, dob, gender, ageGroup, userType, accountId } = e;
            const data = {
                first_name: firstName,
                last_name: lastName,
                email: emailAddress,
                phone_number: phoneNumber,
                phone_code: countryCode,
                userType: userType,
                gender: gender,
                dob: dob,
                image: imageUrl,
                pin: profileInfo.pin,
                age: ageGroup,
                account_id: accountId,    // Include Account ID
            }
            const updateProfile = await _update_profile(data)
            setUpdateCount(updateCount + 1)
            setUpdatingProfile(false)
            setProfileErr(false)
            openNotificationWithIcon('success', updateProfile?.data?.title, updateProfile?.data?.message)
            // console.log("Updating user info with data:", data);
        } catch (err) {
            setUpdatingProfile(false)
            if (err.response) {
                if (err.response.data.message === "Unauthorized") {
                    localStorage.removeItem("konnect_token")
                    navigate('/')
                    setProfileErr(false)
                } else {
                    setProfileErr(err.response.data.message)
                }
            } else {
                openNotificationWithIcon('error', "Something went wrong", err.message)
            }
        }
    }

    const Update2fa = async ({ value }) => {
        try {
            const updateVerification = await _update_2fa_verification({ value })
            openNotificationWithIcon('success', updateVerification?.data?.title, updateVerification?.data?.message);
            setUpdateCount(updateCount + 1)
        } catch (err) {
            if (err.response) {
                if (err.response.data.message === "Unauthorized") {
                    navigate('/signin')
                }
                openNotificationWithIcon('error', err.response.data.title, err.response.data.message);
            } else {
                openNotificationWithIcon('error', "Something went wrong", err.message);
            }
        }
    }

    const changePassword = async e => {
        let { oldpassword, newpassword, confirmnewpassword } = e;
        setUpdatingPass(true)
        try {
            const pass_changed = await _change_password({ current_password: oldpassword, new_password: newpassword, confirm_new_password: confirmnewpassword })
            setUpdatingPass(false)
            openNotificationWithIcon('success', pass_changed?.data?.title, pass_changed?.data?.message);
            window.location.reload()
        } catch (err) {
            setUpdatingPass(false)
            if (err.response) {
                if (err.response.data.message === "Unauthorized") {
                    navigate('/signin')
                    openNotificationWithIcon('error', err?.response?.data?.title, err?.response?.data?.message);
                } else {
                    openNotificationWithIcon('error', err?.response?.data?.title, err?.response?.data?.message);
                }
            } else {
                openNotificationWithIcon('error', "Something went wrong", err?.message);
            }
        }
    }

    const updatePin = async (value) => {
        setRevealPinMessage('');
        if (value.new_pin !== value.confirm_pin) {
            setRevealPinMessage('New pin and confirm pin are not equal')
        } else {
            setUpdatingPin(true)
            try {
                const changeTransPin = await _update_trans_pin({ new_pin: value.new_pin, confirm_new_pin: value.confirm_pin, otp: userOTP })
                setLoadingSpinner(false);
                openNotificationWithIcon(changeTransPin?.data?.status, changeTransPin?.data?.title, changeTransPin?.data?.message);
                setUpdatingPin(false);
                setLoadingSpinner(false);
                setTransactionPinUpdateDisplay(0);
                setUpdatePinVisible(false);
                setVerifiedOtp(false)
            } catch (err) {
                if (err.response) {
                    if (err.response.data.message === "Unauthorized") {
                        openNotificationWithIcon('error', err?.response?.data?.title, err?.response?.data?.message);
                        navigate('/signin');
                    }
                    openNotificationWithIcon('error', err?.response?.data?.title, err?.response?.data?.message);
                } else {
                    openNotificationWithIcon('error', "Something went wrong", err?.message);
                }
                setUpdatingPin(false);
                setLoadingSpinner(false);
            }
        }
    }

    const updateLoginPin = async (value) => {
        setRevealPinMessage('');
        if (value.new_pin !== value.confirm_pin) {
            setRevealPinMessage('New login pin and confirm login pin are not equal')
        } else {
            setUpdatingLoginPin(true)
            try {
                const changeLoginPin = await _change_login_pin({ new_pin: value.new_pin, confirm_new_pin: value.confirm_pin, otp: userOTP })
                setLoadingSpinner(false);
                openNotificationWithIcon(changeLoginPin?.data?.status, changeLoginPin?.data?.title, changeLoginPin?.data?.message);
                setUpdatingLoginPin(false);
                setLoadingSpinner(false);
                setLoginPinUpdateDisplay(0);
                setUpdateLoginPinVisible(false)
                setVerifiedOtp(false)
            } catch (err) {
                if (err.response) {
                    if (err.response.data.message === "Unauthorized") {
                        openNotificationWithIcon('error', err?.response?.data?.title, err?.response?.data?.message);
                        navigate('/signin');
                    }
                    openNotificationWithIcon('error', err?.response?.data?.title, err?.response?.data?.message);
                } else {
                    openNotificationWithIcon('error', "Something went wrong", err?.message);
                }
                setUpdatingLoginPin(false);
                setLoadingSpinner(false);
            }
        }
    }

    const genOtp = async () => {
        setLoadingOTPData(true)
        try {
            const generate_otp = await _generate_otp();
            setLoadingOTPData(false);
            setUpdateCount(updateCount + 1);
            setOtp(true);
            setReGen(false)
            openNotificationWithIcon('success', generate_otp?.data?.title, generate_otp?.data?.message);
        } catch (err) {
            setLoadingOTPData(false)
            if (err.response) {
                if (err.response.data.message === "Unauthorized") {
                    openNotificationWithIcon(err?.response?.data?.status, err?.response?.data?.title, err?.response?.data?.message);
                    navigate('/signin')
                }
                openNotificationWithIcon(err?.response?.data?.status, err?.response?.data?.title, err?.response?.data?.message);
            } else {
                openNotificationWithIcon('error', "Something went wrong", err.message);
            }
        }
    }

    const VerifyOtp = async (values) => {
        setLoadingOTPData(true)
        try {
            const verifyOtp = await _verify_otp({ otp: values.otp });
            setLoadingOTPData(false);
            setVerifiedOtp(true);
            setUserOTP(values.otp);
            setTransactionPinUpdateDisplay(1);
            setLoginPinUpdateDisplay(1);
            openNotificationWithIcon('success', verifyOtp?.data?.title, verifyOtp?.data?.message);
        } catch (err) {
            setLoadingOTPData(false)
            setVerifiedOtp(false);
            if (err.response) {
                if (err.response.data.message === "Unauthorized") {
                    openNotificationWithIcon(err?.response?.data?.status, err?.response?.data?.title, err?.response?.data?.message);
                    navigate('/signin')
                } else {
                    if (err.response.data.message.includes("expired")) {
                        setOtp(false)
                        setReGen(true)
                    }
                    setReGen(false)
                    openNotificationWithIcon(err?.response?.data?.status, err?.response?.data?.title, err?.response?.data?.message);
                }
            } else {
                openNotificationWithIcon('error', "Something went wrong", err.message);
            }
        }
    }

    const getBase64 = (img, callback) => {
        const reader = new FileReader();
        reader.readAsDataURL(img);
        reader.addEventListener('load', () => callback(reader.result));
    }

    const handleChange = info => {
        getBase64(info.file.originFileObj, imageUrl =>
            setImageUrl(imageUrl),
            setLoading(false),
        );
        const reader = new FileReader();

        reader.readAsDataURL(info.file.originFileObj);
        reader.addEventListener('load', () => {
            setImageToSend(reader.result);
        });
    };

    const uploadButton = (
        <div>
            {loading ? <LoadingOutlined /> : <PlusOutlined />}
            <div style={{ marginTop: 8 }}>Upload</div>
        </div>
    );

    const becomeRa = async () => {
        setBecomingRa(true)
        try {
            const changeType = await _update_user_type({ status: !profileInfo.is_ra });
            setUpdateRa(false)
            setBecomingRa(false)
            setUpdateCount(updateCount + 1)
            openNotificationWithIcon('success', changeType?.data?.title, changeType?.data?.message);
        } catch (err) {
            setBecomingRa(false)
            if (err.response) {
                if (err.response.data.message === "Unauthorized") {
                    openNotificationWithIcon(err?.response?.data?.status, err?.response?.data?.title, err?.response?.data?.message);
                    navigate('/signin')
                } else {
                    openNotificationWithIcon(err?.response?.data?.status, err?.response?.data?.title, err?.response?.data?.message);
                }
            } else {
                openNotificationWithIcon('error', "Something went wrong", err.message);
            }
        }
    }

    const updateEmailPasscode = async (values) => {
        const data = {
            old_email: updateEmailInfo?.old_email,
            new_email: updateEmailInfo?.new_email,
            confirm_email: updateEmailInfo?.confirm_email,
            pass_code: values.pass_code
        }
        setUpdatingEmail(true)
        setRevealPinMessage(false)
        try {
            const changeEmail = await _reset_email_passcode(data, profileInfo.id)
            setUpdatingEmail(false)
            openNotificationWithIcon('success', changeEmail?.data?.title, changeEmail?.data?.message)
            localStorage.removeItem('konnect_token')
            navigate(`/signin`)
        } catch (err) {
            if (err.response) {
                if (err.response.data.message === "Unauthorized") {
                    openNotificationWithIcon('error', err?.response?.data?.title, err?.response?.data?.message);
                    navigate('/signin');
                }
                openNotificationWithIcon('error', err?.response?.data?.title, err?.response?.data?.message);
                setRevealPinMessage(err?.response?.data?.message)
            } else {
                setRevealPinMessage(err?.message)
                openNotificationWithIcon('error', "Something went wrong", err?.message);
            }
            setUpdatingEmail(false)
        }
    }

    const beforeUpload = (file) => {
        const isImage = file.type.startsWith('image/');
        if (!isImage) {
            message.error('You can only upload image files!');
            return Upload.LIST_IGNORE;
        }

        const isLt5MB = file.size / 1024 / 1024 < 5;
        if (!isLt5MB) {
            message.error('Image must be smaller than 5MB!');
            return Upload.LIST_IGNORE;
        }

        return true;
    };

    const handlePhoneChange = (phone, country) => {
        const dialCode = `${country.dialCode}`;
        setCountryCode(dialCode);

        // Remove the country code from the start of the phone number
        const phoneWithoutCode = phone.startsWith(dialCode)
            ? phone.slice(dialCode.length).trim()
            : phone;
        setPhoneNumber(phoneWithoutCode);
    };

    return (
        <div className="userprofile">
            <Spin spinning={loadingSpinner} indicator={newAntIcon}>
                <div className="profile-content">
                    <Navbar />
                    <div className="gray_bg_profile">
                        <div className="profile_to_left">
                            <div className="">
                                <div className="profile_nav">
                                    <ProfileNav />
                                </div>
                            </div>
                        </div>
                        <div className="profile_to_right">
                            <div className="">
                                <div className="contain">
                                    <div className="main_container">
                                        <div className="main_container_title_div">
                                            <div style={{ display: "flex", justifyContent: "space-between", alignItems: 'center' }}>
                                                <h5 style={{ marginBottom: 0 }}>Account Overview</h5>
                                                {profileInfo?.is_so &&
                                                    <button style={{ padding: '10px 15px', fontSize: 14 }} className="pay_bg_green" onClick={() => setSupportManagerModal(true)}>Your Support Manager</button>
                                                }
                                            </div>
                                            <Divider style={{ margin: '0px 0 5px' }} />
                                        </div>
                                        <div className="main_container_inner_div">
                                            <div className="profile_container">
                                                <div className="slider_div_to_top">
                                                    <div>
                                                        <div className="grid-flex-bias">
                                                            <div className="tab_block">
                                                                <Tabs activeKey={activeTabKey} onChange={key => setActiveTabKey(key)}> 
                                                                    <TabPane tab="Basic settings" key="1">
                                                                        <div className="short_width">
                                                                            {profileErr ? <p className="error-message">{profileErr}</p> : ''}
                                                                            <form autoComplete="off" onSubmit={handleSubmit(updateUserInfo)}>
                                                                                <div className="form-group space">
                                                                                    <Upload name="avatar" listType="picture-card" className="avatar-uploader" showUploadList={false} multiple={false} onChange={handleChange} beforeUpload={beforeUpload} >
                                                                                        {imageUrl ? <img src={imageUrl} alt="avatar" style={{ width: "120px", height: "120px" }} className="adminCourseUpload" /> : uploadButton}
                                                                                    </Upload>
                                                                                </div>
                                                                                <div className="form-flex-2">
                                                                                    <div className="form-group space">
                                                                                        <label htmlFor="firstName">First name</label>
                                                                                        <Controller name="firstName" control={control} render={({ field }) => (
                                                                                            <Input {...field} id="firstName" type="text" style={{ height: '5rem' }} />)} />
                                                                                    </div>
                                                                                    <div className="form-group">
                                                                                        <label htmlFor="lastName">Last name</label>
                                                                                        <Controller name="lastName" control={control} render={({ field }) => (
                                                                                            <Input {...field} id="lastName" type="text" style={{ height: '5rem' }} />)} />
                                                                                    </div>
                                                                                </div>
                                                                                
                                                                                <div className="form-flex-2">
                                                                                    {/* <div className="form-group">
                                                                                        <label htmlFor="userType">User Type</label>
                                                                                        <Controller control={control} name="userType" render={({ field }) => (
                                                                                            <Input {...field} id="userType" disabled value={profileInfo.is_ra ? "RA" : "Basic"} style={{ height: '5rem' }} />
                                                                                        )} />
                                                                                    </div> */}
                                                                                    <div className="form-group">
                                                                                        <label htmlFor="accountId">Account ID</label>
                                                                                        <Controller 
                                                                                            control={control} 
                                                                                            name="accountId" 
                                                                                            render={({ field }) => (
                                                                                                <Input 
                                                                                                    {...field} 
                                                                                                    id="accountId" 
                                                                                                    disabled={true} 
                                                                                                    type="text" 
                                                                                                    style={{ height: '5rem' }}
                                                                                                    suffix={
                                                                                                        <CopyToClipboard text={field.value} onCopy={() => message.success('Account ID copied to clipboard!')}>
                                                                                                            <CopyOutlined style={{ cursor: 'pointer', color: '#000' }} />
                                                                                                        </CopyToClipboard>
                                                                                                    }
                                                                                                />
                                                                                                    
                                                                                            )}
                                                                                        />
                                                                                    </div>
                                                                                    <div className="form-group">
                                                                                        <label htmlFor="phoneNumber">Phone Number</label>
                                                                                        <Controller control={control} name="phoneNumber" render={({ field }) => (
                                                                                            <PhoneInput
                                                                                                {...field}
                                                                                                id="phoneNumber"
                                                                                                onChange={handlePhoneChange}
                                                                                                containerStyle={{ width: '100%' }}
                                                                                                inputStyle={{ height: '5rem', width: '100%' }}
                                                                                            />
                                                                                        )} />
                                                                                    </div>
                                                                                </div>
                                                                                <div className="form-flex-2">
                                                                                    <div className="form-group">
                                                                                        <label htmlFor="dob">Gender</label>
                                                                                        <Controller control={control} name="gender" render={({ field }) => (
                                                                                            <Select placeholder="Select Gender" {...field} id="gender" style={{ height: '5rem' }}>
                                                                                                <Select.Option value="male">Male</Select.Option>
                                                                                                <Select.Option value="female">Female</Select.Option>
                                                                                            </Select>
                                                                                        )} />
                                                                                    </div>
                                                                                    <div className="form-group">
                                                                                        <label htmlFor="dob">Date Of Birth</label>
                                                                                        <Controller control={control} name="dob" render={({ field }) => (<Input {...field} id="dob" type="date" style={{ height: '5rem' }} />)} />
                                                                                    </div>
                                                                                </div>
                                                                                <div className="form-flex-2">
                                                                                    <div className="form-group">
                                                                                        <label htmlFor="emailAddress">Email address</label>
                                                                                        <Controller control={control} name="emailAddress" render={({ field }) => (
                                                                                            <Input {...field} id="emailAddress" disabled={true} type="email" style={{ height: '5rem' }} />)} />
                                                                                    </div>
                                                                                    <div className="form-group">
                                                                                        <label htmlFor="ageGroup">Age Group</label>
                                                                                        <Controller control={control} name="ageGroup" render={({ field }) => (
                                                                                            <Select placeholder="Age Group" {...field} id="ageGroup" style={{ height: '5rem' }}>
                                                                                                <Select.Option value="16–25">16 – 25</Select.Option>
                                                                                                <Select.Option value="25-26"> 26 – 35</Select.Option>
                                                                                                <Select.Option value="36–45">36 – 45</Select.Option>
                                                                                                <Select.Option value="46–55">46 – 55</Select.Option>
                                                                                                <Select.Option value="56–above">56 – above</Select.Option>
                                                                                            </Select>
                                                                                        )} />
                                                                                    </div>
                                                                                </div>
                                                                                <div style={{ marginTop: '7%' }}></div>
                                                                                {updatingProfile ?
                                                                                    <button id="submit-form" className="bg_green" style={{ display: 'block', width: "100%", height: '4.5rem', borderRadius: '2px' }} disabled> Updating profile.Please wait <Spin style={{ marginLeft: '10px' }} indicator={antIcon} /></button>
                                                                                    :
                                                                                    <button type="submit" className="bg_green" style={{ display: 'block', width: "100%", height: '4.5rem', borderRadius: '2px' }} id="submit-form">Update profile</button>
                                                                                }
                                                                                <div style={{ marginTop: '4%' }}></div>
                                                                            </form>
                                                                        </div>
                                                                    </TabPane>
                                                                    <TabPane tab="Password" key="3">
                                                                        <div className="short_width">
                                                                            {loadPasswordError ?
                                                                                <p className="error-message">{loadPasswordError}</p> : ''
                                                                            }
                                                                            <form autoComplete="off" onSubmit={editPasswordHandleSubmit(changePassword)}>
                                                                                <div className="form-group">
                                                                                    <label htmlFor="oldpassword">Old Password</label>
                                                                                    <Controller name="oldpassword" control={editPasswordControl} render={({ field }) => (<Input.Password {...field} id="oldpassword" type="password" style={{ height: '5rem' }} />)} />
                                                                                </div>
                                                                                <div className="form-group">
                                                                                    <label htmlFor="newpassword">New Password</label>
                                                                                    <Controller control={editPasswordControl} name="newpassword" render={({ field }) => (<Input.Password {...field} id="password" type="password" style={{ height: '5rem' }} />)} />
                                                                                </div>
                                                                                <div className="form-group">
                                                                                    <label htmlFor="confirmnewpassword">Confirm Password</label>
                                                                                    <Controller control={editPasswordControl} name="confirmnewpassword" render={({ field }) => (<Input.Password {...field} id="password" type="password" style={{ height: '5rem' }} />)} />
                                                                                </div>
                                                                                <div style={{ marginTop: '7%' }}></div>
                                                                                {!updatingPass ?
                                                                                    <button type="submit" className="bg_green" style={{ display: 'block', width: "100%", height: '4.5rem', borderRadius: '2px' }} id="submit-form">Update password</button>
                                                                                    :
                                                                                    <Button disabled loading style={{ display: 'block', width: "100%", height: '4.5rem', borderRadius: '2px' }} id="submit-form">Update password</Button>
                                                                                }
                                                                                <div style={{ marginTop: '4%' }}></div>
                                                                            </form>
                                                                        </div>
                                                                    </TabPane>
                                                                    <TabPane tab="Update Email" key="5">
                                                                        <div className="short_width">
                                                                            {profileErr ? <p className="error-message">{profileErr}</p> : ''}
                                                                            <form autoComplete="off" onSubmit={editEmailHandleSubmit(updateEmail)}>
                                                                                <div className="form-group">
                                                                                    <label htmlFor="oldEmail">Old Email</label>
                                                                                    <Controller name="oldEmail" control={editEmailControl} render={({ field }) => (<Input {...field} id="oldemail" type="email" style={{ height: '5rem' }} />)} />
                                                                                </div>
                                                                                <div className="form-group">
                                                                                    <label htmlFor="newEmail">New Email</label>
                                                                                    <Controller control={editEmailControl} name="newEmail" render={({ field }) => (<Input {...field} id="newEmail" type="email" style={{ height: '5rem' }} />)} />
                                                                                </div>
                                                                                <div className="form-group">
                                                                                    <label htmlFor="confirmNewEmail">Confirm Email</label>
                                                                                    <Controller control={editEmailControl} name="confirmNewEmail" render={({ field }) => (<Input {...field} id="newEmail" type="email" style={{ height: '5rem' }} />)} />
                                                                                </div>
                                                                                <div style={{ marginTop: '7%' }}></div>
                                                                                {!updatingEmail ?
                                                                                    <button type="submit" className="bg_green" style={{ display: 'block', width: "100%", height: '4.5rem', borderRadius: '2px' }} id="submit-form">Update Email</button>
                                                                                    :
                                                                                    <Button disabled loading style={{ display: 'block', width: "100%", height: '4.5rem', borderRadius: '2px' }} id="submit-form">Update Email</Button>
                                                                                }
                                                                                <div style={{ marginTop: '4%' }}></div>
                                                                            </form>
                                                                        </div>
                                                                    </TabPane>
                                                                    <TabPane tab="Security settings" key="4">
                                                                        <div className="short_width width-30">
                                                                            <div className="accountProtection">
                                                                                <form>
                                                                                    <div className="short_widt">
                                                                                        {/* <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }} className="form-group">
                                                                                                <label htmlFor="">2FA:</label>
                                                                                                {VerActivated ?
                                                                                                    <button className="pay_bg_green security" onClick={() => Update2fa({ value: false })}>Deactivate</button>
                                                                                                    :
                                                                                                    <button className="pay_bg_green security" onClick={() => Update2fa({ value: true })}>Activate</button>
                                                                                                }
                                                                                            </div> */}
                                                                                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }} className="form-group ">
                                                                                            <label htmlFor="">Transaction code:</label>
                                                                                            <button className="security pay_bg_green" type="button" onClick={() => { setUpdatePinVisible(true); genOtp() }}>Update</button>
                                                                                        </div>
                                                                                    </div>
                                                                                </form>
                                                                            </div>
                                                                        </div>
                                                                    </TabPane>
                                                                    <TabPane tab="Update Login PIN" key="6">
                                                                        <div className="short_width width-30">
                                                                            <div className="accountProtection  my-20 flex justify-center items-center">
                                                                                <form>
                                                                                    <div className="sh">
                                                                                        <div className="form-group ">
                                                                                            <button className="py-3 px-5 w-full font-gilroyBold text-[14px] rounded-xl bg-[green] text-white " type="button" onClick={() => { setUpdateLoginPinVisible(true); genOtp() }}>Generate Login OTP</button>
                                                                                        </div>
                                                                                    </div>
                                                                                </form>
                                                                            </div>
                                                                        </div>
                                                                    </TabPane>
                                                                </Tabs>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* check basic details */}
                    <Modal centered={true} className="airtimeModal" open={updateRa} maskClosable={false} footer={null} >
                        <div className="ambassador-button-showcase center-me">
                            <Spin spinning={becomingRa}>
                                {!profileInfo.is_ra ?
                                    <>
                                        <h3>Become a Retail Ambassador (RA)</h3>
                                        <p>RA are users that support their referees to be active on konnect. Only an RA can earn income when their referee shop & subscribe to wise9ja.</p>
                                        <Checkbox style={{ marginBottom: '3rem' }}>Read and Agree</Checkbox>
                                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                                            <button className="bg_green" style={{ background: "grey", color: "white", border: "white" }} onClick={() => setUpdateRa(false)}>Close</button>
                                            {becomingRa ?
                                                <button disabled className="bg_green"><Spin style={{ marginLeft: '10px' }} indicator={antIcon} /></button>
                                                :
                                                <button onClick={() => becomeRa()} className="bg_green">Become RA</button>
                                            }
                                        </div>
                                    </>
                                    : <>
                                        <h3>Become a Basic User</h3>
                                        <p>Are you sure you want to return to a basic user?</p>
                                        <Checkbox style={{ marginBottom: '3rem' }}>Read and Agree</Checkbox>
                                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                                            <button className="bg_green" style={{ background: "grey", color: "white", border: "white" }} onClick={() => setUpdateRa(false)}>Close</button>
                                            {becomingRa ?
                                                <button disabled className="bg_green"><Spin style={{ marginLeft: '10px' }} indicator={antIcon} /></button>
                                                :
                                                <button onClick={() => becomeRa()} className="bg_green">Become Basic User</button>
                                            }
                                        </div>
                                    </>}
                            </Spin>
                        </div>
                    </Modal>
                    <Modal centered={true} className="airtimeModal" visible={updateEmailCodeModal} maskClosable={false} footer={null} >
                        <div className="">
                            <h4 className="modal-title">Email Passcode</h4>
                            <div style={{ marginTop: '5%' }}></div>
                            {revealPinMessage ? <p className="error-message">{revealPinMessage}</p> : ''}
                            <Form layout="vertical" style={{ maxWidth: 600 }} onFinish={updateEmailPasscode} autoComplete="off">
                                <Form.Item label="Passcode" name="pass_code">
                                    <Input />
                                </Form.Item>
                                <div className="d-flex" style={{ gap: "5px" }}>
                                    {updatingEmail ?
                                        <Button type="primary" disabled loading>Updating Email</Button> :
                                        <Button type="primary" htmlType="submit">Update Email</Button>
                                    }
                                    <Button onClick={() => { setUpdateEmailCodeModal(false) }}>Cancel</Button>
                                </div>
                            </Form>
                        </div>
                    </Modal>
                    <Modal centered={true} className="airtimeModal" visible={updatePinVisible} maskClosable={false} footer={null} >
                        <div>
                            {verifiedOtp ?
                                <div className="">
                                    <h4 className="modal-title">Update Transaction Pin</h4>
                                    <div style={{ marginTop: '5%' }}></div>
                                    {revealPinMessage ? <p className="error-message">{revealPinMessage}</p> : ''}
                                    <Form layout="vertical" style={{ maxWidth: 600 }} onFinish={updatePin} autoComplete="off">
                                        <Form.Item label="New Pin" name="new_pin">
                                            <Input.Password />
                                        </Form.Item>
                                        <Form.Item label="Confirm Pin" name="confirm_pin">
                                            <Input.Password />
                                        </Form.Item>
                                        <div className="d-flex" style={{ gap: "5px" }}>
                                            {updatingPin ?
                                                <Button type="primary" disabled loading>Updating Pin</Button> :
                                                <Button type="primary" htmlType="submit">Update Pin</Button>
                                            }
                                            <Button onClick={() => { setUpdatePinVisible(false); setVerifiedOtp(false) }}>Cancel</Button>
                                        </div>
                                    </Form>
                                </div>
                                :
                                <div className="">
                                    <h4 className="modal-title">Generate Otp</h4>
                                    <p>An Otp would been sent to your email.</p>
                                    <div style={{ marginTop: '5%' }}></div>
                                    {revealPinMessage ? <p className="error-message">{revealPinMessage}</p> : ''}
                                    <Form layout="vertical" style={{ maxWidth: 600 }} onFinish={VerifyOtp} autoComplete="off">
                                        <Form.Item label="Otp" name="otp">
                                            {otp ?
                                                <Input placeholder="Enter Otp" /> :
                                                <Input placeholder="Enter Otp" disabled />
                                            }
                                        </Form.Item>
                                        <div className="d-flex" style={{ gap: "5px" }}>
                                            {otp ?
                                                <>
                                                    {loadingOTPData ? (
                                                        <Button type="primary" loading disabled>
                                                            Verifying Otp
                                                        </Button>
                                                    ) : reGen ? (
                                                        <Button type="primary" onClick={genOtp}>
                                                            Generate Pin
                                                        </Button>
                                                    ) : (
                                                        <Button type="primary" htmlType="submit">
                                                            Verify Otp
                                                        </Button>
                                                    )}

                                                </> :
                                                <>
                                                    {loadingOTPData ?
                                                        <Button type="primary" loading disabled>Generating Pin</Button> :
                                                        <Button type="primary" onClick={genOtp}>Generate Pin</Button>
                                                    }
                                                </>
                                            }
                                            <Button onClick={() => { setUpdatePinVisible(false); setVerifiedOtp(false) }}>Cancel</Button>
                                        </div>
                                    </Form>
                                </div>
                            }
                        </div>
                    </Modal>
                    {/* LOGIN PIN UPDATE MODAL */}
                    <Modal centered={true} className="airtimeModal" open={updateLoginPinVisible} maskClosable={false} footer={null} >
                        <div>
                            {verifiedOtp ?
                                <div className="">
                                    <h4 className="modal-title">Update Login Pin</h4>
                                    <div style={{ marginTop: '5%' }}></div>
                                    {revealPinMessage ? <p className="error-message">{revealPinMessage}</p> : ''}
                                    <Form layout="vertical" style={{ maxWidth: 600 }} onFinish={updateLoginPin} autoComplete="off">
                                        <Form.Item label="New Pin" name="new_pin">
                                            <Input.Password />
                                        </Form.Item>
                                        <Form.Item label="Confirm Pin" name="confirm_pin">
                                            <Input.Password />
                                        </Form.Item>
                                        <div className="d-flex" style={{ gap: "5px" }}>
                                            {updatingLoginPin ?
                                                <Button type="primary" disabled loading>Updating Pin</Button> :
                                                <Button type="primary" htmlType="submit">Update Pin</Button>
                                            }
                                            <Button
                                                onClick={() => { 
                                                setUpdateLoginPinVisible(false); 
                                                setVerifiedOtp(false); // Resetting the verifiedOtp state to false
                                            }}>Cancel</Button>
                                        </div>
                                    </Form>
                                </div>
                                :
                                <div className="">
                                    <h4 className="modal-title">Generate Otp</h4>
                                    <p>An Otp would been sent to your email.</p>
                                    <div style={{ marginTop: '5%' }}></div>
                                    {revealPinMessage ? <p className="error-message">{revealPinMessage}</p> : ''}
                                    <Form layout="vertical" style={{ maxWidth: 600 }} onFinish={VerifyOtp} autoComplete="off">
                                        <Form.Item label="Otp" name="otp">
                                            {otp ?
                                                <Input placeholder="Enter Otp" /> :
                                                <Input placeholder="Enter Otp" disabled />
                                            }
                                        </Form.Item>
                                        <div className="d-flex" style={{ gap: "5px" }}>
                                            {otp ?
                                                <>
                                                    {loadingOTPData ? (
                                                        <Button type="primary" loading disabled>
                                                            Verifying Otp
                                                        </Button>
                                                    ) : reGen ? (
                                                        <Button type="primary" onClick={genOtp}>
                                                            Generate Pin
                                                        </Button>
                                                    ) : (
                                                        <Button type="primary" htmlType="submit">
                                                            Verify Otp
                                                        </Button>
                                                    )}

                                                </> :
                                                <>
                                                    {loadingOTPData ?
                                                        <Button type="primary" loading disabled>Generating Pin</Button> :
                                                        <Button type="primary" onClick={genOtp}>Generate Pin</Button>
                                                    }
                                                </>
                                            }
                                            <Button
                                                onClick={() => { 
                                                setUpdateLoginPinVisible(false); 
                                                setVerifiedOtp(false); // Resetting the verifiedOtp state to false
                                            }}>Cancel</Button>
                                        </div>
                                    </Form> 
                                </div>
                            }
                        </div>
                    </Modal>
                    <Modal centered={true} className="airtimeModal" visible={supportManagerModal} footer={null} >
                        <h2>Your Support Manager</h2>
                        <p>Please check back to meet with your support manager. If you need urgent help, <a target="_blank" href="https://tawk.to/chat/61590f9625797d7a8901fd89/default">click here</a>  to chat with us</p>
                        <Button onClick={() => setSupportManagerModal(false)}>Close</Button>
                    </Modal>
                    <div className="profile mobile">
                        <Footer />
                    </div>
                </div>
            </Spin>
        </div>
    )
}

export default Dashboard