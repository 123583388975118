import React from 'react'
import Navbar from '../../components/Navbar'
import { Card, Col, Row } from 'antd'
import payment_sucessful from '../../assets/images/restaurant/payment_successful.png'
import { useNavigate } from 'react-router-dom'
import { CheckCircleFilled } from '@ant-design/icons'
import { UnderConstructionModal } from '../../components/UnderConstructionModal'

const CheckoutSuccessful = () => {
    const navigate = useNavigate()
  return (
    <div>
        <Navbar noShadow={true} adjustNavSize={true} />
        <div className="py-20">
            <Row gutter={{ lg:44 }} justify={'center'} >
                <Col xs={22} lg={16}> 
                    <div className="flex flex-col justify-center items-center " >
                        <h2 className='lg:text-6xl text-4xl'>Payment successful!</h2>
                        <h2 className='lg:text-6xl text-4xl'>Your food is on the way</h2>
                        <div className="py-5">
                            <img src={payment_sucessful} className='w-full h-full' alt="payment successfull" />
                        </div>
                    </div>

                </Col>
                <Col xs={22} lg={8}> 
                    <Card bordered={false} className="flex-col px-10 py-20 lg:mt-28 items-center bg-[#f5fff5] rounded-[32px] border-2 border-black">
                        <div className="divide-y-2">
                            <div className="rest_header flex justify-between">
                                <h2 className='text-[16px] text-[#959595]' >Give this code to your dispatch rider</h2>
                                <div className="flex gap-3">
                                    <p className='text-[16px] font-semibold'>NH16T</p>
                                </div>
                            </div>
                            <div className="order_item ">
                                <p className='text-[#959595] text-[15px] ' >Item</p>
                                <div className="flex mt-3 gap-5">
                                    <p className="text-3xl font-bold ">2x</p>
                                    <p className="text-2xl font-bold text-wrap">Fried Rice & Chicken - 1 portion and half chicken</p>
                                </div>
                            </div>
                            <div className="">
                                <div className="flex flex-col py-2">
                                    <p className='text-[15px] text-[#959595] '>Restaurant</p>
                                    <p className='text-2xl'>Havey’s Restaurant, Lekki Lagos</p>
                                </div>
                            </div>
                            <div className="">
                                <div className="flex flex-col py-2">
                                    <p className='text-[15px] text-[#959595] '>Delivery to</p>
                                    <p className='text-2xl'>John Doe</p>
                                </div>
                            </div>
                            <div className="">
                                <div className="flex flex-col py-2">
                                    <p className='text-[15px] text-[#959595] '>Contact</p>
                                    <p className='text-2xl'>John@gmail.com</p>
                                    <p>+234 700 000 0000</p>
                                </div>
                            </div>
                            <div className="">
                                <div className="flex flex-col py-2">
                                    <p className='text-[15px] text-[#959595] '>Address</p>
                                    <p className='text-2xl'>No 17, 1st Floor, Queens Tower, Lekki Phase 1, Lagos</p>
                                </div>
                            </div>
                            <div className="">
                                <div className="flex flex-col py-2">
                                    <p className='text-[15px] text-[#959595] '>Date</p>
                                    <p className='text-2xl'>23rd September, 2024 | 10am</p>
                                </div>
                            </div>
                            <div className="mt-5 flex flex-col mx-auto">
                                <div  className="bg-[#44843F] flex items-center justify-between w-full text-white rounded-3xl border-2 border-black px-6  py-3">
                                    <div className='flex flex-col' >
                                        <p>₦6,250.00</p>
                                        <p>Payment received</p>
                                    </div>
                                    <CheckCircleFilled style={{ fontSize: '44px' }} />
                                </div>
                                <div className='flex mt-5 mx-auto cursor-pointer' onClick={() => navigate('/restaurant/1/track_order') }>
                                    <p className=' underline text-[15px] text-[#44843F] ' >Track Delivery</p>
                                </div>                        
                            </div>


                            </div>
                            
                    </Card>
                </Col>
            </Row>        
        </div>
    </div>
  )
}

export default CheckoutSuccessful