import React from 'react'
import {Divider, Spin, Tabs, Image, Upload, message, Form, Input, Button, Select, TimePicker, notification, Tag, Checkbox, Modal} from 'antd'
import {LoadingOutlined} from '@ant-design/icons'
import {PlusOutlined} from '@ant-design/icons';
import NaijaStates from "naija-state-local-government";
import moment from 'moment';
import {useNavigate} from 'react-router-dom';
import PhoneInput from 'react-phone-input-2';

//	styles
import '../../assets/css/shop.css'
import '../../assets/css/restaurnt.css'

// compoenents
import Navbar from '../../components/Navbar'
import RestaurantSideNav from '../../components/dashboard/restaurant_side_nav'
import MobileDashTabs from '../../components/dashboard/MobileDashTabs';
import {_add_restaurant, _delete_cooking_area_image, _get_all_state, _get_cities_by_state_code, _get_vendor_restaurant, _update_kitchen_profile, _update_restaurant_profile} from '../../common/axios_services';
import {useSelector} from 'react-redux';
import {suggestedBanners} from '../../common/constant';

const RestaurantProfile = () => {
	const {TabPane} = Tabs;
	const [form] = Form.useForm()
	const [kitchenEvalForm] = Form.useForm()
	const navigate = useNavigate()
	const newAntIcon = <LoadingOutlined style={{fontSize: 24, color: '#008060'}} spin />;
	const openNotificationWithIcon = (type, title, message) => {
		notification[type]({
			message: title,
			description: message,
			placement: "bottom-right"
		});
	};

	// States
	const timeFormat = 'mm:ss';
	const openTimeFormat = 'HH:mm'; // Use HH:mm format for hours and minutes
	const [all_states, setAllStates] = React.useState([{name: 'Lagos', state_code: 'LG'}]);
	const [restaurantError, setRestaurantError] = React.useState('');
	const [spinnerLoading, setSpinnerLoading] = React.useState(false);
	const [banner_url, setBannerUrl] = React.useState(null);
	const [loading, setLoading] = React.useState(false);
	const [updatingProfile, setUpdatingProfile] = React.useState(false);
	const [all_lgas, set_all_lgas] = React.useState([]);
	const [minTime, setMinTime] = React.useState(moment('00:00', timeFormat));
	const [maxTime, setMaxTime] = React.useState(moment('00:00', timeFormat));
	const [openTime, setOpenTime] = React.useState(moment('09:00', openTimeFormat));
	const [closeTime, setCloseTime] = React.useState(moment('17:00', openTimeFormat));
	const [countryCode, setCountryCode] = React.useState('234');
	const [phoneNumber, setPhoneNumber] = React.useState();
	// const [selectedTags, setSelectedTags] = React.useState([]);
	// const [selectedStorageFacilityTags, setSelectedStorageFacilityTags] = React.useState([]);
	const [previewCookingAreaImage, setPreviewCookingAreaImage] = React.useState('');
	const [previewOpen, setPreviewOpen] = React.useState(false);
	const [agreed, setAgreed] = React.useState(false);
	const [vendorRestaurant, setVendorRestaurant] = React.useState(false);
	const [updateCount, setUpdateCount] = React.useState(0);
	const [activeTabKey, setActiveTabKey] = React.useState("1");
	const [restaurant, setRestaurant] = React.useState({});
	const [createMenuModal, setCreateMenuModal] = React.useState(false);
	const [isBannerModalOpen, setIsBannerModalOpen] = React.useState(false);
	const [isRemovingImage, setIsRemovingImage] = React.useState(false);
	const [cookingAreaFileList, setCookingAreaFileList] = React.useState([]);
	const cookingAreaImageUrls = cookingAreaFileList.filter(file => file.thumbUrl).map(file => file.thumbUrl);
	const profileInfo = useSelector(state => state.userProfile.dataFetched);
	const allowedLGAs = [
		"Alimosho",
		"Amuwo-Odofin",
		"Apapa",
		"Ifako-Ijaiye",
		"Ikeja",
		"Lekki-Ajah",
		"Lagos-Island",
		"Surulere",
		"Yaba-Akoka"
	];


	// const foodHygieneTags = ['Gloves', 'apron', 'boots'];
	// const storageFacilityTags = ['Fridge', 'Freezer'];

	// Functions

	// Function to open modal with banner suggestions
	const showSuggestions = () => {
		setIsBannerModalOpen(true);
	};

	// Function to handle selection of a suggested banner
	const selectBanner = (url) => {
		setBannerUrl(url); // Set the selected banner URL
		setIsBannerModalOpen(false); // Close the modal after selecting
	};

	const handleModalClose = () => {
		setIsBannerModalOpen(() => false);
	};

	// const getBase64 = (img, callback) => {
	// 	const reader = new FileReader();
	// 	reader.readAsDataURL(img);
	// 	reader.addEventListener('load', () => callback(reader.result));
	// }

	// Helper function to convert file to base64
	const getBase64 = (img, callback) => {
		const reader = new FileReader();
		reader.readAsDataURL(img);
		reader.onload = () => callback(reader.result); // Set the result to the callback
		reader.onerror = (error) => console.error("Error reading file:", error); // Error handling
	};

	const preBannerUpload = (file) => {
		const isImage = file.type.startsWith('image/');
		if (!isImage) {
			message.error('You can only upload image files!');
			return Upload.LIST_IGNORE;
		}

		const isLt5MB = file.size / 1024 / 1024 < 5;
		if (!isLt5MB) {
			message.error('Image must be smaller than 5MB!');
			return Upload.LIST_IGNORE;
		}

		return true;
	};

	// const changeBanner = info => {
	// 	getBase64(info.file.originFileObj, imageUrl =>
	// 		setBannerUrl(imageUrl),
	// 		setLoading(false),
	// 	);
	// 	const reader = new FileReader();

	// 	reader.readAsDataURL(info.file.originFileObj);
	// 	reader.addEventListener('load', () => {});
	// };

	const changeBanner = info => {
		// Check if the file and originFileObj exist before proceeding
		if (info.file) {
			getBase64(info.file, (imageUrl) => {
				setBannerUrl(imageUrl);
				setLoading(false);
				setIsBannerModalOpen(false)
			});
		} else {
			console.error("File object is not valid");
		}
	};

	const uploadButton = (
		<div style={{width: '100%', height: '300px', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column'}}>
			{loading ? <LoadingOutlined /> : <PlusOutlined />}
			<div style={{marginTop: 8}}>Upload Banner</div>
		</div>
	);

	const getCookingAreaBase64 = (file) =>
		new Promise((resolve, reject) => {
			const reader = new FileReader();
			reader.readAsDataURL(file);
			reader.onload = () => resolve(reader.result);
			reader.onerror = (error) => reject(error);
		});

	const handleCookingAreaPreview = async (file) => {
		if (!file.url && !file.preview) {
			file.preview = await getCookingAreaBase64(file.originFileObj);
		}
		setPreviewCookingAreaImage(file.url || file.preview);
		setPreviewOpen(true);
	};

	const changeCookingAreaImage = ({fileList: newFileList}) => setCookingAreaFileList(newFileList);

	const cookingAreaUploadButton = (
		<button style={{border: 0, background: 'none'}} type="button">
			<PlusOutlined />
			<div style={{marginTop: 8}}>Upload</div>
		</button>
	);

	// Submit restaurant profile
	const handlePhoneChange = (phone, country) => {
		const dialCode = `${country.dialCode}`;
		setCountryCode(dialCode);

		// Remove the country code from the start of the phone number
		const phoneWithoutCode = phone.startsWith(dialCode)
			? phone.slice(dialCode?.length).trim()
			: phone;
		setPhoneNumber(phoneWithoutCode);
	};

	// Finish Kitchen Profile
	const finishKitchenProfile = async (values) => {
		if (restaurant) {
			const {cooking_area, cooking_area_image, longitude, latitude, ...res_data} = restaurant
			const data = {
				...res_data,
				cooking_area: true,
				cooking_area_image: cookingAreaFileList.filter(file => file.thumbUrl).map(file => file.thumbUrl),
				longitude: longitude ? longitude : '0.00',
				latitude: latitude ? latitude : '0.00',
			}
			try {
				setUpdatingProfile(true);
				if (vendorRestaurant) {
					const updateRestaurantProfile = await _update_restaurant_profile(vendorRestaurant.id, data)
					openNotificationWithIcon('success', updateRestaurantProfile?.data?.title, updateRestaurantProfile?.data?.message);
				} else {
					const addRestaurant = await _add_restaurant(data)
					setCreateMenuModal(true)
					openNotificationWithIcon('success', addRestaurant?.data?.title, addRestaurant?.data?.message);
				}
				setUpdateCount(updateCount + 1)
			} catch (err) {
				if (err.response) {
					if (err.response.data.message === "Unauthorized") {
						localStorage.removeItem('konnect_token')
						navigate('/signin')
					}
					openNotificationWithIcon('error', err.response.data.message)
				} else {
					openNotificationWithIcon('error', err.message)
				}
			} finally {
				setUpdatingProfile(false);
			}
		} else if (vendorRestaurant && !restaurant) {
			try {
				const {longitude, latitude, cooking_area, cooking_area_image, ...restaurantInfo} = vendorRestaurant
				const data = {
					cooking_area: true,
					cooking_area_image: cookingAreaFileList.filter(file => file.thumbUrl).map(file => file.thumbUrl),
					longitude: longitude ? longitude : '0.00',
					latitude: latitude ? latitude : '0.00',
					...restaurantInfo
				};
				setUpdatingProfile(true);
				const updateRestaurantProfile = await _update_restaurant_profile(vendorRestaurant?.id, data)
				setUpdateCount(updateCount + 1)
				openNotificationWithIcon('success', updateRestaurantProfile?.data?.title, updateRestaurantProfile?.data?.message);
			} catch (err) {
				if (err.response) {
					if (err.response.data.message === "Unauthorized") {
						localStorage.removeItem('konnect_token')
						navigate('/signin')
					}
					openNotificationWithIcon('error', err.response.data.message)
				} else {
					openNotificationWithIcon('error', err.message)
				}
			} finally {
				setUpdatingProfile(false);
			}
		}
	}

	const finishProfile = async (values) => {
		const data = {
			name: values.name,  // Restaurant name
			display_image: banner_url || "",  // Restaurant display image URL
			description: values.description,  // Restaurant description
			longitude: '0.00',  // You can replace it with actual longitude if available
			latitude: '0.00',  // You can replace it with actual latitude if available
			address: values.address,  // Pickup address
			state: values.state,  // State
			city: values.lga,  // LGA (used as city)
			instagram: values.instagram_handle,  // Instagram handle
			contact_name: values.contact || "",  // Contact name (optional)
			contact_phone_code: countryCode || "",  // Contact phone code
			contact_phone: phoneNumber || "",  // Contact phone number
			contact_email: values.contact_email,  // Contact email
			min_ready_time: values.min_ready_time.format(timeFormat),  // Minimum ready time
			max_ready_time: values.max_ready_time.format(timeFormat),  // Maximum ready time
			open_time: values.open_time.format(openTimeFormat),  // Restaurant open time
			close_time: values.close_time.format(openTimeFormat),  // Restaurant close time
			business_document: "",  // Placeholder if needed
			cooking_area: vendorRestaurant?.cooking_area ? vendorRestaurant?.cooking_area : '',
			cooking_area_image: vendorRestaurant?.cooking_area_image ? vendorRestaurant?.cooking_area_image : '',
			storage_facility: "",  // Storage facility description
			staff_count: values.staff_count,  // Staff count
			food_hygiene: "",  // Food hygiene description
			active_email: values.active_email,  // Active email
			is_open: true
		};

		setRestaurant({...data})
		if (!data?.cooking_area) {
			setActiveTabKey("3")
		}
	}

	const fetchCities = async (state_code = "LA", keyword = "") => {
		try {
			const uploadedCities = await _get_cities_by_state_code(state_code, keyword);
			set_all_lgas(uploadedCities.data.data);
		} catch (err) {
			set_all_lgas([]);
			if (err.response) {
				openNotificationWithIcon(err?.response?.data?.status, err?.response?.data?.title, err?.response?.data?.message);
			} else {
				openNotificationWithIcon('error', "Something went wrong", err.message);
			}
		}
	}

	const onChangeState = async (value, option) => {
		if (value) {
			// Clear any previously selected LGA
			form.setFieldsValue({lga: ""});
			// Retrieve the state_code (key) of the selected option
			const stateCode = option.key;
			await fetchCities(stateCode)

			// // Get all LGAs in the selected state
			// const allLGAsinAState = NaijaStates.lgas(`${value}`);

			// // Filter to only include the allowed LGAs
			// const filteredLGAs = allLGAsinAState.lgas.filter((lga) => allowedLGAs.includes(lga));

			// // Set the filtered LGAs to the state to update the UI
			// set_all_lgas(filteredLGAs);
		}
	};

	// Handle changes for Minimum Time
	const handleMinTimeChange = (time, timeString) => {
		const selectedTime = moment(timeString, timeFormat);
		// Check if minimum time is greater than the current maximum time
		if (selectedTime.isSameOrAfter(maxTime)) {
			setRestaurantError('Minimum ready time cannot be greater than or equal to Maximum ready time')
		} else {
			setRestaurantError('')
			setMinTime(selectedTime);
		}
	};

	// Handle changes for Maximum Time
	const handleMaxTimeChange = (time, timeString) => {
		const selectedTime = moment(timeString, timeFormat);
		// Check if maximum time is less than the current minimum time
		if (selectedTime.isSameOrBefore(minTime)) {
			setRestaurantError('Maximum ready time must be greater than Minimum ready time')
		} else {
			setRestaurantError('')
			setMaxTime(selectedTime);
		}
	};

	// Handle Open Time change
	// const handleOpenTimeChange = (time, timeString) => {
	// 	const selectedTime = moment(timeString, openTimeFormat);
	// 	// Validate that open time is not after close time
	// 	if (selectedTime.isSameOrAfter(closeTime)) {
	// 		setRestaurantError('Open time must be earlier than Close time')
	// 	} else {
	// 		setRestaurantError('')
	// 		setOpenTime(selectedTime);
	// 	}
	// };

	// // Handle Close Time change
	// const handleCloseTimeChange = (time, timeString) => {
	// 	const selectedTime = moment(timeString, openTimeFormat);
	// 	// Validate that close time is not before open time
	// 	if (selectedTime.isSameOrBefore(openTime)) {
	// 		setRestaurantError('Close time must be later than Open time')
	// 	} else {
	// 		setRestaurantError('');
	// 		setCloseTime(selectedTime);
	// 	}
	// };

	const handleSecondOpenTimeChange = (time) => {
		const formattedTime = time ? time.format("HH:mm") : null; // Extract "HH:mm"
		setRestaurantError('')
		setOpenTime(formattedTime); // Update state with formatted time
	};

	const handleSecondCloseTimeChange = (time) => {
		const formattedTime = time ? time.format("HH:mm") : null; // Extract "HH:mm"
		setRestaurantError('');
		setCloseTime(formattedTime); // Update state with formatted time
	};


	// const checkStorageFacilityTag = (tag, checked) => {
	// 	const nextSelectedTags = checked ? [...selectedStorageFacilityTags, tag] : selectedStorageFacilityTags.filter((t) => t !== tag);
	// 	setSelectedStorageFacilityTags(nextSelectedTags);
	// 	kitchenEvalForm.setFieldsValue({storage_facility: nextSelectedTags});
	// };

	// const checkTag = (tag, checked) => {
	// 	const nextSelectedTags = checked ? [...selectedTags, tag] : selectedTags.filter((t) => t !== tag);
	// 	setSelectedTags(nextSelectedTags);
	// 	kitchenEvalForm.setFieldsValue({food_hygiene: nextSelectedTags});
	// };

	const fetchRestaurant = async () => {
		try {
			setSpinnerLoading(true);
			let response = await _get_vendor_restaurant();
			const data = response.data.data || {};
			// console.log(data)
			form.setFieldsValue({
				name: data?.name,
				description: data?.description,
				address: data?.address,
				state: data?.state,
				lga: data?.city,
				instagram_handle: data?.instagram,
				contact: data?.contact_name ? data.contact_name : `${profileInfo.first_name} ${profileInfo.last_name}`,
				contactNumber: `${data?.contact_phone_code ? data.contact_phone_code : '234'}${data?.contact_phone}`,
				contact_email: data?.contact_email ? data.contact_email : `${profileInfo.email}`,
				min_ready_time: data?.min_ready_time ? moment(data.min_ready_time, timeFormat) : null,
				max_ready_time: data?.max_ready_time ? moment(data.max_ready_time, timeFormat) : null,
				open_time: data?.open_time ? moment(data.open_time, openTimeFormat) : null,
				close_time: data?.close_time ? moment(data.close_time, openTimeFormat) : null,
			});
			if (data?.state) {
				let state_code = ""
				await fetchCities(state_code, data?.state)
			}
			if (data?.cooking_area) {
				setAgreed(true)
			}
			if (data && (data?.kitchen_images?.length > 0)) {
				const mappedFileList = data.kitchen_images.map((url, index) => ({
					uid: `${url?.id}`,
					name: `cooking_area-${url?.id}`,
					status: 'done',
					thumbUrl: url?.url,
					url: url?.url,
				}));

				setCookingAreaFileList(mappedFileList);
			} else if (data && data?.kitchen_images?.length === 0) {
				setCookingAreaFileList([]);
			}
			setVendorRestaurant(response?.data?.data ? response?.data?.data : false);
			setBannerUrl(data?.display_image);
		} catch (err) {
			if (err.response) {
				if (err.response.data.message === "Unauthorized") {
					localStorage.removeItem('konnect_token')
					navigate('/signin')
				}
				openNotificationWithIcon('error', err.response.data.message)
			} else {
				openNotificationWithIcon('error', err.message)
			}
			setVendorRestaurant(false);
		} finally {
			setSpinnerLoading(false);
		}
	}

	const fetchAddedStates = async () => {
		try {
			const uploadedStates = await _get_all_state();
			setAllStates(uploadedStates.data.data);
		} catch (err) {
			setAllStates([{name: 'Lagos', state_code: 'LG'}]);
			if (err.response) {
				openNotificationWithIcon(err?.response?.data?.status, err?.response?.data?.title, err?.response?.data?.message);
			} else {
				openNotificationWithIcon('error', "Something went wrong", err.message);
			}
		}
	}

	React.useEffect(() => {
		fetchRestaurant();
		fetchAddedStates()
	}, [updateCount])

	const handleRemoveImage = async (file) => {
		if (vendorRestaurant && (vendorRestaurant?.kitchen_images?.length > 0)) {
			try {
				setIsRemovingImage(true);
				const imageId = file.uid;
				const delete_cooking_area_image = await _delete_cooking_area_image(vendorRestaurant.id, imageId)
				message.success(delete_cooking_area_image?.data?.message);
				setIsRemovingImage(false);
			} catch (err) {
				if (err.response) {
					if (err.response.data.message === "Unauthorized") {
						localStorage.removeItem('konnect_token')
						navigate('/signin')
					}
					openNotificationWithIcon('error', err.response.data.title, err.response.data.message)
				} else {
					openNotificationWithIcon('error', err.message)
				}
			}
		}
	};

	return (
		<div className="userprofile">
			<Spin spinning={spinnerLoading} indicator={newAntIcon}>
				<div className="profile-content">
					<Navbar />
					<div className="gray_bg_profile">
						<div className="profile_to_left">
							<div className="">
								<div className="profile_nav">
									<RestaurantSideNav />
								</div>
							</div>
						</div>
						<div className="profile_to_right">
							<div className="contain">
								<div className="main_container">
									<MobileDashTabs />
									<div className="main_container_title_div">
										<h5>Profile</h5>
										<Divider style={{margin: '0px 0 5px'}} />
									</div>

									{/* Restaurant Profile */}
									{/* <div style={{marginBottom: '20px'}} className="avatar-uploader">
										<Upload name="avatar" listType="picture-card" showUploadList={false} multiple={false} onChange={changeBanner} beforeUpload={preBannerUpload} >
											{banner_url ? <img src={banner_url} alt="avatar" style={{width: '100%', height: '300px', objectFit: 'cover'}} /> : uploadButton}
										</Upload>
									</div> */}
									<div style={{marginBottom: '20px'}} className="avatar-uploader">
										{/* Upload Component */}
										{/* <Upload
											name="banner"
											listType="picture-card"
											showUploadList={false}
											multiple={false}
											onChange={changeBanner}
											beforeUpload={() => false} // Prevents automatic upload
											onClick={showSuggestions}
										>
											{banner_url ? (
												<img
													src={banner_url}
													alt="banner"
													style={{width: '100%', height: '300px', objectFit: 'cover'}}
												/>
											) : (
												uploadButton
											)}
										</Upload> */}

										{banner_url ? (
											<img
												src={banner_url}
												alt="banner"
												className="w-full h-[300px] object-cover border-2 border-dashed border-gray-300"
												onClick={showSuggestions}
											/>
										) : (
											<div onClick={showSuggestions} className="w-full h-[300px] bg-gray-100 flex items-center justify-center border-2 border-dashed border-gray-300">
												Click to upload banner
											</div>
										)}

										{/* Modal for Suggested Banners */}
										<Modal centered open={isBannerModalOpen} title="Choose a Banner" onCancel={handleModalClose} footer={null} >
											<div style={{display: 'flex', gap: '10px', flexWrap: 'wrap'}}>
												{suggestedBanners.map((banner) => (
													<img key={banner} src={banner} alt="suggested-banner"
														style={{
															width: '100px',
															height: '60px',
															objectFit: 'cover',
															cursor: 'pointer',
															border: banner === banner_url ? '2px solid #1890ff' : '1px solid #ddd',
														}}
														onClick={() => {selectBanner(banner); handleModalClose()}} // Select banner on click
													/>
												))}
											</div>

											{/* Upload button for new banner within the modal */}
											<div className='flex justify-between items-center mt-5'>
												<Upload
													name="banner"
													showUploadList={false}
													onChange={changeBanner}
													beforeUpload={() => false} // Prevents automatic upload
												>
													<Button>Upload New Banner</Button>
												</Upload>
												<Button onClick={handleModalClose}>Close</Button>
											</div>
										</Modal>
									</div>
									<Tabs type='card' activeKey={activeTabKey} onChange={(key) => setActiveTabKey(key)}>
										{/* Restaurant Profile */}
										<TabPane tab="Restaurant Profile" key="1">
											<Form form={form} layout="vertical" onFinish={finishProfile}
												  initialValues={{}} style={{padding: '20px'}}>
												{restaurantError && <p className="error-message">{restaurantError}</p>}
												<p className="profile_category_title">Restaurant basic information</p>
												<div className="flex-side">
													<Form.Item label="Name (as to be displayed)" name="name" rules={[{
														required: true,
														message: 'Please input your restaurant name!'
													}]}>
														<Input type="text" style={{height: '5rem'}}/>
													</Form.Item>
													<Form.Item label="Contact" name="contact">
														<Input type="text" style={{height: '5rem'}}/>
													</Form.Item>
												</div>

												<div className="form-flex-2">
													<Form.Item label="Contact phone" name="contactNumber" rules={[{
														required: true,
														message: 'Please input your restaurant contact phone!'
													}]}>
														<PhoneInput country={'ng'} onChange={handlePhoneChange}
																	containerStyle={{width: '100%'}}
																	inputStyle={{height: '5rem', width: '100%'}}/>
													</Form.Item>
													<Form.Item label="Contact Email" name="contact_email" rules={[{
														required: true,
														message: 'Please input your restaurant contact email!'
													}]}>
														<Input type="email" disabled style={{height: '5rem'}}/>
													</Form.Item>
												</div>

												<div>
													<Form.Item label="Instagram Handle" name="instagram_handle">
														<Input type="text" style={{height: '5rem'}}/>
													</Form.Item>
												</div>

												<p className="profile_category_title">Restaurant location</p>
												<div className="form-flex-2">
													<Form.Item name="state" label="State" className="form-group"
															   rules={[{
																   required: true,
																   message: "Please fill input is required"
															   }]}>
														<Select
															onChange={(value, option) => onChangeState(value, option)}
															placeholder="Select State" name="state"
															style={{width: "100%", height: "5rem"}}>
															{all_states.map((state) => (
																<Select.Option key={state.state_code}
																			   value={state.name}>{state.name}</Select.Option>
															))}
														</Select>
													</Form.Item>
													<Form.Item name="lga" label="LGA" className="form-group" rules={[{
														required: true,
														message: "Please fill input is required"
													}]}>
														<Select placeholder="Select LGA" name="state"
																style={{width: "100%", height: "5rem",}}>
															{all_lgas.map((lga) => (
																<Select.Option key={lga.id}
																			   value={lga.city_name}>{lga.city_name}</Select.Option>
															))}
														</Select>
													</Form.Item>
												</div>

												<div>
													<Form.Item label="Pickup address" name="address" rules={[{
														required: true,
														message: "Please fill input is required"
													}]}>
														<Input type="text" style={{height: '5rem'}}/>
													</Form.Item>
												</div>

												<p className="profile_category_title">Restaurant cooking time</p>
												<div className="form-flex-2">
													<Form.Item label="Minimum ready time (in minutes) "
															   name="min_ready_time" rules={[{
														required: true,
														message: 'Please select your minimum ready time!'
													}]}>
														<TimePicker maxLength={5} size="large"
																	className="custom-timepicker"
																	style={{width: '100%'}} format={timeFormat}
																	value={minTime} onChange={handleMinTimeChange}
																	showNow={false}/>
													</Form.Item>
													<Form.Item label="Maximum ready time (in minutes) "
															   name="max_ready_time" rules={[{
														required: true,
														message: 'Please select your maximum ready time!'
													}]}>
														<TimePicker maxLength={5} size="large"
																	className="custom-timepicker"
																	style={{width: '100%'}} format={timeFormat}
																	value={maxTime} onChange={handleMaxTimeChange}
																	showNow={false}/>
													</Form.Item>
												</div>

												{/*<div className="form-flex-2">*/}
												{/*	<Form.Item label="Open time" name="open_time" rules={[{required: true, message: 'Please select your restaurant open time!'}]}>*/}
												{/*		<TimePicker maxLength={5} format={openTimeFormat} value={openTime} onChange={handleOpenTimeChange} size="large" showNow={false} className="custom-timepicker" style={{width: '100%'}} />*/}
												{/*	</Form.Item>*/}
												{/*	<Form.Item label="Close time" name="close_time" rules={[{required: true, message: 'Please select your restaurant close time!'}]}>*/}
												{/*		<TimePicker maxLength={5} format={openTimeFormat} value={closeTime} onChange={handleCloseTimeChange} size="large" showNow={false} className="custom-timepicker" style={{width: '100%'}} />*/}
												{/*	</Form.Item>*/}
												{/*</div>*/}

												<div className="form-flex-2">

													{/* Open Time in AM */}
													<Form.Item
														label="Open time"
														name="open_time"
														rules={[{
															required: true,
															message: 'Please select your restaurant open time!'
														}]}
													>
														<TimePicker
															format="h:mm A" // Display 12-hour format with AM/PM
															size="large"
															className="custom-timepicker"
															style={{width: '100%'}}
															value={openTime ? moment(openTime, "HH:mm") : null} // Convert back to moment for display
															onChange={handleSecondOpenTimeChange}
															showNow={false}
															disabledHours={() => [...Array(12).keys()].map(hour => hour + 12)} // Disable PM hours
														/>
													</Form.Item>

													{/* Close Time in PM */}
													<Form.Item
														label="Close time"
														name="close_time"
														rules={[{
															required: true,
															message: 'Please select your restaurant close time!'
														}]}
													>
														<TimePicker
															format="h:mm A" // Display 12-hour format with AM/PM
															size="large"
															className="custom-timepicker"
															style={{width: '100%'}}
															value={closeTime ? moment(closeTime, "HH:mm") : null} // Convert back to moment for display
															onChange={handleSecondCloseTimeChange}
															showNow={false}
															disabledHours={() => [...Array(12).keys()]} // Disable AM hours
														/>
													</Form.Item>
												</div>

													<div>
														<Form.Item label="Description" name="description">
															<Input.TextArea placeholder="Enter description here..."
																			rows={6} className="custom-textarea"/>
														</Form.Item>
													</div>

													{updatingProfile || !banner_url ? (
														<button
															className="restaurant_profile_button bg-gray-300 text-gray-500 cursor-not-allowed border-none px-6 py-3 rounded-lg flex items-center justify-center transition-colors"
															disabled>
															{updatingProfile ? (
																<>Updating profile. Please wait<Spin
																	style={{marginLeft: '10px'}}
																	indicator={newAntIcon}/></>
															) : (
																<>Please upload a banner to proceed</>
															)}
														</button>
													) : (
														<button
															className="restaurant_profile_button bg-blue-500 text-white hover:bg-blue-600 border-none px-6 py-3 rounded-lg transition-colors"
															htmlType="submit">Update Profile</button>
													)}
											</Form>
										</TabPane>
										{/* Quality assurance commitment */}
										<TabPane tab="Quality assurance commitment" key="2">
											<div className='restaurant_qac'>
												<h2>ALLERGENS </h2>
												<p className='qac_text'>You can call  {vendorRestaurant?.name ? vendorRestaurant.name : (<span>&lt; &lt; Restaurants name &gt;&gt;</span>)} to ask about their ingredients and allergen information, production or cooking methods. Call  {vendorRestaurant?.name ? vendorRestaurant.name : (<span>&lt; &lt; Restaurants name &gt;&gt;</span>)} on  {vendorRestaurant?.contact_phone ? `${vendorRestaurant?.phone_code ? vendorRestaurant?.phone_code : ''}${vendorRestaurant.contact_phone}` : (<span>&lt; &lt; +234xxxxxxxxx &gt;&gt;</span>)} </p>
												<div style={{margin: '3%'}}></div>
												<h2>HYGIENE RATING</h2>
												<p className='qac_text'>The FSA food hygiene rating is a good standing meaning the kitchen has good hygiene practices.</p>
												<div style={{margin: '3%'}}></div>
												<h2>LOCATION</h2>
												<p className='qac_text'> {vendorRestaurant?.address ? vendorRestaurant.address : (<span>&lt; &lt; Restaurants address &gt;&gt;</span>)}</p>
											</div>
											<div className='restaurant_profile_note'>
												<h2>NOTES</h2>
												<p className='qac_text'>All dishes may contain traces of the following allergens: Gluten, Crustaceans, Eggs, Fish, Peanuts, Soybeans, Milk, Nuts (e.g. almonds, hazelnuts, walnuts, cashews), For any questions regarding the allergen contents of specific dishes please contact the restaurant directly.</p>
											</div>
										</TabPane>
										{/* Kitchen Evaluation */}
										<TabPane tab="Kitchen Evaluation" key="3">
											<div className="p-4 border border-gray-200 rounded-lg bg-gray-50">
												<h2 className="text-2xl lg:text-3xl font-semibold mb-3">Kitchen Evaluation Page</h2>
												<p className="mb-4 text-2xl">
													Thank you for providing your restaurant profile. We aim to satisfy our customers, thus we run a check on your restaurant and kitchen.Please read below and click agree to submit your profile:
												</p>
												<ul className="list-disc list-inside mb-4">
													<li className='text-2xl'>We have a cooking area and have uploaded pictures.</li>
													<li className='text-2xl'>We have storage facilities to preserve foods - Fridge and Freezer.</li>
													<li className='text-2xl'>We have the required staff to deliver within our cooking time.</li>
													<li className='text-2xl'>We keep our restaurant clean and follow proper hygiene practices.</li>
													<li className='text-2xl'>Our email is active and will track all notifications.</li>
												</ul>
												<p className="font-bold text-2xl lg:text-3xl mt-3 mb-2">Note the following important pickup practices:</p>
												<ul className="list-disc list-inside mb-4">
													<li className='text-2xl'>You MUST click “Restaurant open” button to start each day.</li>
													<li className='text-2xl'>You MUST click “Restaurant close” button to close each day.</li>
													<li className='text-2xl'>You MUST use a seal tape to seal the food pack.</li>
													<li className='text-2xl'>You MUST paste the order code sticker on the order package.</li>
												</ul>
											</div>

											<Form form={kitchenEvalForm} layout="vertical" onFinish={finishKitchenProfile} style={{padding: '20px'}}>
												<div>
													<Form.Item label="Upload Cooking Area">
														<Upload
															listType="picture-card"
															fileList={cookingAreaFileList}
															onPreview={handleCookingAreaPreview}
															onChange={changeCookingAreaImage}
															beforeUpload={() => false}
															onRemove={(file) => handleRemoveImage(file)}
														>
															{cookingAreaFileList?.length >= 8 ? null : cookingAreaUploadButton}
														</Upload>
														{isRemovingImage && <div className="absolute top-0 left-0 w-full h-full flex items-center justify-center bg-opacity-50 bg-gray-100"><Spin /></div>}
														{previewCookingAreaImage && (
															<Image
																wrapperStyle={{display: 'none'}}
																preview={{visible: previewOpen, onVisibleChange: (visible) => setPreviewOpen(visible), afterOpenChange: (visible) => !visible && setPreviewCookingAreaImage('')}}
																src={previewCookingAreaImage}
															/>
														)}
													</Form.Item>
												</div>

												{/* <div className="form-flex-2">
														<Form.Item label="Storage Facility" name="storage_facility" rules={[{required: true, message: 'Please input your restaurant storage facility!'}]}>
															{storageFacilityTags.map((tag) => (
																<Tag.CheckableTag key={tag} checked={selectedStorageFacilityTags.includes(tag)} onChange={(checked) => checkStorageFacilityTag(tag, checked)} className={`m-2 p-3 rounded-xl ${selectedStorageFacilityTags.includes(tag) ? 'bg-blue-500 text-white' : 'bg-gray-100 text-black hover:bg-gray-200'}`}>
																	{tag}
																</Tag.CheckableTag>
															))}
														</Form.Item>
														<Form.Item label="Food Hygiene" name="food_hygiene" rules={[{required: true, message: 'Please input your restaurant food hygiene info!'}]}>
															{foodHygieneTags.map((tag) => (
																<Tag.CheckableTag key={tag} checked={selectedTags.includes(tag)} onChange={(checked) => checkTag(tag, checked)} className={`m-2 p-3 rounded-xl ${selectedTags.includes(tag) ? 'bg-blue-500 text-white' : 'bg-gray-100 text-black hover:bg-gray-200'}`}>
																	{tag}
																</Tag.CheckableTag>
															))}
														</Form.Item>
													</div>

													<div>
														<Form.Item label="Is Open" name="is_open" rules={[{required: true, message: 'Please select if restaurant is open!'}]}>
															<Select placeholder="Is Open" name="is_open" style={{width: "100%", height: "5rem", }} >
																<Select.Option value={true}>True</Select.Option>
																<Select.Option value={false}>False</Select.Option>
															</Select>
														</Form.Item>
													</div> */}

												{/* Agree Checkbox */}
												<div className="flex items-center my-4">
													<Checkbox checked={agreed} onChange={(e) => setAgreed(e.target.checked)} disabled={cookingAreaFileList?.length === 0} className="text-lg">I agree to the terms and conditions.</Checkbox>
												</div>

												{/* Submit Button */}
												{updatingProfile ? (
													<button className="restaurant_profile_button bg-gray-300 text-gray-500 cursor-not-allowed border-none px-6 py-3 rounded-lg flex items-center justify-center transition-colors" disabled >Submitting. Please wait<Spin style={{marginLeft: '10px'}} indicator={newAntIcon} /></button>
												) : (
													<button
														disabled={!agreed}
														className={`restaurant_profile_button px-6 py-3 rounded-lg transition-colors 
																${agreed ?
																'bg-blue-500 text-white hover:bg-blue-600 border-none'
																: 'bg-white text-gray-500 border border-gray-400 cursor-not-allowed'}`}
														htmlType="submit"
													>Submit</button>
												)}
											</Form>
										</TabPane>
									</Tabs>
								</div>
							</div>
						</div>
					</div>
				</div>
			</Spin>
			{/* Inform customer they have to create menu modal */}
			<Modal title="Note" open={createMenuModal} centered footer={null} onCancel={() => setCreateMenuModal(false)}>
				<p>Thank you for providing your restaurant profile. <b>Restaurants must add items to their menu to be approved.</b></p>
				<button onClick={() => navigate('/restaurant/menu')} className='restaurant_profile_button px-6 py-3 rounded-lg transition-colors bg-blue-500 text-white hover:bg-blue-600 border-none mt-3'>Create Menu</button>
			</Modal>
		</div >
	)
}
export default RestaurantProfile;
