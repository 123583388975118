import React from 'react';
import Navbar from '../../components/Navbar';
import { Card, Col, Row } from 'antd';
import { CheckCircleOutlined, StarFilled, StarOutlined } from '@ant-design/icons';
import map_locationIcon from '../../assets/images/restaurant/map_locationIcon.svg';
import { UnderConstructionModal } from '../../components/UnderConstructionModal';

const TrackOrder = () => {
  return (
    <div>
        <div>
            <Navbar noShadow={true} adjustNavSize={true} />
            <div className='mt-10'>
                <Row justify={'center'}>
                    <Col xs={24}>
                        <div className="flex mx-auto">
                        <h2 className="lg:text-7xl text-5xl mx-auto">Track delivery - NH16T</h2>
                        </div>
                    </Col>
                </Row>
                <div className="flex justify-center items-center my-10 ">
                    <div className='lg:w-1/2 w-full rounded-2xl border border-black py-5 px-10 '>
                        <Card className='py-16 mb-5 flex justify-center items-center rounded-3xl border-2 border-black bg-[#03BFA6] ' bordered={false}>
                            <div className='h-full flex justify-center  items-center gap-3 mx-auto  '>
                                <CheckCircleOutlined  style={{ fontSize: '32px', color: 'white'}} />
                                <h2 className='text-3xl text-center text-white '>Your order have arrived!</h2>
                            </div>
                            <p className='text-center text-white'>After you receive your order, give the rider the delivery code</p>
                            <p className='text-center text-[15px] text-white'>Rate the restaurant</p>
                            <div className="flex justify-center  items-center gap-3">
                                <StarFilled  style={{ fontSize: '32px', color: '#FFF500'}} />
                                <StarFilled  style={{ fontSize: '32px', color: '#FFF500'}} />
                                <StarFilled  style={{ fontSize: '32px', color: '#FFF500'}} />
                                <StarFilled  style={{ fontSize: '32px', color: '#FFF500'}} />
                                <StarOutlined  style={{ fontSize: '32px', color: '#fff'}} />
                            </div>
                        </Card>  
                        <Row justify={'center'} gutter={{ lg: 32 }} className='lg:mt-16  '  >
                            <Col xs={24} lg={14}>
                                <Card bordered={false} className='w-full max-sm:mb-5 py-16 px-5 rounded-3xl border-2 border-black bg-[#EAF1E9] '>
                                    <div className='h-full w-full '>
                                        <h2 className="text-3xl text-[#44843F] ">Delivery to</h2>
                                        <div className="flex flex-wrap  gap-3 text-black mt-3">
                                            <p className='text-2xl'>John Doe</p>
                                            <p className='text-2xl font-semibold '>+234 800 123 4567</p>
                                            <p className='text-2xl'>John@gmail.com</p>
                                        </div>
                                        <div className="flex items-center gap-3 text-black mt-3">
                                            <span>
                                                <img src={map_locationIcon} alt="" className='w-10 h-10'  />
                                            </span>
                                            <p className='text-2xl font-bold'>No 17, 1st Floor, Queens Tower, Lekki Phase 1, Lagos</p>
                                        </div>
                                    </div>
                                </Card>
                            </Col>
                            <Col xs={24} lg={10}>
                                <Card bordered={false} className='w-full max-sm:my-6   py-16 px-5 rounded-3xl border-2 border-black bg-[#E9C6C66C] '>
                                    <div className='h-full w-full' >
                                        <p className='font-bold' >Give rider this code after delivery</p>
                                        <h2 className="text-4xl my-3 text-[#44843F]">1234</h2>
                                       
                                        
                                    </div>
                                </Card>
                            </Col>
                        </Row>     
                    </div>
                </div>
            
            </div>


        </div>
        
    </div>
  )
}

export default TrackOrder