import '../assets/css/shop.css';

import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import React from 'react';
import { Link, useParams } from 'react-router-dom';

import Groceries from '../assets/images/shop/grocery.webp';
import axiosCall from '../common/axios_call';
import { _all_product } from '../common/axios_services';
import Footer from '../components/Footer';
import Navbar from '../components/Navbar';
import Recommended from '../components/Recommended';
import TrendingCarousel from '../components/TrendingCarousel';


const SubCategories = () => {
    const [productBox, setProductBox] = React.useState([])
    const [searchParameter, setSearchParameter] = React.useState("")
    const [spinnerLoading, setSpinnerLoading] = React.useState(true)
    const [empty, setEmpty] = React.useState(false)
    const [trendingProducts, setTrendingProducts] = React.useState([])
    const [page, setPage] = React.useState(1);
    const [page_size, setPage_size] = React.useState(20);

    const search_cat = " "
    const params = useParams()

    const getProduct = async () => {
        try {
            const all_products = await _all_product({ page: '1', per_page: '14', search: '' })
            setTrendingProducts(all_products.data.data);
        } catch (err) {
            // console.log(err);
        }
    }

    React.useEffect(() => {
        setSpinnerLoading(true);

        axiosCall(`/sub-category/${params.subcategory_id}`).then(res => {
            setSearchParameter(res.data.data.subcat_name)
        }).catch(err => {
            // console.log(err)
        })

        axiosCall(`/products/sub_cat/${params.subcategory_id}?page=${page}&per_page=${page_size}&search=${search_cat}`).then(res => {
            setSpinnerLoading(false);
            setProductBox(res.data.data)
        }).catch(err => {
            // console.log(err)
        })

        getProduct()
    }, [params.subcategory_id])

    const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
    return (
        <div>
            <Spin indicator={antIcon} spinning={spinnerLoading}>
                <Navbar />

                <div className="category_header">
                    <img src={Groceries} alt="groceries" />
                </div>
                {!empty ?
                    <Recommended products={productBox} param={`Products for ${searchParameter} `} />
                    :
                    <div className="center-spinner">
                        <div>
                            <p>There are no products in this subcategory</p>
                            <Link to="/shop">See all products in Shop</Link>
                        </div>
                    </div>
                }
                <TrendingCarousel />
                <Recommended products={trendingProducts} param={`Trending products in the last hour`} />
                <Footer />
            </Spin>
        </div>
    )
}

export default SubCategories