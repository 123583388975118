import { LoadingOutlined } from '@ant-design/icons'
import { Card, Col, Divider, Empty, Row, Skeleton, Spin } from 'antd'
import Meta from 'antd/lib/card/Meta'
import React, { useEffect, useState } from 'react'
import NumberFormat from 'react-number-format'
import { Link } from 'react-router-dom'
import Navbar from '../components/Navbar'
import Profile from '../components/dashboard/side_nav'

const Wishlist = () => {
    const newAntIcon = <LoadingOutlined style={{ fontSize: 24, color: '#008060' }} spin />;
    const [wishListData, setWishListData] = useState([]);
    const [loadingSpinner, setLoadingSpinner] = useState(true);

    useEffect(() => {
        const saved = JSON.parse(localStorage.getItem('konnect_wishlist'));
        setLoadingSpinner(false);
        // console.log(saved)
        setWishListData(saved ? saved : []);
    }, [])

    let skeleton = [];
    for (let i = 0; i < 8; i++) {
        skeleton.push(
            <div>
                <Skeleton.Image active />
                <Skeleton style={{ marginTop: 20 }} active />
            </div>
        )
    }
    return (
        <>
            <div className="userprofile wishlist">
                <div className="profile-content">
                    <Spin spinning={loadingSpinner} indicator={newAntIcon}>
                        <Navbar />
                        <div className="gray_bg_profile">
                            <div className="profile_to_left">
                                <div className="">
                                    <div className="profile_nav">
                                        <Profile />
                                    </div>
                                </div>
                            </div>
                            {/*  */}
                            <div className="profile_to_right">
                                <div>
                                    <div className="mt-2 px-3">
                                        {
                                            !loadingSpinner ?
                                                wishListData.length ?
                                                    <>
                                                        <div className="grid-flex">
                                                            <h2 className="tile-header">Items in your wishlist</h2>
                                                            <Divider />
                                                        </div>
                                                        <Row gutter={[16, 16]}>
                                                            {wishListData.map((product, index) => (
                                                                <Col className="gutter-row" xs={12} sm={6} md={6} lg={6} xl={4} key={index} >
                                                                    <Card key={index}>
                                                                        <Link to={`/product/${product.product_name}/${product.id}`}>
                                                                            <div className="product_image">
                                                                                <img src={product.product_image} alt="product" />
                                                                            </div>
                                                                        </Link>
                                                                        <Meta title={product.product_name} /> {product.currency} <NumberFormat className="new_product_amount" value={product.konnect_price} displayType={'text'} thousandSeparator={true} />
                                                                    </Card>
                                                                </Col>
                                                            ))}
                                                        </Row>
                                                    </>
                                                    :
                                                    <div className="center-spinner" style={{ marginTop: '5%' }}>
                                                        <div>
                                                            <Empty description={<span>
                                                                You do not have any item in your Wishlist
                                                            </span>
                                                            } />
                                                        </div>
                                                    </div>
                                                :
                                                <div className="mt-5">
                                                    <div className="grid-4">
                                                        {skeleton.map((placeholder, index) => (
                                                            <div key={index}>
                                                                {placeholder}
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Spin>
                </div>
            </div>
        </>
    )
}

export default Wishlist