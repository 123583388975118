import { notification, Skeleton, Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import NumberFormat from 'react-number-format';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';

import CartImage from '../assets/images/icons/cart_white.svg';
import filledLikeEmoji from '../assets/images/icons/filled_heart.svg';
import likeEmoji from '../assets/images/icons/heart.svg';

import axiosCall from '../common/axios_call';
import Footer from '../components/Footer';
import Navbar from '../components/Navbar';
import Recommended from '../components/Recommended';
import { addToCart } from '../slices/cartSlice';
import { updateCount } from '../slices/updateCountSlice';

const SingleProduct = () => {
    const param = useParams();
    const [productBox, setProductBox] = useState([]);
    const [product, setProduct] = useState({});
    const [productInWishList, setProductInWishList] = useState(false);
    const [spinnerLoading, setSpinnerLoading] = useState(false);
    const [spinnerMessage, setSpinnerMessage] = useState('');
    const [trendingProducts, setTrendingProducts] = useState([]);
    const [addToCartButton, setAddToCartButton] = useState(false);
    const [addWishList, setAddWishList] = useState(false);
    const dispatch = useDispatch();
    const updateCounts = useSelector(state => state.updateCount.count);


    useEffect(() => {
        axiosCall(`/products/${param.id}`)
            .then(res => {
                setProduct(res.data.data);
                setProductBox(res.data.data);
                const get_wishlist = JSON.parse(localStorage.getItem('konnect_wishlist'))
                if (get_wishlist) {
                    const indexOfObject = get_wishlist.findIndex((object) => {
                        return object.product_code === res.data.data.product_code;
                    });
                    if (indexOfObject !== -1) {
                        setProductInWishList(true)
                    }
                }
            })
            .catch(err => {
                // console.log(err);
            })

        axiosCall('/products/no_pagination?page=1per_page=14&search=')
            .then(res => {
                setTrendingProducts(res.data.data);
            })
            .catch(err => {
                // console.log(err);
            });
    }, [param.name, param.id])

    const openNotificationWithIcon = (type, message) => {
        notification[type]({
            description: message,
            placement: "bottomRight"
        });
    };

    const addToWishList = e => {
        const get_wishlist = JSON.parse(localStorage.getItem('konnect_wishlist'))
        e.preventDefault();
        setAddWishList(true)
        setSpinnerLoading(true);
        setSpinnerMessage('Please wait...');
        if (!get_wishlist) {
            localStorage.setItem('konnect_wishlist', JSON.stringify([product]))
            setSpinnerLoading(false);
            setProductInWishList(true);
        } else {
            setSpinnerLoading(false);
            setProductInWishList(true);
            let index = get_wishlist.findIndex(x => x.product_code == product.product_code);
            let newProd = get_wishlist
            if (index == -1) {
                newProd.push(product)
                localStorage.setItem('konnect_wishlist', JSON.stringify(newProd))
                openNotificationWithIcon("success", "Product added to wishlist")
            } else {
                const indexOfObject = newProd.findIndex((object) => {
                    return object.product_code === product.product_code;
                });

                if (indexOfObject !== -1) {
                    newProd.splice(indexOfObject, 1);
                }
                localStorage.setItem('konnect_wishlist', JSON.stringify(newProd))
                setProductInWishList(false);
                openNotificationWithIcon("success", "Product removed from wishlist")
            }
        }
    }

    const uploadToCart = ({ product }) => {
        setAddToCartButton(true);
        const get_cart = JSON.parse(localStorage.getItem('konnect_cart'))

        setAddToCartButton(true);
        const timestamp = Date.now().toString();
        const randomNumbers = Math.floor(Math.random() * 10000).toString().padStart(4, '0');
        if (!get_cart) {
            const set_cart = {
                product_id: `${timestamp + randomNumbers}`,
                order_id: `${timestamp + randomNumbers}`,
                data: [
                    {
                        id: `${1}`,
                        order_id: `${timestamp + randomNumbers}`,
                        product_id: product.id,
                        product_name: product.product_name,
                        product_image: product.product_image,
                        product_price: product.konnect_price,
                        supplier_price: product.sellers_price,
                        description: product.description,
                        weight: product.weight,
                        discount: product.konnect_price * product.discount_rate * 0.01,
                        discount_rate: product.discount_rate,
                        quantity: 1,
                        timestamp: Date.now(),
                        total_weight: 1 * product.weight,
                        total_price: 1 * product.konnect_price,
                        total_supplier_price: product.sellers_price,
                        cashback: 0,
                        subcat: product.subcat_id
                    }
                ],
                total_amount: product.konnect_price,
                total_weight: product.weight,
                coupon: 0,
                fee: 0,
                tax: 0,
                discount: 0,
                charges: 0,
                total_item: 1,
                delivery_fee: 0,
                delivery_opt: "delivery",
                delivery_data: {}
            }
            dispatch(addToCart(set_cart))
            setAddToCartButton(false);
            localStorage.setItem('konnect_cart', JSON.stringify(set_cart))
        } else {
            let index = get_cart.data.findIndex(x => x.product_id == product.id);
            let newProd = get_cart
            if (index == -1) {
                const new_cart = {
                    id: get_cart.data.length + 1,
                    order_id: get_cart.order_id,
                    product_id: product.id,
                    product_name: product.product_name,
                    product_image: product.product_image,
                    product_price: product.konnect_price,
                    supplier_price: product.sellers_price,
                    description: product.description,
                    weight: product.weight,
                    discount: product.konnect_price * product.discount_rate * 0.01,
                    discount_rate: product.discount_rate,
                    quantity: 1,
                    timestamp: Date.now(),
                    total_weight: 1 * product.weight,
                    total_price: 1 * product.konnect_price,
                    total_supplier_price: 1 * product.sellers_price,
                    cashback: 0,
                    subcat: product.subcat_id
                }
                newProd.data.push(new_cart)
                newProd.total_amount = newProd.total_amount + product.konnect_price
                newProd.total_item = newProd.data.length
                newProd.total_weight = newProd.total_weight + product.weight
                dispatch(addToCart(newProd))
                setAddToCartButton(false);
                localStorage.setItem('konnect_cart', JSON.stringify(newProd))
            }
            setAddToCartButton(false);
        }
        dispatch(updateCount(updateCounts + 1))
    }
    return (
        <React.Fragment>
            {/* Updating Cart. Please wait... */}
            <Spin spinning={spinnerLoading} tip={spinnerMessage} >
                <Navbar />
                <div className="product-showcase">
                    <div className="contain">
                        <div className="grid_2">
                            <div className="product-display">
                                {product.id ?
                                    <img src={product.product_image} className="specific_product_image" alt="product" />
                                    :
                                    <Skeleton.Image size={'large'} active className="skeleton" />
                                }
                            </div>
                            {product ?
                                product.id ?
                                    <div>
                                        <div className="product_main">
                                            <div className="grid_flex">
                                                <div className="likeEmojiDiv">
                                                    <div style={{
                                                        height: '3rem', border: 'none',
                                                        outline: 'none', background: 'transparent'
                                                    }}></div>
                                                    <form onSubmit={addToWishList} id="addWishList">
                                                        <button style={{
                                                            height: '3rem', border: 'none',
                                                            outline: 'none', background: 'transparent'
                                                        }}
                                                        >{productInWishList ?
                                                            <img src={filledLikeEmoji}
                                                                className="likeEmoji"
                                                                alt="like emoji" />
                                                            :
                                                            <img src={likeEmoji}
                                                                className="likeEmoji"
                                                                alt="like emoji" />
                                                            }</button>
                                                    </form>
                                                </div>
                                                <div>
                                                </div>
                                            </div>
                                            <h2 className="product-name">{product.product_name}</h2>
                                        </div>
                                        <div className="grid_flex">
                                            <span>
                                                <span className="currency">NGN</span>
                                                <NumberFormat value={product.konnect_price} className="product-price" displayType={'text'} thousandSeparator={true} />
                                            </span>
                                        </div>
                                        <div className="space-below"></div>
                                        <p className="product-desc">
                                            {product.description}
                                        </p>
                                        <div className="shop_tags">
                                            <ul>
                                                <li>free delivery</li>
                                                <li>cashbacks</li>
                                                <li>free cash</li>
                                                <li>voucher</li>
                                                <li>discounts</li>
                                                <li>pay later</li>
                                                <li>fast delivery</li>
                                                <li>packaging</li>
                                                <li>personalization</li>
                                                <li>low prizes</li>
                                            </ul>
                                        </div>
                                        <div>
                                            <div className="formbox">
                                                {!addToCartButton ?
                                                    <button onClick={() => uploadToCart({ product })} className="bg_green" style={{ height: '5rem', width: '100%', display: 'flex', textAlign: 'center', justifyContent: 'center', alignItems: 'center', gridGap: 10 }}>
                                                        <img style={{ width: 'auto' }} src={CartImage} alt="cart" />
                                                        <p style={{ color: '#fff', marginBottom: 0 }}>Add to Cart</p>
                                                    </button>
                                                    :
                                                    <button className="bg_green" disabled={true} style={{ height: '5rem', width: '100%', display: 'flex', textAlign: 'center', justifyContent: 'center', alignItems: 'center', gridGap: 10 }}>
                                                        <img style={{ width: 'auto' }} src={CartImage} alt="cart" />
                                                        <p style={{ color: '#fff', marginBottom: 0 }}>Adding product to cart.... Please wait</p>
                                                    </button>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    <div>
                                        <Skeleton active /><Skeleton active />
                                    </div>
                                : ''
                            }
                        </div>
                    </div>
                    <div style={{ marginTop: '5%' }}></div>
                    <Recommended products={trendingProducts} param={`Trending products in the last hour`} />
                    <div style={{ marginTop: '5%' }}></div>
                </div>
                <Footer />
            </Spin>
        </React.Fragment >
    )
}

export default SingleProduct    