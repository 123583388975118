import { LoadingOutlined } from '@ant-design/icons';
import { Skeleton, Spin, notification } from 'antd';
import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';


import Groceries from '../assets/images/shop/grocery.webp';
import axiosCall from '../common/axios_call';
import { _all_product } from '../common/axios_services';
import Category from '../components/Category';
import Footer from '../components/Footer';
import Navbar from '../components/Navbar';
import Recommended from '../components/Recommended';
import TrendingCarousel from '../components/TrendingCarousel';

const EachCategory = () => {
    const [page, setPage] = useState(1);
    const [page_size, setPage_size] = useState(20);
    const [searchParameter, setSearchParameter] = useState('');
    const [categoryData, setCategoryData] = useState([]);
    const [spinnerLoading, setSpinnerLoading] = useState(true);
    const [trendingProducts, setTrendingProducts] = useState([]);
    const [productBox, setProductBox] = useState([]);

    const search_cat = " "

    const openNotificationWithIcon = (type, message) => {
        notification[type]({ message: '', placement: 'bottomRight', description: message, });
    };

    const params = useParams()
    const getProduct = async () => {
        try {
            const all_products = await _all_product({ page: '1', per_page: '14', search: '' })
            setTrendingProducts(all_products.data.data);
        } catch (err) {
            // console.log(err);
        }
    }

    useEffect(() => {
        setSpinnerLoading(true)

        axiosCall(`/category/${params.category_id}`, { headers: { "Authorization": `Bearer ${process.env.REACT_APP_FAKE_TOKEN}` } }).then(res => {
            setSearchParameter(res.data.data.category_name);
        }).catch(err => {
            openNotificationWithIcon('error', err.response.data.message)
        })

        axiosCall(`/products/cat_id/${params.category_id}?page=${page}&per_page=${page_size}&search=${search_cat}`, { headers: { "Authorization": `Bearer ${process.env.REACT_APP_FAKE_TOKEN}` } }).then(res => {
            setProductBox(res.data.data)
            setSpinnerLoading(false);
        }).catch(err => {
            openNotificationWithIcon('error', err.response.data.message)
        })


        axiosCall('/category/no_pagination', { headers: { "Authorization": `Bearer ${process.env.REACT_APP_FAKE_TOKEN}` } }).then(res => {
            setCategoryData(res.data.data)
        }).catch(err => {
            openNotificationWithIcon('error', err.response.data.message)
        })

        getProduct()

    }, [params.category_id])

    const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
    let skeleton = [];
    for (let i = 0; i < 21; i++) {
        skeleton.push(<Skeleton.Button active size={'default'}
            shape={'default'}
        />
        )
    }

    return (
        <div>
            <Spin indicator={antIcon} spinning={spinnerLoading}>
                <Navbar />
                <div className="category_header">
                    <img src={Groceries} alt="groceries" />
                </div>
                <div className="promo">
                    <div className="">
                        <Category categoryData={categoryData} param={'Shop by category'} />
                    </div>
                </div>
                {(productBox.length) ?
                    <Recommended products={productBox} param={`Products for ${searchParameter}`} />
                    :
                    <div className="center-spinner">
                        <div>
                            <p>There are no products in this category</p>
                            <Link to="/shop">See all products in Shop</Link>
                        </div>
                    </div>
                }
            </Spin>
            <TrendingCarousel />
            <Recommended products={trendingProducts} param={`Trending products in the last hour`} />
            <Footer />
        </div>
    )
}

export default EachCategory;