import {Card, Col, notification, Row, Switch, Radio, Spin} from 'antd';
import React, { useEffect, useState } from 'react';
import Navbar from '../../components/Navbar';
import restaurant_food from '../../assets/images/homepage/restaurant_food.png';
import giftIcon from '../../assets/images/restaurant/gift.svg';
import map from '../../assets/images/restaurant/Map_of_Assyria.png';
import map_locationIcon from '../../assets/images/restaurant/map_locationIcon.svg';
import info_circle from '../../assets/images/restaurant/-Info-circle.svg';
import { CheckoutDrawer } from '../../components/Restaurant/CheckoutDrawer';
import {useNavigate, useParams} from 'react-router-dom';
import { ReactComponent as Cancel } from '../../assets/images/icons/x.svg';

import {
    _confirm_user_transfer_pin,
    _get_saved_address,
    _get_user_wallet,
    _single_restaurant
} from "../../common/axios_services";
import {useDispatch, useSelector} from "react-redux";
import {LoadingOutlined, PlusOutlined} from "@ant-design/icons";
import NumberFormat from "react-number-format";
import {fetchData, profileData} from "../../slices/profileSlice";
import { setRestaurant } from "../../slices/restaurantCartSlice";
import TopUpWallet from "../../components/Restaurant/TopUpWallet";
import RequestTransactionCode from "../../components/Restaurant/RequestTransactionCode";
import BackToRestaurant from "../../components/Restaurant/BackToRestaurant";


const ReviewCheckout = () => {
    const navigate = useNavigate()
    const [wantsCutlery, setWantsCutlery] = useState(false);
    const [visibleDrawer, setVisibleDrawer] = useState(false);
    const [allDeliveryLocations, setAllDeliveryLocations] = useState([]);
    const [selectedAddress, setSelectedAddress] = useState(null);
    const [deliveryMethod, setDeliveryMethod] = useState('standard');
    const [loading, setLoading] = useState(true);
    const [restaurantData, setRestaurantData] = useState({});
    const {id: restaurantId} = useParams();
    const [userTransferData, setUserTransferData] = useState({});
    const restaurantCarts = useSelector(store => store.restaurantCart.items);
    const dispatch = useDispatch();
    const [deliveryMode, setDeliveryMode] = useState("Delivery");
    const [selectedState, setSelectedState] = useState("");
    const [selectedLGA, setSelectedLGA] = useState("");
    const [completeOrderEnabled, setCompleteOrderEnabled] = useState(false);
    const [displayTransCodeModal, setDisplayTransCodeModal] = useState(false);
    const [userPin, setUserPin] = useState("");
    const [otpCounter, setOtpCounter] = useState(false);


    const antIcon = <LoadingOutlined style={{fontSize: 24}} spin/>;
    const showDrawer = () => setVisibleDrawer(true);
    const closeDrawer = () => setVisibleDrawer(false);


    const openNotificationWithIcon = (type, message) => {
        notification[type]({
            description: message,
        });
    };


    // Fetch restaurant info
    const fetchRestaurantInfo = async (id) => {
        try {
            const response = await _single_restaurant({id});
            setRestaurantData(response.data.data);
            setLoading(false);
        } catch (err) {
            if (err.response) {
                if (err.response.data.message === "Unauthorized") {
                    localStorage.removeItem("konnect_token");
                    navigate("/");
                }
                openNotificationWithIcon("error", err.response.data.message);
            } else {
                openNotificationWithIcon("Something went wrong", err.message);
            }
            setLoading(false);
        }
    };

    useEffect(() => {
        if (restaurantId) {
            fetchRestaurantInfo(restaurantId);
            // Ensure the cart is hydrated on page load
            dispatch(setRestaurant({ restaurantId }));
        }
    }, [restaurantId, dispatch]);

    const fetchUserAddresses = async () => {
        try {
            const allAddresses = await _get_saved_address();
            setAllDeliveryLocations(allAddresses.data.data);
        } catch (err) {
            notification.error({
                message: "Error",
                description: err.response?.data?.message || "Something went wrong.",
            });
        }
    };

    const fetchUserWallet = async () => {
        try {
            const user_profile = await _get_user_wallet();
            let userData = user_profile?.data.data;
            setUserTransferData(userData);
            setLoading(false);
        } catch (err) {
            if (err.response) {
                if (err.response.data.message === "Unauthorized") {
                    localStorage.removeItem('konnect_token')
                    navigate(`/signin`)
                }
                dispatch(profileData(false))
                dispatch(fetchData(false))
            } else {
            }
        }
    }

    useEffect(() => {
        fetchUserWallet()
        fetchUserAddresses();
    }, []);

    // Calculate total items and total cost
    const totalItems = restaurantCarts.reduce((total, item) => total + item.quantity, 0);

    const totalAmount = restaurantCarts.reduce((total, item) => {
        const price = item?.detail?.public_price || 0; // Use 0 if price is invalid
        const discount = item?.detail?.discount || 0;  // Use 0 if discount is missing or invalid
        const discountedPrice = discount > 0
            ? price * (1 - discount / 100) // Apply discount
            : price; // No discount, use the original price
        let itemTotal = discountedPrice * (item.quantity || 0); // Ensure quantity is valid (default to 0)
        const extrasTotal = (item.extras || []).reduce((extraTotal, extra) => {
            const extraPrice = parseFloat(extra?.price) || 0; // Ensure price is valid
            const extraQuantity = extra?.quantity || 0; // Ensure quantity is valid
            return extraTotal + (extraPrice * extraQuantity); // Add the extra's total
        }, 0);
        itemTotal += extrasTotal;
        return total + itemTotal; // Accumulate the total amount
    }, 0);


    // Validation Logic
    const validateForm = () => {
        if (deliveryMode === "Delivery") {
            return selectedAddress !== null && deliveryMethod !== null;
        }
        if (deliveryMode === "Pickup") {
            return true; // No additional fields are needed for Pickup
        }
        return false; // Default to invalid
    };
    // Enable/Disable Complete Order Button
    React.useEffect(() => {
        setCompleteOrderEnabled(validateForm());
    }, [deliveryMode, selectedAddress, deliveryMethod]);


    // Transaction Pin

    const toggleTransCodeModalDisplay = () => {
        if (!validateForm()) {
            alert("Please complete all required fields before proceeding.");
            return;
        }
        // // Open the drawer or perform the necessary action
        setDisplayTransCodeModal(!displayTransCodeModal);
        setOtpCounter(true);
        console.log("Complete Order");
    };

    const verifyUserPin = async (e) => {
        setLoading(true);
        setDisplayTransCodeModal(false);
        try {
            const profile = await _confirm_user_transfer_pin(e);
            navigate("/restaurant/1/checkout_successful");
            setLoading(false);
        } catch (err) {
            if (err?.response?.data?.message) {
                if (err?.code === "ERR_NETWORK") {
                    openNotificationWithIcon(
                        "error",
                        "An error occurred while checking pin. Please check network and try again"
                    );
                } else if (err?.response?.data?.message === "Unauthorized") {
                    openNotificationWithIcon(
                        "error",
                        "User Authorization expired. Please log in to continue"
                    );
                    navigate("/signin");
                } else {
                    openNotificationWithIcon(
                        "error",
                        err?.data?.message.length
                            ? err?.data?.message
                            : err?.response?.data?.message
                    );
                }
            } else {
                openNotificationWithIcon(
                    "error",
                    "An error occurred while checking pin. Please try again"
                );
            }
            setLoading(false);
        }
    };


    const handleSwitch = (checked) =>{
		setWantsCutlery(checked)
	}
  return (
    <>
        <Spin spinning={loading} indicator={antIcon}>
            <div>
                <Navbar noShadow={true} adjustNavSize={true}  />
                <div className="px-10 pb-20">
                    <Row justify={'center'}>
                        <Col xs={24}>
                            <BackToRestaurant restaurantId={restaurantId} />
                            <h2 className="lg:text-6xl text-4xl">
                                Review your order from {restaurantData.name}
                            </h2>
                        </Col>
                    </Row>
                </div>
                <div className="px-10 ">
                    <Row justify={'center'} gutter={{lg: 48}}>
                        <Col xs={24} lg={16}>
                            <div className="payment">
                                <h2 className='text-3xl'>Payment</h2>
                                <Card bordered={false}
                                      className="px-10 mt-3  py-5 bg-[#fff] rounded-2xl border-2 border-black">
                                    <Radio.Group
                                        value="wallet"
                                        className="w-full"
                                        disabled
                                    >
                                        <div className="flex justify-between items-center">
                                            <Radio value="wallet" className="text-3xl">
                                                <div className="flex flex-col">
                                                    <p className="text-2xl text-black">Wallet</p>
                                                </div>
                                            </Radio>
                                            <div className="text-right">
                                                <p className="text-2xl font-semibold">
                                                    {!loading
                                                        ? (
                                                            <NumberFormat
                                                                value={+userTransferData?.available_balance}
                                                                displayType={'text'}
                                                                thousandSeparator={true}
                                                                decimalScale={2}
                                                                fixedDecimalScale={true}
                                                                prefix={'₦'}
                                                                className="numeric"
                                                            />
                                                        )
                                                        : "-"
                                                    }
                                                </p>
                                            </div>
                                        </div>
                                    </Radio.Group>
                                </Card>
                            </div>
                            {/*PAYMENT PROCESSING*/}
                            <TopUpWallet userTransferData={userTransferData}/>

                            <div className="delivery my-5">
                                <h2 className='text-3xl'>Delivery Mode</h2>
                                <Card bordered={false}
                                      className="px-10 mt-3  py-5 bg-[#fff] rounded-2xl border-2 border-black">
                                    <div className="divide-y-2">
                                        <Radio.Group
                                            onChange={(e) => setDeliveryMode(e.target.value)}
                                            value={deliveryMode}
                                            className="w-full divide-y-2 flex flex-col gap-y-5"
                                        >
                                            <div className="flex justify-between items-center py-3">
                                                <Radio value="Pickup">
                                                    <div className="flex flex-col">
                                                        <p className="text-2xl">Pickup</p>
                                                    </div>
                                                </Radio>
                                            </div>
                                            <div className="flex justify-between items-center py-3">
                                                <Radio value="Delivery">
                                                    <div className="flex flex-col">
                                                        <p className="text-2xl">Delivery</p>
                                                    </div>
                                                </Radio>
                                            </div>
                                        </Radio.Group>
                                    </div>
                                </Card>
                            </div>

                            {/* Conditional Delivery Sections */}
                            {deliveryMode === "Delivery" && (
                                <>
                                    {/* Delivery Address */}
                                    <div className="delivery-address-section my-5">
                                        <div className="flex justify-between">
                                            <h2 className="text-3xl">Delivery Address</h2>
                                            {allDeliveryLocations.length > 0 && (
                                                <span
                                                    className="text-[green] font-bold text-2xl cursor-pointer"
                                                    onClick={showDrawer}
                                                >Change</span>
                                            )}
                                        </div>
                                        {visibleDrawer && (
                                            <CheckoutDrawer
                                                visible={visibleDrawer}
                                                onClose={closeDrawer}
                                                title={
                                                    allDeliveryLocations.length > 0
                                                        ? "Change"
                                                        : "Add"
                                                }
                                                selectedState={selectedState}
                                                selectedLGA={selectedLGA}
                                            />
                                        )}
                                        <Card bordered={false}
                                              className="px-10 mt-3  py-5 bg-[#fff] rounded-2xl border-2 border-black">
                                            <div className="w-full my-5 mb-8">
                                                <img src={map} className="h-72 w-full" alt="Location map"/>
                                            </div>
                                            {allDeliveryLocations.length > 0 ? (
                                                allDeliveryLocations.map((item, index) => (
                                                    <div key={index}>
                                                        <div
                                                            className="location-info flex flex-wrap gap-3 text-black mt-3">
                                                            <h2 className="location-name">{item.name}</h2>
                                                            <p className="location-contact">{item.phone_contact}</p>
                                                            <p className="location-contact">{item.email}</p>
                                                        </div>
                                                        <div
                                                            className="location-address flex items-center gap-3 text-black mt-3">
                                                            <span className="address-icon">
                                                                <img src={map_locationIcon} alt="Location Icon"
                                                                     className="w-6 h-6"/>
                                                            </span>
                                                            <p className="address-text text-2xl font-bold">
                                                                {[
                                                                    item.floor_no,
                                                                    item.landmark,
                                                                    item.lga,
                                                                    item.city,
                                                                    item.state,
                                                                ].filter(Boolean).join(", ")}
                                                            </p>
                                                        </div>
                                                        <div className="flex items-center gap-3 mt-3">
                                                            <input
                                                                type="radio"
                                                                name="selectedAddress"
                                                                value={index}
                                                                checked={selectedAddress === index}
                                                                onChange={() => setSelectedAddress(index)}
                                                                className="mr-2"
                                                            />
                                                            <label htmlFor={`address-${index}`} className="text-xl">
                                                                Use this address
                                                            </label>
                                                        </div>
                                                        <hr className="my-5" style={{backgroundColor: "#eee"}}/>
                                                    </div>
                                                ))
                                            ) : (
                                                <span
                                                    className="text-[#44843F] cursor-pointer mt-24 font-bold text-2xl"
                                                    onClick={showDrawer}
                                                >
                                                          <PlusOutlined/>  Add Delivery Address
                                                        </span>
                                            )}
                                        </Card>
                                    </div>

                                    {/* Delivery Method */}
                                    <div className="delivery-section my-5">
                                        <h2 className="text-2xl">Delivery Fee</h2>
                                        <Card bordered={false}
                                              className="px-10 mt-3  py-5 bg-[#fff] rounded-2xl border-2 border-black">
                                            <div className="divide-y-2">
                                                <Radio.Group
                                                    onChange={(e) => setDeliveryMethod(e.target.value)}
                                                    value={deliveryMethod}
                                                    className="w-full divide-y-2 flex flex-col gap-y-5"
                                                >
                                                    <div className="flex justify-between items-center py-3">
                                                        <Radio value="standard">
                                                            <div className="flex flex-col">
                                                                <p className="text-2xl">Standard Delivery</p>
                                                            </div>
                                                        </Radio>
                                                        <p className="text-xl font-semibold">₦400</p>
                                                    </div>
                                                </Radio.Group>
                                            </div>
                                        </Card>
                                    </div>
                                </>
                            )}

                            {deliveryMode === "Pickup" && (
                                <div className="delivery-address-section my-5">
                                    <h2 className="text-2xl">Pickup Address</h2>
                                    <Card bordered={false}
                                          className="px-10 mt-3  py-5 bg-[#fff] rounded-2xl border-2 border-black">
                                        <h2 className="text-[#44843F] text-3xl">{restaurantData.name}</h2>
                                        <p className="text-2xl font-bold">
                                            {
                                                `${restaurantData.address}, ${restaurantData.city}  ${restaurantData.state} `
                                            }
                                            {/*{restaurantData.address + restaurantData.city + restaurantData.state || "No address available"}*/}
                                        </p>
                                    </Card>
                                </div>
                            )}

                            {/* Last Card */}
                            {/*<div className="delivery my-5">*/}
                            {/*    <Card bordered={false} className="px-10 mt-5  py-5 bg-[#fff] rounded-2xl border-2 border-black">*/}
                            {/*        <div className="flex justify-between">*/}
                            {/*            <h2>Need Cutlery</h2>*/}
                            {/*            <Switch className={` ${wantsCutlery ? 'bg-[#258635]' : 'bg-[grey]'}`} onChange={handleSwitch}  />*/}
                            {/*        </div>*/}
                            {/*    </Card>*/}
                            {/*</div>*/}
                            {/* Complete Order Button */}
                            <button
                                onClick={toggleTransCodeModalDisplay}
                                disabled={!completeOrderEnabled}
                                className={`${
                                    completeOrderEnabled ? "bg-[#44843F]" : "bg-gray-400 cursor-not-allowed"
                                } text-white flex justify-center w-full rounded-3xl px-10 my-10 py-3`}
                            >
                                <p>Complete Order</p>
                            </button>


                            {/*Transaction Pin*/}
                            <RequestTransactionCode
                                openDrawer={displayTransCodeModal}
                                closeDrawer={toggleTransCodeModalDisplay}
                                inputCount={4}
                                actionFunction={verifyUserPin}
                                stateFunc={setUserPin}
                                transChildrenPresent={true}
                            >
                                <div className="px-10 mt-4">
                                    <div className="drawer_header py-3 flex justify-between">
                                        <h2 className="text-4xl"> {"Complete Order"} </h2>
                                        <Cancel onClick={toggleTransCodeModalDisplay}
                                                className="cancel cursor-pointer"/>
                                    </div>
                                    <hr className={"mb-5"}/>
                                    <span className={"text-2xl "}>
                                                    Please enter your transaction PIN to authorize the payment of
                                                <span className="font-bold">
                                                     <NumberFormat
                                                         value={totalAmount}
                                                         displayType={'text'}
                                                         thousandSeparator={true}
                                                         decimalScale={2}
                                                         fixedDecimalScale={true}
                                                         prefix={' ₦'}
                                                     />
                                                </span> {" "} to {restaurantData.name} for:
                                            </span>
                                    <div>
                                        {restaurantCarts.map((item, index) => {
                                            const extrasIncluded = item.extras && item.extras.length > 0 ? " (extras included)" : "";
                                            return (
                                                <div className="flex gap-2" key={index}>
                                                    <ul className={"list-disc px-8 marker:text-green-800"}>
                                                        <li>
                                                            {item.quantity} pack(s) of {item.detail?.product_name}
                                                            {extrasIncluded}
                                                        </li>
                                                    </ul>
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                            </RequestTransactionCode>
                        </Col>

                        {/* CHECKOUT DETAILS */}
                        <Col xs={24} lg={8}>
                            <Card bordered={false}
                                  className="flex-col px-10 py-20 md:mb-0 mb-36 lg:mt-28 items-center bg-[#f5fff5] rounded-[32px] border-2 border-black">
                                <div className="">
                                    <div className="rest_header">
                                        <h2 className='text-4xl text-black'>Basket</h2>
                                    </div>
                                    <hr className="my-3"/>
                                    <div className="order_item my-10">
                                        <div className="flex items-center gap-4 py-3 my-3  ">
                                            <img src={restaurantData.display_image} alt="restaurant_logo"
                                                 className="w-28 h-28 object-cover rounded-full"/>
                                            <div>
                                                <p className="text-2xl text-black font-semibold">
                                                    {restaurantData.name} - {restaurantData.city}
                                                </p>
                                                <p className="text-xl text-[#959595]">
                                                    {totalItems} Items •
                                                    <NumberFormat
                                                        value={totalAmount}
                                                        displayType={'text'}
                                                        thousandSeparator={true}
                                                        decimalScale={2}
                                                        fixedDecimalScale={true}
                                                        prefix={' ₦'}
                                                    />
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="order_item my-10">
                                        {
                                            restaurantCarts.map((item, index) => {
                                                const discount = item?.detail?.discount ? parseFloat(item?.detail?.discount) : 0;
                                                const originalPrice = parseFloat(item?.detail?.public_price);
                                                const discountedPrice = discount > 0
                                                    ? (originalPrice * (1 - discount / 100)).toFixed(2)
                                                    : originalPrice.toFixed(2);

                                                return (
                                                    <>
                                                        <div key={index}>
                                                            <div className="flex justify-between gap-2">
                                                                <p className="text-3xl">{item.quantity} x</p>
                                                                <p className="text-3xl text-wrap">{item.detail?.product_name}</p>
                                                                <p className=' text-[#44843F] '>
                                                                    <NumberFormat
                                                                        value={(discountedPrice * item.quantity)}
                                                                        displayType={'text'}
                                                                        thousandSeparator={true}
                                                                        decimalScale={2}
                                                                        fixedDecimalScale={true}
                                                                        prefix={' ₦'}
                                                                    />
                                                                </p>
                                                            </div>
                                                            {/* Extra Menus */}
                                                            {item.extras && item.extras.length > 0 && (
                                                                <div className="mt-3">
                                                                    <p className="text-2xl font-bold">Extras:</p>
                                                                    {item.extras.map((extra, extraIndex) => (
                                                                        <div key={extraIndex}
                                                                             className="flex justify-between text-lg text-gray-600">
                                                                            <p className="text-2xl">{extra.name} (x{extra.quantity})</p>
                                                                            <p className=' text-[#44843F] text-xl '>
                                                                                <NumberFormat
                                                                                    value={extra.price * extra.quantity}
                                                                                    displayType={'text'}
                                                                                    thousandSeparator={true}
                                                                                    decimalScale={2}
                                                                                    fixedDecimalScale={true}
                                                                                    prefix={'₦'}
                                                                                />
                                                                            </p>
                                                                        </div>
                                                                    ))}
                                                                </div>
                                                            )}
                                                        </div>
                                                        {index !== restaurantCarts.length - 1 &&
                                                            <hr className={'my-3'} />
                                                        }
                                                    </>

                                                );
                                            })
                                        }
                                        <div className="flex justify-between mt-5">
                                            <div className="flex-col">
                                                <p>Subtotal</p>
                                                <p className='text-[#959595] text-xl'>10% discount</p>
                                            </div>
                                            <p className=' text-[#44843F]'>₦6000</p>
                                        </div>
                                        {/* Your Selections */}
                                        {deliveryMode === "Delivery" && (
                                            <div
                                                className="bg-[#E4E4E4] rounded-3xl p-4 my-5  border border-black ">
                                                <div className="divide-[#959595] divide-y">
                                                    <div className="flex justify-between text-[#000] text-2xl pt-3">
                                                        <span>Delivery Fee</span>
                                                        <span className='flex items-center gap-2'>
                                                                 <img src={info_circle} className='w-10 h-10'
                                                                      alt=""/>
                                                                 <p> ₦200</p>
                                                            </span>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                        <div className="my-10">
                                            <div className="flex justify-between py-2">
                                                <p className={"text-2xl font-bold"}>Total</p>
                                                <p className={'font-bold'}>
                                                    <NumberFormat
                                                        value={totalAmount}
                                                        displayType={'text'}
                                                        thousandSeparator={true}
                                                        decimalScale={2}
                                                        fixedDecimalScale={true}
                                                        prefix={'₦'}
                                                    />
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Card>
                        </Col>
                  </Row>
                </div>
            </div>
        </Spin>
    </>
    )
}
export default ReviewCheckout;