import React, { useState } from 'react';
import {Button, message, Modal} from 'antd';
import {ArrowRightOutlined, CopyOutlined} from '@ant-design/icons';
import walletIcon from '../../assets/images/icons/payment_processing.svg';
import CopyToClipboard from "react-copy-to-clipboard";

const TopUpWalletButton = ({userTransferData}) => {
    const [isModalVisible, setIsModalVisible] = useState(false);

    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleOk = () => {
        setIsModalVisible(false);
        // Optionally add logic here for when the "Ok" button is clicked.
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    return (
        <>
            <Button
                type="default"
                onClick={showModal} // Show modal on click
                className="flex my-5 items-center justify-between hover:text-black focus:text-black px-6 py-10 w-full bg-[#FDF4D7] text-black rounded-2xl border border-black hover:bg-[#FDF4D7]"
            >
                <div className="flex items-center space-x-3">
                    <img src={walletIcon} alt="Wallet" className="w-10 h-10" />
                    <span className="text-2xl text-black font-semibold">Top-up wallet</span>
                </div>
                <ArrowRightOutlined />
            </Button>

            {/* Modal Component */}
            <Modal
                title="Top-up Your Wallet"
                open={isModalVisible}
                onOk={handleOk}
                onCancel={handleCancel}
                footer={null}
                className={"partnerModal"}
            >
                <div className={"flex flex-col gap-y-3"}>
                    <p>
                        Fund your wallet by bank transfer from any bank.
                    </p>
                    <p>
                        <span className={"font-bold"}>Bank:</span>  {userTransferData.account_number ? userTransferData.bank_name : "-"}
                    </p>
                    <p>
                        <span className={"font-bold"}>Account Number: </span>
                        <CopyToClipboard text={userTransferData.account_number} onCopy={() => message.success('Account Number copied to clipboard!')}>
                          <span style={{cursor: 'pointer', color: '#000',}}>
                             {userTransferData.account_number ? userTransferData.account_number : "-"}
                              <CopyOutlined style={{marginLeft: '20px', fontSize: '16px', color: '#44843F'}}/>
                          </span>
                        </CopyToClipboard>
                    </p>
                </div>
            </Modal>
        </>
    );
};

export default TopUpWalletButton;
