import { LoadingOutlined } from '@ant-design/icons';
import '../assets/css/shop.css';

import { Skeleton } from 'antd';
import React, { useEffect, useState } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import { default as axios, default as axiosCall } from '../common/axios_call';
import { _get_search_product } from '../common/axios_services';
import AllProducts from '../components/AllProducts';
import Category from "../components/Category";
import Footer from '../components/Footer';
import Navbar from '../components/Navbar';

const Shop = () => {
    const [productBox, setProductBox] = useState([]);
    const [categoryData, setCategoryData] = useState([]);
    const [spinnerLoading, setSpinnerLoading] = useState(true);
    const [productsSpinner, setProductsSpinner] = useState(true);
    let params = new URLSearchParams(window?.location?.search);
    let searchKey = params.get('search')
    const searchProduct = async () => {
        try {
            const _get_search_products = await _get_search_product({ page: "1", page_size: "24", search: searchKey })
            setProductBox(_get_search_products.data.data)
        } catch (err) {
            // console.log(err)
        }
    }

    useEffect(() => {
        setSpinnerLoading(true);
        axios('/category/no_pagination', { headers: { "Authorization": `Bearer ${process.env.REACT_APP_FAKE_TOKEN}` } })
            .then(data => {
                let categoryDatum = [];
                if (data.data.status === "success") {
                    setSpinnerLoading(false);
                    categoryDatum = data.data.data;
                }
                setCategoryData(categoryDatum);
            })
            .catch(err => {
                // console.log(err);
            })

        searchProduct()
    }, [searchKey])
    const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
    let skeleton = [];
    for (let i = 0; i < 6; i++) {
        skeleton.push(<Skeleton active />)
    }
    return (
        <div>
            <Navbar adjustNavSize={true} lastWord="shop" navShadow={true} />
            <div className="search-page-data">
                <div className="search-page contain">
                    {
                        productsSpinner ?
                            <div className="grid-6">
                                {skeleton.map((placeHolder, index) => (
                                    <div className="item" key={index}>
                                        {placeHolder}
                                    </div>
                                ))}
                            </div>
                            :
                            productBox.length ?
                                <AllProducts products={productBox} param={`'${searchKey}'`} />
                                :
                                <div className="center-spinner">
                                    <div>
                                        <p>Product not found</p>
                                        <Link to="/">See all products in Shop</Link>
                                    </div>
                                </div>
                    }
                </div>
                {
                    categoryData.length ?
                        <div className="by-category">
                            <div className="promo">
                                <Category categoryData={categoryData} param={'Shop by category'} />
                            </div>
                        </div>
                        :
                        <div className="contain">
                            <div className="by-category">
                                <div className="grid-6">
                                    {skeleton.map((placeHolder, index) => (
                                        <div className="item" key={index}>
                                            {placeHolder}
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                }
            </div>
            <Footer />
        </div >
    )
}
export default Shop;